import { useEffect, useState } from "react";
import { NotificationManager } from "common/notifications";
import {
  Button,
  CheckBox,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Select,
  Text,
} from "components";
import { rules } from "constant";
import { ServiceApi, URLS } from "services";

type LegalEntity = {
  id: string,
  code: string,
  name: string,
}

export default function AddChannel(props: any) {
  const [loading, setLoading] = useState(false);
  const initData = {
    code: '',
    title: '',
    description: '',
    ilnNumber: '',
    businessChannels: {
      crmChannel: false,
      erpChannel: false,
      onlineShopChannel: false
    },
    legalEntity: {
      legalEntityId: '',
      code: ''
    }
  }
  const [data, setData] = useState<any>(initData);

  const onSubmitForm = () => {
    setLoading(true);
    const url = URLS.SALES_CHANNEL;
    const selectedLegalEntity = props.legalEntities.filter((i: LegalEntity) => i.id === data?.legalEntityId)?.[0];
    const payLoad = selectedLegalEntity ?
      {
        ...data, legalEntity: {
          legalEntityRef: selectedLegalEntity?.id,
          code: selectedLegalEntity?.code
        }
      } : { ...data };
    ServiceApi.post(url, payLoad)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        props.getData();
        props.toggle();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetData = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, [key]: value }));
  };

  const onChangeHandlerBusinessChannels = (key: any) => {
    return (value: any) => setData((p: any) => ({ ...p, businessChannels: { ...p?.businessChannels, [key]: value } }));
  }

  useEffect(() => { setData(initData) }, [props.isOpen])

  return (
    <Drawer isOpen={props.isOpen} toggle={props.toggle}>
      <Form
        onSubmit={onSubmitForm}
        id={"create-drawer"}
      >
        <Drawer.Menu className="pr-0">
          <Drawer.Header className="pr-4 pt-6">
            <div className="flex items-center justify-between">
              <h2 className="text-heading-2 text-gray-800">
                <Text>salesManagment.salesChannels.addSalesChannel</Text>
              </h2>
              <div className="flex items-center justify-between">
                <Button
                  type="button"
                  variant="light"
                  className="ml-2"
                  onClick={() => props.toggle()}
                >
                  <Icon className="mr-2" icon={"times"} />
                  <Text>global.buttons.close</Text>
                </Button>
              </div>
            </div>
          </Drawer.Header>
          <Drawer.Body className="mt-8 space-y-6 pr-8">
            <InputGroup
              label="salesManagment.salesChannels.title"
              value={data.title}
              setValue={handleSetData("title")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesChannels.code"
              value={data.code}
              setValue={handleSetData("code")}
              rules={rules.required}
            />
            <InputGroup
              label="salesManagment.salesChannels.iln"
              value={data.ilnNumber}
              setValue={handleSetData("ilnNumber")}
              type="number"
            />
            <Select
              label="salesManagment.salesChannels.legalEntity"
              items={props.legalEntities}
              clear
              value={data.legalEntityId}
              setValue={handleSetData("legalEntityId")}
              rules={rules.required}
            />
            <InputGroup
              as="short-textarea"
              label="salesManagment.salesChannels.description"
              value={data.description}
              setValue={handleSetData("description")}
            />
            <div className="flex flex-col gap-4">
              <label>
                <Text>salesManagment.salesChannels.activeBusinessChannels</Text>
              </label>
              <CheckBox
                label="CRM"
                value={data?.businessChannels?.crmChannel}
                setValue={onChangeHandlerBusinessChannels("crmChannel")}
              />
              <CheckBox
                label="ERP"
                value={data?.businessChannels?.erpChannel}
                setValue={onChangeHandlerBusinessChannels("erpChannel")}
              />
              <CheckBox
                label="salesManagment.salesChannels.NoOnlineShop"
                value={data?.businessChannels?.onlineShopChannel}
                setValue={onChangeHandlerBusinessChannels("onlineShopChannel")}
              />
            </div>
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button
              variant="white"
              type="button"
              onClick={() => props.toggle()}
            >
              <Text>global.buttons.cancel</Text>
            </Button>
            <Button
              type="submit"
              variant="primary"
              loading={loading}
            >
              <Text>global.buttons.submit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
