import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  Drawer,
  Dropdown,
  Icon,
  Image,
  InputGroup,
  LazyImage,
  SelectLang,
  Text,
  Toggler,
  WithPermission,
} from "components";
import { AxiosRequestConfig } from "axios";
import { flagCountry } from "constant";
import { useSelector, useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ServiceApi, URLS } from "services";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import { PimContext } from "..";
import ImageMetaData from "./image-metadata";
type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  image: any;
  getMedias: any;
};

const ImageSettingDrawer = ({
  isOpen,
  toggle,
  image,
  getMedias,
}: drawerProps) => {
  const { i18n } = useTranslation();
  const { getPIMProduct, productData, channels } = useContext(PimContext);
  const companySetting = useSelector(s => s.companySetting);
  const [deletModal, toggleDelete] = useToggleState();
  const [metaDataModal, toggleMetaData] = useToggleState();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [coverLoading, setCoverLoading] = useToggleState();
  const [toggler, setToggler] = useToggleState();

  const [lang, setLang] = useState<string>("de");
  const [channelsCodeList, setChannelCodeList] = useState<any>([]);
  const [mediaFields, setMediaFields] = useState<any>([]);
  const [selectedChannel, setSelectedChannel] = useState("");
  const [langItems, setLangItems] = useState([]);
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    setSelectedChannel(channels?.[1]?.code);
    setMediaFields(image?.mediaFields || []);
  }, [image]);

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ id: item, name: item });
    });
    setLangItems(arr);
  }, [companySetting]); // eslint-disable-line react-hooks/exhaustive-deps

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const onTogglerChangeHandler = (value: boolean, code: string) => {
    onChangeHandler(value, "publishChannelEnabled");
  };

  const findTranslatedName = (channel: any) => {
    return channel?.title;
  };

  const findInputValue = (key: string) => {
    const fieldObject = mediaFields?.filter(
      (field: any) =>
        field.language === lang && field.channelCode === selectedChannel,
    );
    if (fieldObject?.length) {
      return fieldObject[0][key];
    }
  };

  const onChangeHandler = (value: string | boolean, key: string) => {
    const fieldObject = mediaFields?.filter(
      (field: any) =>
        field.language === lang && field.channelCode === selectedChannel,
    );
    if (fieldObject?.length) {
      const index = mediaFields.findIndex((object: any) => {
        return (
          object.language === lang && object.channelCode === selectedChannel
        );
      });
      let clone = fieldObject[0];
      clone[key] = value;
      let newMediaFields = [...mediaFields];
      newMediaFields[index] = {
        ...clone,
        publishChannelEnabled:
          key === "publishChannelEnabled" && value === true ? true : false,
      };
      setMediaFields(newMediaFields);
    } else {
      let object: any = {
        placeHolder: "",
        description: "",
        language: lang,
        channelCode: selectedChannel,
        publishChannelEnabled: channelsCodeList?.includes(selectedChannel),
      };
      object[key] = value;
      let newMediaFields = [...mediaFields];
      newMediaFields.push(object);
      setMediaFields(newMediaFields);
    }
  };

  const onSubmit = () => {
    setSubmitLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    const body = {
      pimProductMediaFields: mediaFields.length === 0 ? [{
        placeHolder: "",
        description: "",
        language: lang,
        channelCode: selectedChannel,
        publishChannelEnabled: false,
      }] : [...mediaFields],
    };
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setSubmitLoading();
        getMedias();
        toggle();
      })
      .catch(() => setSubmitLoading());
  };

  const onDeleteHandler = () => {
    setRemoveLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    ServiceApi.delete(url).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      setRemoveLoading();
      toggleDelete();
      getMedias();
      toggle();
    });
  };

  const onUploadHandler = (image: any) => {
    setCoverLoading();
    const url = URLS.UPDATE_PIM_PRODUCT_AVATAR_URL(productData?.id);
    ServiceApi.patch(url, {
      url: image.url,
      type: image.type,
      thumbnailUrl: image.thumbnailUrl,
    })
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getPIMProduct();
        setCoverLoading();
      })
      .catch(() => setCoverLoading());
    const urlPimProductMedias = URLS.UPDATE_PIM_PRODUCT_MEDIA_URL(image?.id);
    image["tags"] = ["CoverPhoto"];
    ServiceApi.put(urlPimProductMedias, {
      pimProductMediaFields: image.mediaFields,
    })
      .then(() => { })
      .catch(() => { });
  };

  useEffect(() => {
    imageSrcHandler(image?.url);
  }, [image]);

  const imageSrcHandler = (src: string) => {
    //url
    const url = src;
    if (url?.includes("/get-file")) {
      const config: AxiosRequestConfig = {
        responseType: "blob",
        params: { withoutSubscriptionKey: true },
      };
      ServiceApi.get(url, config).then(({ data }) => {
        setUrl(URL.createObjectURL(data));
      });
      return;
    } else {
      setUrl(url);
    }
  };

  const mediaTypeChecker = (type: string) => {
    if (type?.toLowerCase()?.includes("image")) {
      return { type: "image", icon: "" };
    }
    if (type?.toLowerCase()?.includes("excel")) {
      return { type, icon: "file-excel" };
    }
    if (type?.toLowerCase()?.includes("word")) {
      return { type, icon: "file-word" };
    }
    if (type?.toLowerCase()?.includes("pdf")) {
      return { type, icon: "file-pdf" };
    }
    if (type?.toLowerCase()?.includes("text")) {
      return { type, icon: "file-lines" };
    }
    if (
      type?.toLowerCase()?.includes("zip") ||
      type?.toLowerCase()?.includes("rar") ||
      type?.toLowerCase()?.includes("compressed")
    ) {
      return { type, icon: "file-zipper" };
    }
    return { type, icon: "file" };
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.Details.imageSetting</Text>
            </span>
            <div className="flex gap-x-2">
              <WithPermission permissions={["PS_DeletePimProductMedia"]}>
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={toggleDelete}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>productManagement.products.Details.deleteImage</Text>
                </Button>
              </WithPermission>
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-6 pr-4">
          {!image?.isFile ? (
            <LazyImage
              isDynamic
              src={image?.url}
              className="h-[190px] max-w-lg cursor-pointer bg-gray-100"
              imageClassName=" object-contain"
              onClick={() => {
                document.body.style.overflow = "hidden";
                if (!image?.isFile) {
                  setToggler();
                }
              }}
              info
              setCover={image?.isFile ? false : true}
              onInfoClick={toggleMetaData}
              onSetCover={() => onUploadHandler(image)}
              setCoverLoading={coverLoading}
            />
          ) : (
            <div className="relative flex max-w-lg rounded-lg bg-gray-100 p-4">
              <div className="flex w-full cursor-pointer items-center justify-between space-y-4">
                <div className="flex items-center space-x-4">
                  <Icon
                    icon={mediaTypeChecker(image.type)?.icon}
                    className="text-primary"
                    size="3x"
                  />
                  <p className="h-5 truncate text-gray-800">
                    {
                      image?.mediaMetadata?.find(
                        (p: any) => p.key === "OriginalName",
                      )?.value
                    }
                  </p>
                </div>
                <Icon
                  icon={"download"}
                  className="text-primary"
                  size="xl"
                  onClick={() => window.open(image?.url, "_blank")}
                />
              </div>
            </div>
          )}

          {/* DropDowns */}
          <div className="!mt-8 flex items-center justify-between">
            <h5 className="text-gray-800">
              <Text>productManagement.products.Details.imageInfo</Text>
            </h5>
            <div className="flex space-x-4">
              <Dropdown onSelect={onChangeLanguage}>
                <Dropdown.Toggle
                  as="button"
                  type="button"
                  className="flex space-x-2 rounded-md bg-gray-200 p-2 text-body-base font-medium uppercase text-gray-600"
                >
                  <span>
                    {findTranslatedName(
                      channels.filter(
                        (channel: any) => channel?.code === selectedChannel,
                      )?.[0],
                    )}
                  </span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="mt-2 min-w-fit p-2">
                  <ul className="w-max text-gray-700">
                    {channels?.map((channel: any) => {
                      return (
                        <>
                          {channel?.code !== "CH000D" && (
                            <li
                              key={channel?.code}
                              onClick={() => setSelectedChannel(channel.code)}
                              className={`h6 px-4 py-2 font-medium first:mb-1 ${channel.code === selectedChannel &&
                                "bg-primary-light text-primary "
                                } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                            >
                              {findTranslatedName(channel)}
                            </li>
                          )}
                        </>
                      );
                    })}
                  </ul>
                </Dropdown.Menu>
              </Dropdown>
              <SelectLang
                value={lang}
                items={langItems}
                onChangeLanguage={onChangeLanguage}
              />
            </div>
          </div>
          <InputGroup
            label="productManagement.products.Details.placeHolder"
            flag={flagCountry[lang]}
            value={findInputValue("placeHolder")}
            setValue={e => onChangeHandler(e, "placeHolder")}
          />
          <InputGroup
            as={"short-textarea"}
            label="productManagement.products.Details.description"
            flag={flagCountry[lang]}
            value={findInputValue("description")}
            setValue={e => onChangeHandler(e, "description")}
          />
          {/* Channels */}
          <h5 className="text-gray-800">
            <Text>
              productManagement.products.Details.imagePublicationInChannels
            </Text>
          </h5>
          <div className="flex justify-between rounded-xl bg-white p-4 text-sm shadow-card">
            <span>
              <Text>productManagement.products.Details.publishIn</Text>{" "}
              {findTranslatedName(
                channels.filter(
                  (channel: any) => channel?.code === selectedChannel,
                )?.[0],
              )}{" "}
              / {lang}
            </span>
            <div>
              <Toggler
                size="sm"
                value={findInputValue("publishChannelEnabled")}
                setValue={e => {
                  onTogglerChangeHandler(e, selectedChannel);
                }}
              />
            </div>
          </div>
        </Drawer.Body>
        <Drawer.Footer className="flex justify-between">
          <Button
            size="sm"
            onClick={onSubmit}
            loading={submitLoading}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: "Media" }}
        onConfirm={onDeleteHandler}
        loading={removeLoading}
      />
      <ImageMetaData
        isOpen={metaDataModal}
        toggle={toggleMetaData}
        metaData={image?.mediaMetadata}
      />
      <Lightbox
        open={toggler}
        index={0}
        close={() => {
          document.body.style.overflow = "visible";
          setToggler();
        }}
        slides={[{ src: url }]}
        plugins={[Zoom]}
      />
    </Drawer>
  );
};

export default ImageSettingDrawer;
