import {useEffect, useState} from "react";
import {
  Skeleton,
  NoItems,
  Wrapper,
  RadioButton,
  CheckBox,
  Button,
  Footer,
  Text,
  Icon,
} from "components";
import {useDataState, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";

export default function StorageConfig() {
  const [data, setData, setBaseData, isChanged] = useDataState<any>([]);
  const [loadingList, toggleList] = useToggleState();
  const [loading, toggle] = useToggleState();

  const [StorageType, setStorageType] = useState([
    {
      checked: 0,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.pimProduct",
    },
    {
      checked: 1,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.customerProfile",
    },
    {
      checked: 2,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.offerDocs",
    },
    {
      checked: 3,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.SellOfProduct",
    },
    {
      checked: 4,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.Company",
    },
    {
      checked: 5,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.User",
    },
    {
      checked: 6,
      accessLevel: 0,
      label: "configurations.globalSettings.dataStorage.Applications",
    },
    {
      checked: 99,
      accessLevel: 0,
      label: "Temp",
    },
  ]);
  const PulsFileType: any = [
    {
      value: 0,
      label: "configurations.globalSettings.dataStorage.none",
    },
    {
      value: 1,
      label: "configurations.globalSettings.dataStorage.generalBlob",
    },
    {
      value: 2,
      label: "configurations.globalSettings.dataStorage.personalBlob",
    },
    {
      value: 3,
      label: "configurations.globalSettings.dataStorage.personalElo",
    },
  ];
  const FileAccessLevel: any = [
    {
      value: 0,
      label: "configurations.globalSettings.dataStorage.public",
    },
    {
      value: 1,
      label: "configurations.globalSettings.dataStorage.customer",
    },
    {
      value: 2,
      label: "configurations.globalSettings.dataStorage.thirdParty",
    },
    {
      value: 3,
      label: "configurations.globalSettings.dataStorage.dashboard",
    },
  ];

  const getData = () => {
    toggleList();
    const url = URLS.GET_POST_STORAGE_CONFIGS;
    ServiceApi.get(url)
      .then(({data}) => {
        const clone = StorageType?.map((item: any, index: number) => ({
          ...item,
          checked: data.storageFileTypes[index].storageType,
          accessLevel: data.storageFileTypes[index].accessLevel,
        }));
        setStorageType(clone);
        setBaseData(data.storageFileTypes);
        toggleList();
      })
      .catch(() => toggleList());
  };

  useEffect(getData, []);

  const handleSetValue = (key: string, index: number, inner: number) => {
    const clone = [...data];
    clone[index] = {...clone[index], storageType: inner};
    const types = StorageType?.map((item: any, indextype: number) => {
      if (indextype === index) {
        return {
          ...item,
          [key]: inner,
        };
      }
      return item;
    });
    setStorageType(types);
    const newData = types?.map((type, index) => ({
      pulsFileType: index > 6 ? 99 : index,
      storageType: type.checked,
      accessLevel: type.accessLevel,
    }));
    setData(newData);
  };

  const onSubmitHandler = () => {
    toggle();
    const url = URLS.GET_POST_STORAGE_CONFIGS;
    ServiceApi.post(url, {storageFileTypes: data})
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData();
        toggle();
      })
      .catch(() => toggle());
  };
  return (
    <section className="grid gap-6">
      {loadingList ? (
        [1, 2, 3, 4].map((key: number) => (
          <Skeleton.List key={`skeleton_${key}`} />
        ))
      ) : data ? (
        <>
          {data?.map((storage: any, index: number) => {
            return (
              <Wrapper>
                <Wrapper.Header className="flex justify-between">
                  <Text>{StorageType[index]?.label}</Text>
                </Wrapper.Header>
                <Wrapper.Body className="space-y-8">
                  <div
                    key={`first_${String(new Date().toISOString())}`}
                    className="space-y-4"
                  >
                    <div className="grid grid-cols-3 gap-4">
                      {PulsFileType?.map((item: any, inner: number) => {
                        return (
                          <>
                            {inner === 0 && (
                              <p
                                className={` ${
                                  StorageType[index]?.checked === 0
                                    ? "text-warning-active"
                                    : "text-gray-800"
                                } col-span-3 font-semibold text-gray-800`}
                              >
                                <Text>
                                  configurations.globalSettings.dataStorage.storage
                                </Text>{" "}
                                :{" "}
                                {StorageType[index]?.checked === 0 && (
                                  <Icon icon="triangle-exclamation" size="1x" />
                                )}
                              </p>
                            )}
                            {item.value !== 0 && (
                              <RadioButton
                                label={item?.label}
                                value={
                                  item?.value === StorageType[index]?.checked
                                }
                                setValue={() =>
                                  handleSetValue("checked", index, inner)
                                }
                                labelClassName="text-gray-700 font-normal"
                              />
                            )}
                          </>
                        );
                      })}
                    </div>
                  </div>
                  <div
                    key={String(new Date().toISOString())}
                    className="space-y-4"
                  >
                    <p className="font-semibold text-gray-800">
                      <Text>
                        configurations.globalSettings.dataStorage.access
                      </Text>{" "}
                      :
                    </p>
                    <div className="grid grid-cols-4 gap-4">
                      {FileAccessLevel?.map((item: any, inner: number) => {
                        return (
                          <>
                            <CheckBox
                              label={item?.label}
                              value={
                                item?.value === StorageType[index]?.accessLevel
                              }
                              setValue={() =>
                                handleSetValue("accessLevel", index, inner)
                              }
                              indeterminate={
                                StorageType[index]?.accessLevel < item.value
                              }
                            />
                          </>
                        );
                      })}
                    </div>
                  </div>
                </Wrapper.Body>
              </Wrapper>
            );
          })}
          <Footer show={!isChanged}>
            <Button
              type="button"
              variant="primary"
              disabled={!isChanged}
              onClick={onSubmitHandler}
              loading={loading}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Footer>
        </>
      ) : (
        <div className="flex w-full flex-col items-center space-y-4">
          <NoItems />
        </div>
      )}
    </section>
  );
}
