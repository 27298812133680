import { useId, ReactNode } from "react";
import Text from "./Text";

type radioButtonProps = {
  label?: string;
  children?: ReactNode;
  value: boolean;
  disabled?: boolean;
  setValue?: (val: boolean) => void;
  labelClassName?: string;
  parentClassName?: string;
};

export default function RadioButton({
  value,
  setValue = () => { },
  label,
  disabled = false,
  children,
  labelClassName,
  parentClassName
}: radioButtonProps) {
  const id = useId();
  return (
    <label
      data-active={value}
      className={`radioButton flex-center group w-full gap-4 ${parentClassName}`}
    >
      <input
        id={id}
        type="radio"
        checked={value}
        disabled={disabled}
        onChange={() => setValue(!value)}
        hidden
      />
      <span className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-full border bg-gray-200 text-center transition-colors group-data-active:bg-primary">
        <i className="bi-circle-fill align-text-bottom text-[9px] text-white  opacity-0 transition-opacity group-data-active:opacity-100 " />
      </span>
      {label && (
        <span className={`flex-1 ${labelClassName}`} data-lang-map={label}>
          <Text>{label}</Text>
        </span>
      )}
      {children && <div className="flex-1">{children}</div>}
    </label>
  );
}
