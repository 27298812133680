export const status = {
  store: {
    active: "Active",
    notActive: "Not Active",
  },
  employee: {
    active: "Active",
    deActive: "Deactive",
  },
  salesList: {
    shippingCostHasWarning: "Shipping Cost Warning",
    sellFactorHasWarning: "Sell Factor Warning",
    pendingApproval: "Pending Approval",
    approved: "Approved",
  },
  channelMessage: {
    all: "All",
    published: "Published",
    processed: "Processed",
    synced: "Synced",
    failed: "Failed",
  },
  priceTemplates: {
    active: "Active",
    notActive: "NotActive",
  },
  generalDiscount: {
    active: "Active",
    notActive: "NotActive",
  },
  templateType: {
    salesOfferContract: "Offer",
    salesOrderContract: "Order",
    customerLegalContract: "Customer Legal",
    TermsAndConditions: "TermsAndConditions",
  },
  calculationUnits: {
    active: "Active",
    notActive: "Not Active",
  },
  supplier: {
    TradingGoods: "TradingGoods",
    ConsumableGoods: "ConsumableGoods",
  },
  availability: {
    active: "Active",
    inactive: "Inactive",
    salesOnly: "Sales Only",
    blocked: "Blocked",
  },
  productLabelActivation: {
    active: "Active",
    notActive: "Not Active",
  },
  printRequest: {
    completed: "Completed",
    onProcess: "OnProcess",
    failed: "Failed",
  },
  pimCheckList: {
    active: "Active",
    notActive: "NotActive",
  },
};
