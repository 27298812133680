const PRICE_ENGINE = {
  GET_CALCULATION_UNITS: "/priceengineservice/api/calculation-unit",
  CREATE_CALCULATION_UNITS: "/priceengineservice/api/calculation-unit",
  REMOVE_CALCULATION_UNITS: id =>
    `/priceengineservice/api/calculation-unit/${id}`,
  GET_CALCULATION_UNIT_DETAILS: id =>
    `/priceengineservice/api/calculation-unit/${id}`,
  UPDATE_CALCULATION_UNIT: `/priceengineservice/api/calculation-unit/`,
  CREATE_PURCHASE_PRICE_TEMPLATES:
    "/priceengineservice/api/purchase-price-templates",
  GET_PURCHASE_PRICE_TEMPLATES:
    "/priceengineservice/api/purchase-price-templates",
  GET_PURCHASE_PRICE_TEMPLATES_DETAILS: id =>
    `/priceengineservice/api/purchase-price-templates/${id}`,
  GET_SALES_TEMPLATES_DETAILS: id =>
    `/priceengineservice/api/sale-price-templates/${id}`,
  UPDATE_PURCHASE_PRICE_TEMPLATES_DETAILS: id =>
    `/priceengineservice/api/purchase-price-templates/${id}`,
  UPDATE_SALES_TEMPLATES_DETAILS: id =>
    `/priceengineservice/api/sale-price-templates/${id}`,
  GET_PURCHASE_ITEMS_LIST:
    "/priceengineservice/api/purchase-price-templates-item",
  GET_SALES_ITEMS_LIST: "/priceengineservice/api/sale-price-templates-item",
  ADD_PURCHASE_ITEMS: "/priceengineservice/api/purchase-price-templates-item",
  ADD_SALES_ITEMS: "/priceengineservice/api/sale-price-templates-item",
  REMOVE_PURCHASE_ITEMS: id =>
    `/priceengineservice/api/purchase-price-templates-item/${id}`,
  REMOVE_SALES_ITEMS: id =>
    `/priceengineservice/api/sale-price-templates-item/${id}`,
  GET_SALES_PRICE_TEMPLATES: "/priceengineservice/api/sale-price-templates",
  CREATE_SALES_TEMPLATE: "/priceengineservice/api/sale-price-templates",
  GET_CALCULATION_UNIT_CUSTOMIZATION:
    "/priceengineservice/api/calculation-unit-customization",
  ADD_CALCULATION_UNIT_CUSTOMIZATION_ITEM:
    "/priceengineservice/api/calculation-unit-customization/item",
  UPDATE_CALCULATION_UNIT_CUSTOMIZATION_CONFIG:
    "/priceengineservice/api/calculation-unit-customization/config",
  REMOVE_CALCULATION_UNIT_CUSTOMIZATION_ITEM: (id, itemId) =>
    `/priceengineservice/api/calculation-unit-customization/${id}/items/${itemId}`,
  REORDER_PURCHASE_PRICE_CALCULATION:
    "/priceengineservice/api/purchase-price-templates-item/reorder",
  REORDER_SALES_PRICE_CALCULATION:
    "/priceengineservice/api/sale-price-templates-item/reorder",
  GET_AVAILABLE_OPTIONAL_DISCOUNTS:
    "/priceengineservice/api/sale-price-calculation/available-discounts",
  SIMULATE_PURCHASE_PRICE:
    "/priceengineservice/api/purchase-price-calculation/simulate-purchase-price",
  SIMULATE_SALES_PRICE_FULL_DETAILS:
    "/priceengineservice/api/sale-price-calculation/simulate-sale-price-detail",
  SIMULATE_SALES_PRICE_SIMPLE_DETAILS:
    "/priceengineservice/api/sale-price-calculation/simulate-sale-price-detail",
  GET_SALES_CHANNEL_LIST: "/salesservice/api/salechannels",
};

export default PRICE_ENGINE;
