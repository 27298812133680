import {
  Button,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
  Select,
  Toggler,
} from "components";
import {Fragment, useContext} from "react";
import {PimContext} from "..";
import {usePermission} from "hooks";
import PriceSimulation from "compositions/price-simulation";

const PriceTab = () => {
  const {
    productData,
    setData,
    loading,
    isChanged,
    editProduct,
    submitLoading,
    setPatchData,
    brandId,
    baseData,
  } = useContext(PimContext);
  const editPermission = usePermission("PS_UpdatePimProduct");
  const purchasePriceCalculationPermission = usePermission(
    "PE_GetPurchasePriceCalculation",
  );
  const purchasePriceSimulationPermission = usePermission(
    "PE_GetPurchasePriceSimulation",
  );
  const salePriceCalculationPermission = usePermission(
    "PE_GetSalePriceCalculation",
  );
  const salePriceSimulationPermission = usePermission(
    "PE_GetSalePriceSimulation",
  );
  const priceTemplatePermission =
    purchasePriceCalculationPermission ||
    purchasePriceSimulationPermission ||
    salePriceCalculationPermission ||
    salePriceSimulationPermission;
  const PurchasePriceType = [
    {
      name: "Gross",
      id: 0,
    },
    {
      name: "Net",
      id: 1,
    },
    {
      name: "NNet",
      id: 2,
    },
  ];

  const onChangeHandler = (key: string) => {
    return (value: any) => {
      if (key === "purchasePriceType") {
        setData((p: any) => ({
          ...p,
          [key]: value,
        }));
        setPatchData((p: any) => ({
          ...p,
          [key]: value,
        }));
      } else {
        setPatchData((p: any) => ({
          ...p,
          [key]: {...p.price, amount: value},
        }));
        setData((p: any) => ({
          ...p,
          [key]: {...p.price, amount: value},
        }));
      }
    };
  };

  return (
    <div className="space-y-6">
      <div className="gap-4 ">
        <Wrapper>
          <Wrapper.Header>
            <Text>productManagement.products.Details.prices</Text>
          </Wrapper.Header>
          <Wrapper.Body>
            <div className="grid grid-cols-4 items-center gap-4">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <>
                  <div className="col-span-2">
                    <InputGroup
                      label="productManagement.products.Details.purchasePrice"
                      value={productData?.purchasePrice?.amount ?? ""}
                      setValue={onChangeHandler("purchasePrice")}
                      type="price"
                      disabled={!editPermission}
                    />
                  </div>
                  <div className="col-span-1">
                    <Select
                      label="productManagement.products.Details.type"
                      placeholder="Select"
                      value={productData?.purchasePriceType}
                      items={PurchasePriceType}
                      setValue={onChangeHandler("purchasePriceType")}
                      disabled={!editPermission}
                    />
                  </div>
                </>
              )}
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-2 gap-8">
          <div className="grid grid-cols-2">
            <div className="flex h-full items-end">
              {loading ? (
                <Skeleton.Input />
              ) : (
                <Toggler
                  label="productManagement.products.Details.noDiscount"
                  value={productData?.noDiscount}
                  setValue={isActive =>
                    setData((p: any) => ({
                      ...p,
                      noDiscount: isActive,
                    }))
                  }
                  disabled={!editPermission}
                />
              )}
            </div>
          </div>
          <div className="grid grid-cols-2">
            <div className="flex h-full items-end">
              {loading ? (
                <Skeleton.Input />
              ) : (
                <Toggler
                  label="productManagement.products.Details.NoPromotion"
                  value={productData?.noPromotion}
                  setValue={isActive =>
                    setData((p: any) => ({
                      ...p,
                      noPromotion: isActive,
                    }))
                  }
                  disabled={!editPermission}
                />
              )}
            </div>
          </div>
        </Wrapper.Body>
      </Wrapper>
      {priceTemplatePermission && (
        <Wrapper>
          <Wrapper.Header>
            <Text>productManagement.products.Details.priceTemplates</Text>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 gap-4 p-4 lg:grid-cols-2">
            <PriceSimulation
              supplierId={productData.supplierId}
              programId={productData.supplierProgramId}
              brandId={brandId}
              productId={productData.id}
              basePrice={productData?.purchasePrice?.amount}
              baseData={baseData}
              priceType={productData?.purchasePriceType}
              simulateFrom="pim"
            />
          </Wrapper.Body>
        </Wrapper>
      )}
      {!loading && (
        <WithPermission permissions={["PS_UpdatePimProduct"]}>
          <Wrapper className="sticky bottom-0 z-20 shadow-card">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                type="button"
                variant="primary"
                disabled={!isChanged}
                onClick={editProduct}
                loading={submitLoading}
              >
                <Text>global.buttons.saveChanges</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      )}
    </div>
  );
};

export default PriceTab;
