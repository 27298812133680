import {Button, CheckBox, Drawer, Text, Toggler, SelectLang} from "components";
import {useContext, useEffect, useState} from "react";
import {PimContext} from "../details";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";

import {NotificationManager} from "common/notifications";

type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  getProductAttributes: () => void;
  languages: any;
};

const CopyDefaultValuesDrawer = ({
  isOpen,
  toggle,
  getProductAttributes,
  languages,
}: drawerProps) => {
  const {channels, productData} = useContext(PimContext);
  const [selectedChannels, setChannels] = useState<string[]>([]);
  const [lang, setLang] = useState<string>("de");
  const [isOverwrite, toggleOverWrite] = useToggleState(true);
  const [loading, toggleLoading] = useToggleState();

  useEffect(() => {
    setChannels([]);
    const clone = channels?.map((channel: any) => channel?.code !== "CH000D");
    setChannels(clone);
  }, [isOpen]);

  const onChangeHandler = (code: string) => {
    let clone = [...selectedChannels];
    if (clone?.includes(code)) {
      clone = clone?.filter((channelCode: any) => channelCode !== code);
    } else {
      clone.push(code);
    }
    setChannels(clone);
  };

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.FILL_FORM_DEFAULT_VALUES;
    const body = {
      pimProductId: productData?.id,
      language: lang,
      channelCodes: selectedChannels,
      overwriteExistingValues: isOverwrite,
    };
    ServiceApi.post(url, body)
      .then(() => {
        toggleLoading();
        setChannels([]);
        toggle();
        getProductAttributes();
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
      })
      .catch(() => toggleLoading());
  };

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              Copy Value on Channels
            </span>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <p className="text-body-2">From : </p>
          <div className="grid grid-cols-2 gap-6">
            <div className="flex items-center justify-between rounded bg-gray-200 p-4">
              <span className="text-heading-6 text-gray-800">Channel</span>{" "}
              <span className="text-heading-5 text-teal-500">Default</span>
            </div>
            <div className="flex items-center justify-between rounded bg-gray-200 p-4">
              <span className="text-heading-6 text-gray-800">Language</span>{" "}
              <SelectLang
                value={lang}
                items={languages}
                onChangeLanguage={onChangeLanguage}
                className="bg-white"
              />
            </div>
          </div>
          <p className="text-body-2">To : </p>
          <Toggler
            label="overwriteExistingValues"
            size="sm"
            value={isOverwrite}
            setValue={toggleOverWrite}
            className="border-b pb-4"
            disabled={loading}
          />
          <p className="text-body-base text-gray-800">
            Choose channels that you want to fill with default value
          </p>
          {channels?.map((channel: any) => {
            return (
              <>
                {channel.code !== "CH000D" && (
                  <div
                    className={`${selectedChannels?.includes(channel.code) ? "bg-primary-light" : "bg-gray-100"} rounded p-6 *:!text-heading-5`}
                    key={channel.code}
                  >
                    <CheckBox
                      value={selectedChannels?.includes(channel.code)}
                      label={channel.title}
                      setValue={() => onChangeHandler(channel.code)}
                      disabled={loading}
                    />
                  </div>
                )}
              </>
            );
          })}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button
            type="button"
            onClick={toggle}
            variant="light"
            size="sm"
            disabled={loading}
          >
            <Text>global.buttons.cancel</Text>
          </Button>
          <Button
            onClick={onSubmitHandler}
            disabled={!selectedChannels?.length}
            size="sm"
            loading={loading}
          >
            <Text>global.buttons.submit</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
};

export default CopyDefaultValuesDrawer;
