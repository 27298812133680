import {Skeleton, Text, Wrapper, InputGroup, Icon} from "components";
import {NotificationManager} from "common/notifications";
import {Fragment, useContext} from "react";
import {CustomerContext} from "..";

export default function GeneralAsCompany() {
  const {userData, loading} = useContext<any>(CustomerContext);

  return (
    <div className="grid grid-cols-1 gap-8 lg:grid-cols-2">
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>customers.customerList.basicInfo</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <InputGroup
                label="customers.customerList.company"
                value={userData?.firstName}
                disabled
              />
              <InputGroup
                label="customers.customerList.emailAddress"
                value={userData?.emailAddress}
                disabled
              />
              <InputGroup
                label="customers.customerList.phoneNumber"
                value={
                  userData?.phoneNumber
                    ? `+${userData?.phoneNumber?.countryCode} ${userData?.phoneNumber?.number}`
                    : null
                }
                disabled
              />
              <InputGroup
                label="customers.customerList.cimNumber"
                value={userData?.cimNumber}
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      window.navigator.clipboard.writeText(userData?.cimNumber);
                      NotificationManager.success(
                        "global.toast.copy-msg",
                        "global.toast.copy-title",
                      );
                    }}
                  >
                    <Icon
                      className="ml-6 cursor-pointer text-primary"
                      icon={"files"}
                    />
                  </span>
                }
              />
              <InputGroup
                label="customers.customerList.registrationNumber"
                value={userData?.companyRegistrationNumber}
                disabled
                append={
                  <span
                    className="input-group-text"
                    onClick={() => {
                      window.navigator.clipboard.writeText(
                        userData?.companyRegistrationNumber,
                      );
                      NotificationManager.success(
                        "global.toast.copy-msg",
                        "global.toast.copy-title",
                      );
                    }}
                  >
                    <Icon
                      className="ml-6 cursor-pointer text-primary"
                      icon={"files"}
                    />
                  </span>
                }
              />
            </Fragment>
          )}
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h1 className="text-heading-2 font-semibold">
            <Text>customers.customerList.EligibleforTax</Text>
          </h1>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 gap-8">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : (
            <Fragment>
              <InputGroup
                label="customers.customerList.TaxID"
                value={userData?.taxId}
                disabled
              />
            </Fragment>
          )}
        </Wrapper.Body>
      </Wrapper>
    </div>
  );
}
