export const menu = {
  dashboard: "DASHBOARD",
  productManagement: {
    root: "PRODUCT MANAGEMENT",
    masterData: {
      root: "MASTER DATA",
      Attributes: "Attributes",
      AttributeGroups: "Attribute Groups",
      ProductFamilies: "Product Families",
      ProductCategories: "Product Categories",
      MeasurementUnits: "Measurement Units",
      Suppliers: "Suppliers",
      Brands: "Brands",
      ProductGroup: "Product group",
      ShippingMethods: "Shipping Methods",
      ProductLabelTemplates: "Product Label Templates",
    },
    products: {
      root: "PRODUCTS",
      AllProducts: "All products",
      ImportProduct: "Import product",
      publishedProducts: "Published Products",
      checkList: "Check List",
    },
    setting: {
      root: "SETTINGS",
      Configurations: "Configurations",
    },
  },
  salesManagement: {
    root: "SALES MANAGEMENT",
    sellOffSales: {
      root: "SELL-OFF SALES",
      sellOffProducts: "Sell-off products",
      salesTemplates: "Sales Templates",
      salesSettings: "Sales Settings",
      SellOffCommissions: "Sell-off Commissions",
      manageCheckList: "Manage Check List",
      salesChecklist: "Sales Check List",
      preparationPhase: "Preparation Phase",
    },
    settings: {
      root: "SETTINGS",
      salesChannels: "Product Channels",
    },
    reports: "REPORTS",
    kitchenSales: {
      root: "KITCHEN SALES",
      salesList: "Sales List",
      salesReport: "Sales Report",
      configuration: "Configuration",
    },
    salesArea: {
      root: "SALES AREA",
      locations: "Locations",
    },
    priceTemplates: {
      root: "PRICE TEMPLATES",
    },
    generalDiscount: {
      root: "GENERAL DISCOUNT",
    },
    salesChannels: {
      root: "PRODUCT CHANNELS",
    },
    commissionReports: {
      root: "Commission Reports",
    },
  },
  applications: {
    root: "APPLICATIONS",
    mobileApp: {
      root: "MOBILE APP",
      positions: "Positions",
      infoPages: "Info pages",
      contentManagement: "Content Management",
      settings: "Settings",
    },
  },
  thirdParty: {
    root: "THIRD PARTY",
    settings: "Settings",
    crmConfiguration: "CRM Configuration",
    erpConfiguration: "ERP Configuration",
    nopCommerce: "NOP Commerce",
    iwofurn: "Iwofurn",
  },
  company: {
    root: "COMPANY",
    profile: "Profile",
    stores: "Stores",
    legalEntities: "Legal Entities",
    employees: "Employees",
  },
  customers: {
    root: "CUSTOMERS",
    customerList: "Customer List",
    settings: "Settings",
    Appointments: "Appointments",
  },
  configurations: {
    root: "CONFIGURATIONS",
    notifications: {
      root: "NOTIFICATIONS",
      alerts: "Alerts",
      actionGroups: "Action Groups",
    },
    globalSettings: {
      root: "GLOBAL SETTINGS",
      apiKeys: "API Keys",
      ssoSettings: "SSO Settings",
      dataStorage: "Data Storage",
      templates: "TEMPLATES",
    },
    userManagement: {
      root: "USER MANAGEMENT",
      usersList: "Users List",
      userGroups: "User Groups",
      roles: "Roles",
    },
    addressConfiguration: {
      root: "ADDRESS CONFIGURATION",
      countries: "COUNTRIES",
    },
  },
  priceEngine: {
    root: "PRICE ENGINE",
    priceTemplates: {
      root: "Price Templates",
      purchasePrice: "Purchase Price",
      salesPrice: "Sales Price",
    },
    calculationUnites: {
      root: "Calculation Unites",
    },
    calculationLevelManagements: {
      root: "Calculation Level Managements",
    },
    priceSimulator: {
      root: "Price Simulator",
    },
  },
  channelMessages: {
    root: "Channel messages",
  },
  salesChannelMessages: {
    root: "Sales Channel messages",
  },
};
