import { NotificationManager } from "common/notifications";
import {
  Breadcrumb,
  Button,
  Form,
  Icon,
  Stepper,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import { useSelector, useTitleTranslations } from "hooks";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import AttributeType from "./tabs/attribute-type";
import InfoAndProperties from "./tabs/info-and-properties";
import Review from "./tabs/review";

export const AttributeContext = createContext({});

export default function Create() {
  const companySetting = useSelector(s => s.companySetting);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [data, setData] = useState<any>(null);
  const navigate = useNavigate();
  const { checkTranslations } = useTitleTranslations();
  const tabs = [
    {
      label: "productManagement.masterData.attributes.attributeType",
      desc: "productManagement.masterData.attributes.attributeTypeDesc",
      component: AttributeType,
    },
    {
      label: "productManagement.masterData.attributes.infoAndProperties",
      desc: "productManagement.masterData.attributes.infoAndPropertiesDesc",
      component: InfoAndProperties,
    },
    {
      label: "productManagement.masterData.attributes.review",
      desc: "productManagement.masterData.attributes.reviewDesc",
      component: Review,
    },
  ];
  const [loading, setLoading] = useState(false);
  const activeTab = tabs[activeTabIndex];
  const isFirstTab = activeTabIndex === 0;
  const isLastTab = activeTabIndex === tabs.length - 1;
  const isSecondTab = activeTabIndex === 1;

  useEffect(() => {
    let arr: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr.push({ labelTitle: "", description: "", language: item });
    });
    setData({
      defaultValue: "",
      isRequired: false,
      items: [],
      measurementFamilyCode: null,
      perChannel: false,
      perLanguage: false,
      translations: arr,
      type: 10,
      sortOrder: 0,
    });
  }, [companySetting]);

  const goNext = () => {
    if (isLastTab) return submit();
    if (isSecondTab) {
      const validTranslates = checkTranslations(data.translations);
      if (validTranslates) {
        setData({ ...data, translates: validTranslates })
        setActiveTabIndex(p => p + 1);
      }
    }
    else
      setActiveTabIndex(p => p + 1);
  };
  const goPrev = () => {
    if (isFirstTab) return;
    setActiveTabIndex(p => p - 1);
  };

  const submit = () => {
    setLoading(true);
    const url = URLS.ADD_ATTRIBUTES_URLS;
    ServiceApi.post(url, data)
      .then(res => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        navigate(`/productManagement/master-data/attributes/${res.data}?tab=1`);
      })
      .catch(() => { })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="space-y-6">
      <Breadcrumb />
      <div className="grid grid-cols-1 items-start gap-6 lg:grid-cols-12">
        <Stepper tabs={tabs as []} activeTab={activeTabIndex} />
        <Wrapper
          as={Form}
          onSubmit={goNext}
          className="col-span-full lg:col-span-8"
        >
          <Wrapper.Body className="mx-auto w-full space-y-8 lg:w-[calc(100%-9rem)]">
            <AttributeContext.Provider
              value={{
                data,
                setData,
              }}
            >
              {createElement(activeTab.component)}
            </AttributeContext.Provider>
            <div className="flex items-center justify-between">
              <Button
                type="button"
                variant="light"
                disabled={isFirstTab}
                onClick={goPrev}
              >
                <Icon icon="arrow-left" /> <Text>global.buttons.back</Text>
              </Button>
              <WithPermission permissions={["PS_CreateAttributeTemplate"]}>
                <Button
                  type="submit"
                  variant="primary"
                  loading={loading}
                  aria-label="cy-attribute-submit"
                >
                  {isLastTab ? (
                    <Fragment>
                      <Icon icon="check" /> <Text>global.buttons.submit</Text>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Text>global.buttons.next</Text>{" "}
                      <Icon icon="arrow-right" />
                    </Fragment>
                  )}
                </Button>
              </WithPermission>
            </div>
          </Wrapper.Body>
        </Wrapper>
      </div>
    </div>
  );
}
