import {allProducts} from "./all-products";
import {importProduct} from "./import-product";
import {Details} from "./details";
import {checklist} from "./check-list";

export const products = {
  allProducts: allProducts,
  importProduct: importProduct,
  Details,
  checklist,
};
