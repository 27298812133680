import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import AddDrawer from "./add-drawer";

type BrandItems = {
  brandId: string;
  code: string;
  name: string;
  description: string;
  logoThumbnailUrl: any;
  logoUrl: any;
};

type ListType = {
  items?: BrandItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function Brand() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [isOpenDrawerCreate, toggleDrawerCreate] = useToggleState(false);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_BRANDS_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, { ...config, signal: controller.signal })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailsPermission = usePermission("PS_GetBrand");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateBrand"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button
              type="button"
              onClick={toggleDrawerCreate}
              aria-label="cy-brand-add"
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.masterData.brand.addBrand</Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
            Object.keys(data).length !== 0 &&
            data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.brandId}>
                      <td>
                        <Link
                          to={getDetailsPermission ? item.brandId : ""}
                          className="inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <Table.Image
                            className="w-unset"
                            src={item?.logoThumbnailUrl}
                            alt={item.name}
                          />
                          <div className="space-y-4">
                            <h6 className="text-heading-6">{item.name}</h6>
                            <div>
                              <span className="text-body-2 text-gray-500">
                                {item.code}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="text-wrap text-left text-body-base leading-6 text-gray-600">
                        {item.description}
                      </td>
                      <td>
                        <WithPermission permissions={["PS_GetBrand"]}>
                          <Button
                            as={Link}
                            to={item.brandId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({ ...p, pageSize: e }))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddDrawer
        isOpen={isOpenDrawerCreate}
        toggle={toggleDrawerCreate}
        getData={getData}
      />
    </div>
  );
}
export default withPermission(Brand, ["PS_GetBrand"]);
