import { createElement, createContext, useState } from "react";
import { Tabs, Text } from "components";
import Units from "./tabs/units";
import Configurations from "./tabs/configurations";

type Props = {
  refId: string;
  level?: number;
  refCode?: string;
  refTitle?: string;
  levelData?: any;
  setLevelData?: any;
  toggleInfo?: any;
  setConfigurationIsChanged?: any;
};

export const PriceCalculationContext = createContext({} as Props);

export default function PriceCalculationTab({ refId, level, refCode, refTitle }: Props) {
  const [levelData, setLevelData] = useState<any>();
  const [configurationIsChanged, setConfigurationIsChanged] = useState(false);

  const tabs = [
    {
      label: "priceEngine.calculationLevel.units",
      component: Units,
    },
    {
      label: "priceEngine.calculationLevel.configurations",
      component: Configurations,
    },
  ];

  return (
    <Tabs
      activeKey={tabs[0].label}
      checkSave={configurationIsChanged ? true : false}
      onSave={() => setConfigurationIsChanged(false)}>
      <Tabs.ButtonGroup className="!gap-4">
        {tabs.map((e: any) => (
          <Tabs.Button
            key={e.label}
            eventKey={e.label}
            permission={e.permission}
            className="bg-white data-active:bg-primary data-active:text-white rounded-full !py-3 px-4 "
          >
            <Text>{e.label}</Text>
          </Tabs.Button>
        ))}
      </Tabs.ButtonGroup>
      {tabs.map((e: any) => (
        <Tabs.Item key={e.label} eventKey={e.label}>
          <PriceCalculationContext.Provider
            value={{
              refId,
              level,
              refCode,
              refTitle,
              levelData,
              setLevelData,
              setConfigurationIsChanged
            }}
          >
            {createElement(e.component)}
          </PriceCalculationContext.Provider>
        </Tabs.Item>
      ))}
    </Tabs>
  );
}
