import {Button, DatePicker, Drawer, Icon, Select, Text} from "components";
import {status} from "constant";
import {useSelector} from "hooks";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";
import {ChannelMessage, toggleProps} from "types";
type FilterDrawerProps = toggleProps & {
  params: URLSearchParams;
  setParams: (
    key: keyof ChannelMessage.Params,
  ) => (value: string | number | null) => void;
  resetFilter: () => void;
};
export default function FilterDrawer({
  isOpen,
  toggle,
  params,
  setParams,
  resetFilter,
}: FilterDrawerProps) {
  const {i18n} = useTranslation();
  const companySetting = useSelector(s => s.companySetting);
  const showDateValue = (key: string) => {
    const hasValue = params.has(key);
    const value = params.get(key) ?? "";
    if (hasValue) return new Date(value);
    return "";
  };
  const channels = useMemo(() => {
    const activeLanguage = i18n.language;
    let items = companySetting.channels?.map((e: any) => ({
      name: e.channelNameTranslates?.find(
        ({language}: any) => language === activeLanguage,
      )?.name,
      id: e.code,
    }));
    const test = items ? [{id: "", name: "All"}, ...items] : [];
    return test ?? [];
  }, [i18n.language, companySetting]);

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h2 className="flex-1 text-start text-heading-2">
            <Text>channelMessage.filter.filters</Text>
          </h2>
          <Button
            type="button"
            variant="danger"
            light
            size="sm"
            onClick={resetFilter}
          >
            <Icon icon="circle-xmark" />{" "}
            <Text>channelMessage.filter.resetFilterBtn</Text>
          </Button>
          <Button type="button" variant="light" size="sm" onClick={toggle}>
            <Icon icon="close" /> <Text>channelMessage.filter.closeBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <Select
            label="channelMessage.filter.status"
            placeholder="global.select"
            value={params?.get("status") ?? ""}
            items={status.pimChannelStatus}
            setValue={setParams("status")}
          />
          <Select
            label="channelMessage.filter.channel"
            placeholder="global.select"
            value={params?.get("channelCode") ?? ""}
            items={channels}
            setValue={setParams("channelCode")}
          />
          <div className="grid grid-cols-2 gap-x-2 gap-y-1">
            <span className="col-span-full text-sm text-gray-800">
              <Text>channelMessage.filter.dateRange</Text>
            </span>
            <DatePicker
              showYearDropdown
              showMonthDropdown
              placeholderText="channelMessage.filter.from"
              value={showDateValue("fromDate")}
              onChange={setParams("fromDate")}
              maxDate={showDateValue("toDate")}
            />
            <DatePicker
              showYearDropdown
              showMonthDropdown
              minDate={showDateValue("fromDate")}
              placeholderText="channelMessage.filter.to"
              value={showDateValue("toDate")}
              onChange={setParams("toDate")}
            />
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}
