import {
  Breadcrumb,
  DropdownButton,
  HandleName,
  Icon,
  Skeleton,
  Tabs,
  Wrapper,
} from "components";
import {useToggleState} from "hooks";
import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";
import {ServiceApi, URLS} from "services";
import {setProductFamilies} from "store/productFmilies";
import AddModal from "./AddModal";
import AttributeGroups from "./AttributeGroups";
import EditDrawer from "./EditDrawer";
import General from "./General";
import SubFamily from "./SubFamily";
import {usePermission} from "hooks";

export const ProductFamiliesContext = createContext<any>({id: ""});

const ProductFamilies = () => {
  const getPermission = usePermission("PS_GetAttributeTemplate");
  const addPermission = usePermission("PS_CreatePimProductFamily");
  const editPermission = usePermission("PS_UpdatePimProductFamily");

  const {i18n} = useTranslation();
  const [addLoading, setAddLoading] = useToggleState();
  const dispatch = useDispatch();
  const [productFamilies, setProductFamiliesState] = useState([]);
  const [root, setRoot] = useState<any>([]);
  const [subFamily, setSubFamily] = useState([]);
  const [loading, setLoading] = useToggleState();
  const [addModal, setAddModal] = useToggleState();
  const [editDrawer, toggleEdit] = useToggleState();
  const [selectedFamily, SetSelectedFamily] = useState("");

  const tabs = [
    {
      label: "productManagement.masterData.productFamilies.general",
      component: General,
    },
    {
      label: "productManagement.masterData.productFamilies.subFamily",
      component: SubFamily,
    },
    {
      label: "Attribute Groups",
      component: AttributeGroups,
    },
  ];

  const onAddBranch = () => {
    getProductFamilies();
  };

  const getSubFamily = (array: any, id: string) => {
    return array.filter((item: any) => item.parentId === id);
  };

  const getProductFamilies = () => {
    setLoading();
    const url = URLS.CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL;
    ServiceApi.get(url)
      .then(({data}) => {
        setProductFamiliesState(data);
        const root = data.filter((item: any) => !item.parentId)[0];
        setRoot(root);
        const subFamily = getSubFamily(data, root.pimProductFamilyId);
        setSubFamily(subFamily);
        setLoading();
      })
      .catch(err => console.log(err));
  };

  useEffect(() => {
    getProductFamilies();
  }, []);
  if (!getPermission) return <>Permission Error</>;
  //UI
  return (
    <>
      <Tabs activeKey={tabs[0].label}>
        {/* BreadCrumb */}
        <div className="space-y-4">
          <div className="flex items-center">
            <div className="flex-1">
              <Breadcrumb />
            </div>
          </div>
          {loading && (
            <>
              <Wrapper>
                <Skeleton.List />
              </Wrapper>
              <Wrapper>
                <Wrapper.Body>
                  <Skeleton.TextArea />
                </Wrapper.Body>
              </Wrapper>
            </>
          )}
          {!loading && (
            <>
              <Wrapper>
                <Wrapper.Body className="flex items-center justify-between">
                  <div className="space-y-4">
                    <span className="text-heading-5 font-semibold text-gray-800">
                      {
                        <HandleName
                          translations={root?.translates}
                          code={root?.code}
                          keyName="labelTitle"
                        />
                      }
                    </span>
                    <p className="text-body-2 font-medium">{root.code}</p>
                  </div>

                  <DropdownButton
                    aria-label="cy-productFamilies-dropdown-button"
                    iconOnly
                    className="min-w-[44px]"
                    variant={"light"}
                    items={[
                      {
                        id: "edit",
                        title:
                          "productManagement.masterData.productFamilies.edit",
                        permission: editPermission,
                        onClick: () => {
                          dispatch(setProductFamilies(root));
                          SetSelectedFamily(root.pimProductFamilyId);
                          toggleEdit();
                          // navigate(sub.pimProductFamilyId);
                        },
                      },
                      {
                        id: "addBranch",
                        title:
                          "productManagement.masterData.productFamilies.addBranch",
                        permission: addPermission,
                        onClick: (e: any) => {
                          SetSelectedFamily(root.pimProductFamilyId);
                          setAddModal();
                        },
                        ariaLabel: "cy-productfamilies-add",
                      },
                    ]}
                  >
                    <Icon icon={"ellipsis-vertical"} className="text-primary" />
                  </DropdownButton>
                </Wrapper.Body>
              </Wrapper>
              <ProductFamiliesContext.Provider
                value={{
                  root,
                  subFamily,
                  getSubFamily,
                  productFamilies,
                  getProductFamilies,
                }}
              >
                <SubFamily />
              </ProductFamiliesContext.Provider>
            </>
          )}
        </div>
      </Tabs>
      <AddModal
        isOpen={addModal}
        loading={addLoading}
        toggle={setAddModal}
        onConfirm={onAddBranch}
        selected={selectedFamily}
      />
      <EditDrawer
        isOpen={editDrawer}
        toggle={toggleEdit}
        getProductFamilies={getProductFamilies}
        selected={selectedFamily}
      />
    </>
  );
};

export default ProductFamilies;
