import { NotificationManager } from "common/notifications";
import {
  Button,
  DeleteModal,
  Icon,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import { useToggleState } from "hooks";
import { useContext, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { withPermission } from "hoc";
import { PimContext } from "..";
import AssignPlace from "../drawers/assign-place";

const LocationsTab = () => {
  const { productData, getPIMProduct, loading } = useContext(PimContext);
  const [locations, setLocations] = useState<any>([]);
  const [ids, setIds] = useState<any>([]);
  const [loadingPlace, setLoading] = useToggleState();
  const [isOpen, toggle] = useToggleState();
  const [deletModal, toggleDelete] = useToggleState();
  const [removeLoading, setRemoveLoading] = useToggleState();
  const [selectedItem, setSelectedItem] = useState<any>({});

  const searchLocations = () => {
    const url = URLS.GET_LOCATIONS_URL;
    const config = { pageNumber: 1, pageSize: 100, keyword: null };
    ServiceApi.get(url, { params: config }).then(({ data }) => {
      setLocations(data.items);
    });
  };

  useEffect(() => {
    searchLocations();
  }, []);
  useEffect(() => {
    let ids = productData?.locations?.map((loc: any) => loc?.code);
    setIds(ids);
  }, [productData]);

  const onDeleteHandler = () => {
    setRemoveLoading();
    const url = URLS.REMOVE_PLACES(productData?.id, selectedItem?.id);
    ServiceApi.delete(url).then(() => {
      NotificationManager.success(
        "global.toast.delete-msg",
        "global.toast.delete-title",
      );
      setRemoveLoading();
      toggleDelete();
      getPIMProduct();
    });
  };

  return (
    <div className="space-y-4">
      {loadingPlace ||
        (loading && [1, 2, 3, 4].map(key => <Skeleton.List key={key} />))}
      <Table>
        {productData?.locations?.map((place: any) => {
          return (
            <tr key={place.code}>
              <td className="space-y-2">
                <h5 className="text-gray-800">{place.name}</h5>
                <p className="text-body-2">{place.code}</p>
              </td>
              <td className="text-body-base font-normal text-gray-800">
                {place.storeCode}
              </td>
              <WithPermission permissions={["PS_UpdatePimProduct"]}>
                <td>
                  <Button
                    variant={"danger"}
                    light
                    size="sm"
                    onClick={() => {
                      setSelectedItem(place);
                      toggleDelete();
                    }}
                  >
                    <Icon icon="trash" />
                  </Button>
                </td>
              </WithPermission>
            </tr>
          );
        })}
      </Table>
      <WithPermission permissions={["PS_UpdatePimProduct"]}>
        <div
          onClick={toggle}
          className="flex-center flex h-[52px] cursor-pointer select-none gap-4 rounded-xl border border-dashed border-primary bg-gray-100 text-primary"
        >
          <Icon
            icon="plus"
            size="xs"
            className="rounded-md bg-primary-light p-2"
          />
          <h5 className="text-primary">
            <Text>productManagement.products.Details.assignPlace</Text>
          </h5>
        </div>
      </WithPermission>
      <AssignPlace
        isOpen={isOpen}
        toggle={toggle}
        ids={ids}
        id={productData?.id}
        onConfirm={getPIMProduct}
      />
      <DeleteModal
        isOpen={deletModal}
        toggle={toggleDelete}
        selected={{ name: selectedItem.placeDisplayName }}
        onConfirm={onDeleteHandler}
        loading={removeLoading}
      />
    </div>
  );
};

export default withPermission(LocationsTab, ["PS_GetLocation"]);
