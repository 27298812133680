import {Button, Modal, Text, Icon} from "components";

type DuplicateModalType = {
  isOpen: boolean;
  loading: boolean;
  toggle: () => void;
  onConfirm: (id: any) => void;
  selected: any;
};

export default function DuplicateModal({
  isOpen,
  loading = false,
  toggle,
  onConfirm,
  selected,
}: DuplicateModalType) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      width="w-[500px]"
      modalClassName="z-40"
    >
      <Modal.Body className="max-h-100 overflow-auto">
        <div className="mb-8 mt-8 pb-4 text-center text-[70px]">
          <Icon
            size="lg"
            className="text-blue-300"
            icon={"circle-exclamation"}
          />
        </div>
        <div className="text-center text-body-base text-gray-700">
          <span>
            <Text>are You Sure You Want To Duplicate</Text>
          </span>
        </div>
        <div className="py-4 text-center text-heading-6 text-gray-800">
          <h6>
            {selected?.name}
            <span>?</span>
          </h6>
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button onClick={toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.ImNotSure</Text>
        </Button>
        <Button
          aria-label="cy-category-delete-confirmation"
          type="submit"
          className=""
          //   variant="danger"
          onClick={() => onConfirm(selected.product)}
          loading={loading}
        >
          <Icon className="mr-2" icon={"plus"} />
          <Text>global.buttons.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
