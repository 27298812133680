import {
  Breadcrumb,
  Button,
  DropdownButton,
  DuplicateModal,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { useConverter, usePermission, useToggleState } from "hooks";
import { createContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import ConfigProduct from "./all-products-drawers/ConfigProduct";
import CreateDrawer from "./all-products-drawers/CreateDrawer";
import FilterDrawer from "./all-products-drawers/FilterDrawer";
import SelectFromBasket from "./all-products-drawers/SelectFromBasket";
import { NotificationManager } from "common/notifications";
import ImportFromExcel from "./details/drawers/import-excel";

export const ProductsContext = createContext<any>({ id: "" });

function AllProducts() {
  const [searchParams, setSearchParams] = useSearchParams();

  const importPermission = usePermission("PS_ImportPimProduct");
  const controller = new AbortController();
  const navigate = useNavigate();
  const [data, setData] = useState<any>();
  const [pimProductType, setPimProductType] = useState<number>(0);
  const [isOpenExcel, toggleExcel] = useToggleState();
  const [loadingList, setLoadingList] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [filterModal, setFilterModal] = useToggleState(false);
  const [duplicateToggle, toggle] = useToggleState(false);
  const [duplicateLoading, toggleDuplicateLoading] = useToggleState(false);
  //original filter data for reset
  const originalFilterData = {
    productId: null,
    categoryId: null,
    keyword: "",
    language: null,
    pageNumber: parseInt(searchParams.get("pageNumber") as string) || 1,
    pageSize:
      parseInt(searchParams.get("pageSize") as string) || config.pageSize,
    pimProductFamilyId: null,
    priceFrom: null,
    priceTo: null,
    programId: null,
    searchFields: "",
    sortType: null,
    supplierId: null,
    brandId: null,
    title: null,
    version: null,
    pimProductType: null,
  };
  const [selectedID, setSelectedID] = useState("");
  const [keyword, setKeyword] = useState("");
  const [isOpenModalCreate, toggleModalCreate] = useToggleState(false);
  const [showConfigProduct, toggleConfigProduct] = useToggleState(false);
  const [showSelectFromBasket, toggleSelectFromBasket] = useToggleState(false);
  const { convertDate, convertAmount } = useConverter();
  const [params, setParams] = useState<any>({});
  const [filterType, setType] = useState(null);

  useEffect(() => {
    setParams(originalFilterData);
  }, []);

  const getData = () => {
    const keys = Object.keys(params);
    if (keys.length) {
      const url = URLS.POST_SEARCH_LIST_PRODUCTS;
      const config = params;
      setLoadingList(true);
      ServiceApi.post(url, { ...config, signal: controller.signal })
        .then(({ data }) => {
          let newData = data;
          newData?.searchResult?.forEach((item: any) => {
            newData?.pimProductFamily?.forEach((productFamily: any) => {
              if (productFamily.value === item.pimProductFamilyId) {
                item["productFamily"] = productFamily.title;
              }
            });
            newData?.brand?.forEach((brand: any) => {
              if (brand.value === item.brandId) {
                item["brandName"] = brand.title;
              }
            });
            newData?.supplier?.forEach((supplier: any) => {
              if (supplier.value === item.supplierId) {
                item["supplierName"] = supplier.title;
              }
            });
          });

          setData(newData);
          setKeyword(params.searchFields);
        })
        .catch(() => {
          setData([]);
        })
        .finally(() => {
          setLoadingList(false);
        });
    }
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setSearchParams({
      pageNumber: "1",
      pageSize: "10",
    });
    setParams({ ...originalFilterData, pageNumber: 1, pageSize: 10 });
    setKeyword("");
  };

  const handleSetFilterData = (key: string) => {
    const pageNumber =
      key === "pageNumber"
        ? parseInt(searchParams.get("pageNumber") as string)
        : 1;
    return (value: any) => {
      if (key === "pageNumber") {
        setSearchParams({
          pageNumber: value,
          pageSize: params.pageSize,
        });
      } else if (key === "pageSize") {
        setSearchParams({
          pageNumber: params.pageNumber,
          pageSize: value,
        });
      } else {
        setSearchParams({
          pageNumber: "1",
          pageSize: params.pageSize,
        });
      }
      setParams((p: any) => ({
        ...p,
        pageNumber: pageNumber,
        filter: true,
        [key]: value,
      }));
    };
  };

  const getDetailsPermission = usePermission("PS_GetPimProduct");

  const onDuplicate = (product: any) => {
    toggleDuplicateLoading();
    const url = URLS.POST_CLONE_PRODUCT(product.id);
    ServiceApi.post(url)
      .then(({ data }) => {
        NotificationManager.success(
          "global.toast.duplicate-msg",
          "global.toast.duplicate-title",
        );
        navigate(data);
        toggleDuplicateLoading();
        toggle();
      })
      .catch(() => toggleDuplicateLoading());
  };

  const productType = [
    {
      label: "productManagement.products.allProducts.all",
      value: null,
    },
    {
      label: "productManagement.products.allProducts.standard",
      value: 0,
    },
    {
      label: "productManagement.products.allProducts.bundle",
      value: 1,
    },
  ];
  //UI
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreatePimProduct"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            // <Button type="button" onClick={toggleModalCreate}>
            //   <Icon className="mr-2" icon={"plus"} />
            //   <Text>productManagement.products.allProducts.addProduct</Text>
            // </Button>
            <DropdownButton
              aria-label="cy-create-product"
              arrowIcon={false}
              items={[
                {
                  id: "edit",
                  title:
                    "productManagement.products.allProducts.createNewProduct",
                  permission: true,
                  onClick: () => {
                    setPimProductType(0);
                    toggleModalCreate();
                  },
                },
                {
                  id: "bundle",
                  title:
                    "productManagement.products.allProducts.createBundleProduct",
                  permission: true,
                  onClick: () => {
                    setPimProductType(1);
                    toggleModalCreate();
                  },
                },
                {
                  id: "configProduct",
                  title:
                    "productManagement.products.allProducts.createWithIwofurn",
                  permission: importPermission,
                  onClick: toggleConfigProduct,
                },
                {
                  id: "selectFromBasket",
                  title:
                    "productManagement.products.allProducts.selectFromIwofurn",
                  permission: importPermission,
                  onClick: toggleSelectFromBasket,
                },
                {
                  id: "addBranch",
                  title:
                    "productManagement.products.allProducts.importFromPool",
                  permission: importPermission,
                  onClick: (e: any) => { },
                },
                {
                  id: "importExcel",
                  title:
                    "productManagement.products.allProducts.importFromExcel",
                  permission: importPermission,
                  onClick: toggleExcel,
                },
              ]}
            >
              <Icon className="mr-2" icon={"plus"} />
              <Text>productManagement.products.allProducts.addProduct</Text>
            </DropdownButton>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("searchFields")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onFilter={() => setFilterModal()}
        resetFilter={!params?.filter ? undefined : resetFilter}
        onReload={getData}
      />
      <div className="flex items-center gap-4">
        {productType?.map((type: any, index) => (
          <span
            key={index}
            className={`min-w-14 cursor-pointer rounded-lg p-3 text-center text-heading-6 font-semibold ${filterType === type.value
              ? "bg-primary text-white"
              : "bg-white text-gray-500"
              } ${loadingList && "pointer-events-none opacity-30"}`}
            onClick={() => {
              setParams((p: any) => ({ ...p, pimProductType: type.value }));
              setType(type.value);
            }}
          >
            <Text>{type.label}</Text>
          </span>
        ))}
      </div>
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.searchResult && data?.searchResult?.length !== 0 ? (
            <>
              <Table>
                {data?.searchResult?.map((item: any) => {
                  return (
                    <tr key={item.id}>
                      <td>
                        <Link
                          to={getDetailsPermission ? item.id : ""}
                          className="flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <Table.Image
                            className="w-unset"
                            src={item?.avatarThumbnailUrl}
                            alt={item.originalName}
                            imageClassName="h-20 w-32"
                          />
                          <div className="space-y-2 text-wrap py-2">
                            <h6 className="text-heading-6 font-semibold leading-6 text-gray-800">
                              {item.originalName}
                            </h6>
                            <div className="text-sm text-gray-500">
                              <Text>
                                productManagement.products.allProducts.productFamily
                              </Text>
                              :
                              <span className="ml-1">{item.productFamily}</span>
                            </div>
                            <div className="flex items-center space-x-4 text-body-2 font-medium text-gray-700">
                              {item.supplierName && (
                                <div>
                                  <span>{item.supplierName}</span>
                                </div>
                              )}
                              {item.brandName && (
                                <div>
                                  <span>{item.brandName}</span>
                                </div>
                              )}
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="space-y-1 text-left text-body-2 font-medium text-gray-600">
                        <div className="text-body-2 font-medium text-gray-500">
                          <Text>
                            productManagement.products.allProducts.article
                          </Text>
                          <span className="ml-1" aria-label="cy-article-number">
                            #{item.articleNumber}
                          </span>
                        </div>
                        {item.supplierArticleNumber && (
                          <div>
                            <Text>
                              productManagement.products.allProducts.supplier
                            </Text>
                            #:
                            <span className="ml-1">
                              {item.supplierArticleNumber}
                            </span>
                          </div>
                        )}
                        {item.referenceKey && (
                          <div>
                            <Text>
                              productManagement.products.allProducts.referenceKey
                            </Text>
                            :<span className="ml-1">{item.referenceKey}</span>
                          </div>
                        )}
                        {item.lastModifiedAt && (
                          <div>
                            <Text>
                              productManagement.products.allProducts.updateAt
                            </Text>
                            :
                            <span className="ml-1">
                              {convertDate(item.lastModifiedAt)}
                            </span>
                          </div>
                        )}
                        {item.lastModifiedByName && (
                          <div>
                            <Text>
                              productManagement.products.allProducts.updateBy
                            </Text>
                            :
                            <span className="ml-1">
                              {item.lastModifiedByName}
                            </span>
                          </div>
                        )}
                        {/* <div className="block xl:hidden">
                          <h6 className="text-heading-6 font-normal text-gray-700">
                            {convertAmount(item?.price?.amount)}
                          </h6>
                        </div> */}
                      </td>
                      {/* <td className="hidden space-y-4 xl:table-cell">
                        <div>
                          <h6 className="text-heading-6 font-normal text-gray-700">
                            {convertAmount(item?.price?.amount)}
                          </h6>
                        </div>
                      </td> */}
                      <td className="flex flex-col gap-4 xl:table-cell">
                        <WithPermission permissions={["PS_GetPimProduct"]}>
                          <Button
                            to={item.id}
                            variant="light"
                            size="sm"
                            className="mr-2"
                            onClick={() => {
                              setSelectedProduct(item);
                              toggle();
                            }}
                          >
                            <Icon icon="copy" />
                          </Button>
                          <Button
                            as={Link}
                            to={item.id}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={parseInt(params?.pageNumber) || 1}
                pageSize={params?.pageSize || config.pageSize}
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <ProductsContext.Provider value={{ id: selectedID }}>
        <FilterDrawer
          isOpen={filterModal}
          data={data}
          toggle={setFilterModal}
          onChange={handleSetFilterData}
          resetFilter={resetFilter}
          keyword={keyword}
          setKeyword={setKeyword}
          setParams={setParams}
          params={params}
        />
        <CreateDrawer
          isOpen={isOpenModalCreate}
          toggle={toggleModalCreate}
          getData={getData}
          pimProductType={pimProductType}
        />
        <ConfigProduct
          isOpen={showConfigProduct}
          toggle={toggleConfigProduct}
          getData={getData}
        />
        <SelectFromBasket
          isOpen={showSelectFromBasket}
          toggle={toggleSelectFromBasket}
          getData={getData}
        />
        <DuplicateModal
          isOpen={duplicateToggle}
          toggle={toggle}
          selected={{
            name: selectedProduct?.originalName,
            product: selectedProduct,
          }}
          loading={duplicateLoading}
          onConfirm={onDuplicate}
        />
        <ImportFromExcel isOpen={isOpenExcel} toggle={toggleExcel} />
      </ProductsContext.Provider>
    </div>
  );
}

export default withPermission(AllProducts, ["PS_GetPimProduct"]);
