export const Details = {
  print: "print",
  chooseTemplates: "choose templates",
  priceInOtherhannels: "price in other channels",
  searchChannelByCode: "search channel by code",
  priceInThisChannel: "price in this channel",
  thisChannel: "this channel",
  otherChannels: "other channels",
  results: "results",
  printHistory: "Print history",
  printDetails: "Print Details",
  download: "Download",
  title: "Title",
  requestDate: "Request date",
  status: "Status",
  error: "Error",
  overWritedPrice: "Overwrited Price",
  priceNow: "Price (Now)",
  availabilityStatus: "Availability status",
  priceWarning: {
    warningMessage:
      "Lorem ipsum dolor sit amet consectetur. Sit turpis pharetra pretium sit. Praesent cursus aliquam nunc tincidunt amet sed eget facilisis.",
    errorMessage:
      "Lorem ipsum dolor sit amet consectetur. Sit turpis pharetra pretium sit. Praesent cursus aliquam nunc tincidunt amet sed eget facilisis.",
  },
  inventory: "Inventory",
  priceInfo: "price info",
  linePrice: "Line Price",
  changedPrice: "Changed Price",
  editPrice: "Overwrite product price VK1",
  enterPrice: "Enter price",
  overwritePrice: "Overwrite price",
};
