import {
  Breadcrumb,
  Button,
  HandleName,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import CreateDrawer from "./create";

type ItemsType = {
  measurementFamilyId: string;
  code: string;
  truncated: string;
  translates: any;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function MeasurementUnits() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const [isOpen, toggle] = useToggleState(false);

  const getDetailsPermission = usePermission("PS_GetMeasurementFamily");

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_MEASUREMENT_FAMILY_URL;
    const config = { params };
    setLoadingList(true);
    ServiceApi.get(url, { ...config, signal: controller.signal })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["PS_CreateMeasurementFamily"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button onClick={() => toggle()} aria-label="cy-create-measurement-unit" as={Link}>
              <Icon className="mr-2" icon={"plus"} />
              <Text>
                productManagement.masterData.measurementUnits.addMeasurement
              </Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data &&
            Object.keys(data).length !== 0 &&
            data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.measurementFamilyId}>
                      <td className="space-y-4">
                        <Link
                          to={
                            getDetailsPermission ? item.measurementFamilyId : ""
                          }
                          replace={!getDetailsPermission}
                        >
                          <div>
                            <h6 className="text-heading-6 font-semibold text-gray-800">
                              {
                                <HandleName
                                  translations={item?.translates}
                                  code={item?.code}
                                  keyName="labelTitle"
                                />
                              }
                            </h6>
                          </div>
                        </Link>
                        <div>
                          <span className="text-body-base font-medium text-gray-500">
                            {item.code}
                          </span>
                        </div>
                      </td>
                      <td></td>
                      <td>
                        <WithPermission
                          permissions={["PS_GetMeasurementFamily"]}
                        >
                          <Button
                            variant="light"
                            size="sm"
                            as={Link}
                            to={item.measurementFamilyId}
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <CreateDrawer isOpen={isOpen} toggle={toggle} getData={getData} />
    </div>
  );
}

export default withPermission(MeasurementUnits, ["PS_GetMeasurementFamily"]);
