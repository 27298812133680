import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { usePermission, useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi, URLS } from "services";
import FilterDrawer from "./FilterDrawer";

type EmployeesListItems = {
  employeeId: string;
  firstName: string;
  lastName: string;
  companyName: string;
  emailAddress: string;
  store: any;
  departments: any;
  employeeNumber: string;
  userType: string;
  accountEnabled: boolean;
};

type ListType = {
  items?: EmployeesListItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function EmployeesList() {
  const controller = new AbortController();
  const getDetailsPermission = usePermission("AS_GetEmployee");
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [filterModal, setFilterModal] = useToggleState(false);
  const [keyword, setKeyword] = useState("");
  const [searchParams] = useSearchParams();
  const originalFilterData = {
    keyword: "",
    userCreatedFromDate: null,
    userCreatedToDate: null,
    pageNumber: "1",
    pageSize: config.pageSize,
  };
  const [params, setParams] = useSearchParams();

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url =
      URLS.GET_EMPLOYEES_LIST_URL +
      `?pageNumber=${params?.pageNumber}&pageSize=${params?.pageSize}&keyword=${params?.keyword}&userCreatedFromDate=${params?.userCreatedFromDate ? params?.userCreatedFromDate : ""
      }&userCreatedToDate=${params?.userCreatedToDate ? params?.userCreatedToDate : ""
      }`;
    setLoadingList(true);
    ServiceApi.get(url, { signal: controller.signal })
      .then(res => {
        setData(res?.data);
        setKeyword(params.keyword);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const resetFilter = () => {
    setParams({});
    setKeyword("");
  };

  const toDateString = (value: string) => new Date(value).toDateString();

  const handleSetFilterData = (key: string) => {
    return (value: any) => {
      if (key === "userCreatedFromDate" || key === "userCreatedToDate") {
        value = toDateString(value);
      }
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
      </div>
      <SearchBox
        value={keyword}
        onSubmit={handleSetFilterData("keyword")}
        disabled={loadingList}
        // onFilter={() => setFilterModal()}
        resetFilter={searchParams?.size === 0 ? undefined : resetFilter}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {!!data?.items?.length ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.employeeId}>
                      <td>
                        <Link
                          to={getDetailsPermission ? item.employeeId : ""}
                          className="flex-center inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <Table.Image
                            placeholder="user"
                            src=""
                            alt={item.employeeId}
                            className="h-[78px] w-[78px]"
                          />
                          <div className="space-y-2">
                            <div>
                              <h6 className="text-heading-6 font-semibold text-gray-800">{`${item?.firstName} ${item?.lastName}`}</h6>
                            </div>
                            <div>
                              <Icon
                                className="mr-2 font-black text-gray-500"
                                size="1x"
                                icon="user-tag"
                              />
                              <span className="text-body-2 font-medium text-gray-500">
                                {item?.companyName}
                              </span>
                            </div>
                            <div>
                              <Icon
                                className="mr-2 font-black text-gray-500"
                                size="1x"
                                icon="envelope"
                              />
                              <span className="text-body-2 font-medium text-gray-500">
                                {item?.emailAddress}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="space-y-2 text-left">
                        <div>
                          <span className="text-body-base font-medium text-gray-600">
                            {item?.store?.title}
                          </span>
                        </div>
                        {/* <div><span className="text-body-base text-gray-600 font-medium">{handelDepartmentsLables(item?.departments)}</span></div> */}
                        <div>
                          <span className="mr-1 text-body-base font-medium text-gray-800">
                            <Text>company.employees.employeeNumber</Text>:
                          </span>
                          <span className="text-body-base font-medium text-gray-600">
                            {item?.employeeNumber}
                          </span>
                        </div>
                      </td>
                      {/* <td>
                    <Status.EmployeesType id={item?.userType} />
                  </td> */}
                      <td>
                        <Status.EmployeesStatus id={item?.accountEnabled} />
                      </td>
                      <td>
                        <WithPermission permissions={["AS_GetEmployee"]}>
                          <Button
                            as={Link}
                            to={item.employeeId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetFilterData("pageNumber")}
                onPageSizeChange={handleSetFilterData("pageSize")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <FilterDrawer
        isOpen={filterModal}
        data={data}
        keyword={keyword}
        setKeyword={setKeyword}
        toggle={setFilterModal}
        onChange={handleSetFilterData}
        resetFilter={resetFilter}
        setParams={setParams}
        params={params}
      />
    </div>
  );
}

export default withPermission(EmployeesList, ["AS_GetEmployee"]);
