import { Badge, NoItems, Text, Wrapper } from "components";
import { useConverter } from "hooks";
import i18n from "langs/i18n";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Area, AreaChart, ResponsiveContainer, Tooltip, XAxis } from "recharts";
import { ServiceApi } from "services";
type productType = {
  count: number;
  soldDate: Date;
  status: number;
  sumPrice: number;
};
export default function SoldSellOffProducts() {
  const controller = new AbortController();

  const { convertDate } = useConverter();
  const [data, setData] = useState<productType[]>([]);
  const hasData = !isEmpty(data);
  const convertChartDate = (value: any) => {
    const date = new Date(value);
    return date.toLocaleString(i18n.language, {
      month: "2-digit",
      day: "2-digit",
    });
  };
  const getData = () => {
    const dateFrom = new Date();
    const dateTo = new Date();
    dateFrom.setDate(dateTo.getDate() - 7);
    const url = "/productservice/api/selloffproducts/search-sold";
    const body = {
      soldDateFrom: dateFrom,
      soldDateTo: dateTo,
    };
    const config = { signal: controller.signal };

    ServiceApi.post(url, body, config).then(res => {
      const result =
        res?.data &&
        res?.data?.map((e: any, i: number) => ({
          ...e,
          soldDate: new Date(e.soldDate).getTime(),
        }));
      setData(result);
    });
  };
  useEffect(() => {
    getData();
    return () => controller.abort();
  }, []);
  return (
    <Wrapper className="xl:col-span-full">
      <Wrapper.Header className="flex items-center gap-4">
        <h2 className="flex-1 text-gray-800">
          <Text>dashboard.soldSellOffProducts</Text>
        </h2>
        <Badge variant="gray">
          <Text>dashboard.soldSellOffProductsBadge</Text>
        </Badge>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-4">
        {data ? (
          <ResponsiveContainer width="100%" height={275}>
            <AreaChart className="h-full w-full" data={data}>
              <defs>
                <linearGradient id="colorTotal" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset="5%"
                    stopColor="currentColor"
                    className="text-info"
                    stopOpacity={0.2}
                  />
                  <stop
                    offset="95%"
                    stopColor="currentColor"
                    className="text-info"
                    stopOpacity={0}
                  />
                </linearGradient>
              </defs>
              {hasData && (
                <XAxis
                  dataKey="soldDate"
                  className="text-primary"
                  tickFormatter={convertChartDate}
                />
              )}
              <Tooltip
                content={({ active, payload }) => {
                  if (!active || !payload) return;
                  return (
                    <div className="space-y-4 bg-white p-4 text-center text-base shadow">
                      <h6 className="text-info">
                        <Text>dashboard.soldSellOffProduct.count</Text>: {payload?.at(0)?.payload?.count}
                      </h6>
                      <h6 className="text-gray-600">
                        {convertDate(payload?.at(0)?.payload?.soldDate)}
                      </h6>
                    </div>
                  );
                }}
              />
              <Area
                type="linear"
                dataKey="count"
                stroke="currentColor"
                strokeWidth="2"
                fill="url(#colorTotal)"
                className="text-info"
                dot={{
                  stroke: "currentColor",
                  strokeWidth: 6,
                  r: 1,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div className="flex w-full flex-col items-center space-y-4">
            <NoItems />
          </div>
        )}
      </Wrapper.Body>
    </Wrapper>
  );
}
