export const status = {
  store: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
  employee: {
    active: "Aktiv",
    deActive: "Inaktiv",
  },
  salesList: {
    shippingCostHasWarning: "Warnung: Versandkosten",
    sellFactorHasWarning: "Warnung: Verkaufsfaktor",
    pendingApproval: "Genehmigung ausstehend",
    approved: "Genehmigt",
  },
  channelMessage: {
    all: "Alle",
    published: "Veröffentlicht",
    processed: "Verarbeitet",
    synced: "Synchronisiert",
    failed: "Fehlgeschlagen",
  },
  priceTemplates: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
  generalDiscount: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
  templateType: {
    salesOfferContract: "Angebot",
    salesOrderContract: "Bestellung",
    customerLegalContract: "Kundenvertrag",
    TermsAndConditions: "Geschäftsbedingungen",
  },
  calculationUnits: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
  supplier: {
    TradingGoods: "Handelswaren",
    ConsumableGoods: "Verbrauchsgüter",
  },
  availability: {
    active: "Aktiv",
    inactive: "Bestellware",
    salesOnly: "Abverkauf",
    blocked: "Ware blockiert",
  },
  productLabelActivation: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
  printRequest: {
    completed: "Druck komplett",
    onProcess: "Druck in Bearbeitung",
    failed: "Druck fehlgeschlagen",
  },
  pimCheckList: {
    active: "Aktiv",
    notActive: "Inaktiv",
  },
};
