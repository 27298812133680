import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";

type SalesTemplatesItems = {
  sellPhaseTemplateId: string;
  title: string;
  code: string;
  description: string;
};

type ListType = {
  items?: SalesTemplatesItems[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function SalesTemplates() {
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_SELL_PHASE_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, config)
      .then(({data}) => {
        setData(data);
      })
      .finally(() => {
        setLoadingList(false);
      });
  };
  useEffect(getData, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDetailsPermission = usePermission("SC_GetSellPhaseTemplate");

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["SC_CreateSellPhaseTemplate"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button as={Link} to="new">
              <Icon className="mr-2" icon={"plus"} />
              <Text>
                salesManagment.sellOffSales.salesTemplates.addTemplates
              </Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.sellPhaseTemplateId}>
                      <td className="w-[26rem]">
                        <Link
                          to={
                            getDetailsPermission ? item.sellPhaseTemplateId : ""
                          }
                          className="flex-center inline-flex w-fit gap-2"
                          replace={!getDetailsPermission}
                        >
                          <div className="space-y-4">
                            <div>
                              <h6 className="text-wrap text-heading-6 leading-6 text-gray-800">
                                {item.title}
                              </h6>
                            </div>
                            <div>
                              <span className="mr-1 text-body-2 text-gray-500">
                                <Text>
                                  salesManagment.sellOffSales.salesTemplates.code
                                </Text>
                                :
                              </span>
                              <span className="text-body-2 text-gray-500">
                                {item.code}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      <td className="w-[39rem] text-wrap text-left text-body-base leading-6 text-gray-600">
                        {item.description}
                      </td>
                      <td>
                        <WithPermission
                          permissions={["SC_GetSellPhaseTemplate"]}
                        >
                          <Button
                            as={Link}
                            to={item.sellPhaseTemplateId}
                            variant="light"
                            size="sm"
                          >
                            <Text>global.buttons.details</Text>
                          </Button>
                        </WithPermission>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={(e: any) =>
                  setParams(p => ({...p, pageSize: e}))
                }
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
    </div>
  );
}
export default withPermission(SalesTemplates, ["SC_GetSellPhaseTemplate"]);
