export const userList = {
  addUserGroup: "Benutzergruppe hinzufügen",
  addGroup: "Gruppe hinzufügen",
  editGroup: "Gruppe bearbeiten",
  name: "Name",
  description: "Beschreibung",
  role: "Rolle",
  chooseRole: "Rolle auswählen",
  inviteUser: "Benutzer einladen",
  addUser: "Benutzer hinzufügen",
  general: "Allgemein",
  group: "Gruppe",
  store: "Geschäft",
  stores: "Geschäfte",
  authenticationSetting: "Authentifizierungseinstellung",
  basicInfo: "Grundlegende Informationen",
  address: "Adresse",
  usersGroup: "Benutzergruppe",
  firstName: "Vorname",
  lastName: "Nachname",
  gender: "Geschlecht",
  dateOfBirth: "Geburtsdatum",
  personalNumber: "Personalnummer",
  phoneNumber: "Telefonnummer",
  mobileNumber: "Handynummer",
  emailAddress: "E-Mail-Adresse",
  active: "Aktiv",
  setDefault: "Als Standard festlegen",
  providerName: "Anbietername",
  userName: "Benutzername",
  activeLogin: "Aktive Anmeldung",
  generalDesc: "Vorname, Nachname, ...",
  location: "Standort",
  locationDesc: "Benutzerstandort",
  userGroup: "Benutzergruppe",
  userGroupDesc: "Benutzergruppe auswählen",
  review: "Überprüfung",
  reviewDesc: "Informationen abschließen",
  searchUser: "Benutzer suchen",
  search: "Suchen",
  addAsAnAdmin: "Als Administrator hinzufügen",
  userType: "Benutzertyp",
  admin: "Administrator",
  user: "Benutzer",
  local: "Lokal",
  add: "Hinzufügen",
  employee: "Mitarbeiter",
  assignEmployee: "Mitarbeiter zuweisen",
  searchEmployee: "Mitarbeiter suchen",
  selectEmployee: "Mitarbeiter auswählen",
  info: "Information",
  detailsEmployee: "Mitarbeiterdetails",
  password: "Passwort",
  reEnterpassword: "Passwort erneut eingeben",
  passwordDesc: "Passwort festlegen",
  setPasswordTitle: "Passwort festlegen (Optional)",
  setPassword: "Passwort festlegen",
  hasPassword: "wurde festgelegt",
  hasntPassword: "vom System generiert",
  salesChannels: "Sales Channels DE",
  asAdminstrator: "As adminstrator",
};
