import {
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
} from "components";
import { config } from "constant";
import { withPermission } from "hoc";
import { useConverter, useToggleState } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceApi } from "services";
import { ChannelMessage, WithPaging } from "types";
import ChannelMessageDetails from "./ChannelMessageDetails";
import FilterDrawer from "./FilterDrawer";

type Data = WithPaging<ChannelMessage.Item>;
type Params = ChannelMessage.Params;

function ChannelMessages() {
  const controller = new AbortController();
  const initParams: Params = {
    pageNumber: "1",
    pageSize: config.pageSize.toString(),
  };
  const { convertDate } = useConverter();
  const [data, setData] = useState<Data>({});
  const [params, setParams] = useSearchParams(initParams);
  const [loading, setLoading] = useState(false);
  const [activeId, setActiveId] = useState("");
  const [showFilter, toggleFilter] = useToggleState(false);

  const hasData = !!data.items?.length;
  const clearActiveId = () => {
    setActiveId("");
  };
  const handleSetParams = (key: keyof Params) => {
    return (value: string | number | null) => {
      setParams(p => {
        const hasValue = !!String(value ?? "");
        hasValue ? p.set(key, `${value}`) : p.delete(key);
        return p;
      });
    };
  };

  const getData = () => {
    setLoading(true);
    const toDateString = (value: string) => new Date(value).toDateString();
    const url = "/channelmanagerservice/api/channelmessage";
    const config = { params, signal: controller.signal };
    const fromDate = params.get("fromDate");
    const toDate = params.get("toDate");
    !!fromDate && config.params.set("fromDate", toDateString(fromDate));
    !!toDate && config.params.set("toDate", toDateString(toDate));
    ServiceApi.get(url, config)
      .then(res => {
        setData(res?.data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const resetFilter = () => {
    setParams(initParams);
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]);

  return (
    <div className="space-y-4">
      <Breadcrumb />
      <SearchBox
        value={params.get("keyword")}
        onSubmit={handleSetParams("keyword")}
        disabled={loading}
        totalItems={data?.totalItems}
        onFilter={toggleFilter}
        resetFilter={resetFilter}
        onReload={getData}
      />
      {loading ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {hasData ? (
            <>
              <Table>
                {data?.items?.map(e => {
                  const hasError = !!e.errorCode;
                  return (
                    <>
                      <tr key={e.channelMessageId}>
                        <td className="space-y-2">
                          <h6 className="text-heading-6 text-gray-800">
                            {e.aggregateType}
                          </h6>
                          <p className="text-body-2 text-gray-500">
                            <Text>channelMessage.list.aggregateId</Text>:{" "}
                            {e.aggregateIdentifier}
                          </p>
                          <div className="block space-y-2 xl:hidden">
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.channel</Text>:{" "}
                              <span className="font-bold uppercase">
                                {e.channelCode}
                              </span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.createdAt</Text>:{" "}
                              <span className="font-bold">
                                {convertDate(e.createdAt)}
                              </span>
                            </p>
                            <p className="text-body-2 text-gray-600">
                              <Text>channelMessage.list.updatedAt</Text>:{" "}
                              <span className="font-bold">
                                {convertDate(e.updatedAt) || "--"}
                              </span>
                            </p>
                          </div>
                          <div className="block space-y-2 xl:hidden">
                            <Status.PimChannelStatus id={e?.status} />
                            {hasError && (
                              <p className="text-wrap text-body-2 leading-5 text-warning-active">
                                <Icon icon="circle-info" />{" "}
                                <Text>{`errors.${e.errorCode}`}</Text>
                              </p>
                            )}
                          </div>
                        </td>
                        <td className="hidden space-y-2 text-start xl:table-cell">
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.channel</Text>:{" "}
                            <span className="font-bold uppercase">
                              {e.channelCode}
                            </span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.createdAt</Text>:{" "}
                            <span className="font-bold">
                              {convertDate(e.createdAt)}
                            </span>
                          </p>
                          <p className="text-body-2 text-gray-600">
                            <Text>channelMessage.list.updatedAt</Text>:{" "}
                            <span className="font-bold">
                              {convertDate(e.updatedAt) || "--"}
                            </span>
                          </p>
                        </td>
                        <td className="hidden space-y-2 xl:table-cell">
                          {/* <Status.ChannelMessage id={e.status} /> */}
                          <Status.PimChannelStatus id={e?.status} />
                          {hasError && (
                            <p className="text-wrap text-body-2 leading-5 text-warning-active">
                              <Icon icon="circle-info" />{" "}
                              <Text>{`errors.${e.errorCode}`}</Text>
                            </p>
                          )}
                        </td>
                        <td>
                          <Button
                            type="button"
                            variant="light"
                            size="sm"
                            onClick={() => setActiveId(e.channelMessageId)}
                          >
                            <Text>channelMessage.list.detailsBtn</Text>
                          </Button>
                        </td>
                      </tr>
                      <ChannelMessageDetails
                        channelMessageId={e.channelMessageId}
                        isOpen={e.channelMessageId === activeId}
                        toggle={clearActiveId}
                      />
                    </>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages}
                pageNumber={+(params.get("pageNumber") ?? 1)}
                pageSize={+(params.get("pageSize") ?? config.pageSize)}
                setActivePage={handleSetParams("pageNumber")}
                onPageSizeChange={handleSetParams("pageSize")}
              />
            </>
          ) : (
            <NoItems />
          )}
        </>
      )}
      <FilterDrawer
        isOpen={showFilter}
        toggle={toggleFilter}
        params={params}
        setParams={handleSetParams}
        resetFilter={resetFilter}
      />
    </div>
  );
}

export default withPermission(ChannelMessages, [
  "CMS_GetChannelMessage",
  "CMS_ChannelMessageFullAccess",
]);
