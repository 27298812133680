export const erpConfiguration = {
  name: "Name DE",
  inboundConnectionString: "In bound Connection string of the service bus DE",
  outboundConnectionString: "Out bound Connection string of the service bus DE",
  inboundQueueName: "Inbound Queue Name DE",
  outboundQueueName: "Outbound Queue Name DE",
  dynamics365: "Dynamics 365 DE",
  outboundQueueSubscriptionName: "Outbound Queue Subscription Name DE",
  erpInventoryBaseUrl: "Erp Inventory Base Url DE",
  environmentIdentifier: "Environment Id DE",
  tenantIdentifier: "Tenant Id DE",
  clientIdentifier: "Client Id DE",
  clientSecret: "Client Secret DE",
  inventory: "Inventory DE",
};
