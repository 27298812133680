import {
  Button,
  Drawer,
  Text,
  Icon,
  InputGroup,
  RadioButton,
  Form,
  CheckBox,
} from "components";
import { useEffect, useState } from "react";
import { unitTypes, hasManualUnitTypes, unitOperationType } from "constant";
import { PriceEngin } from "types";
import { rules } from "constant";
import { useToggleState } from "hooks";
import { ServiceApi, URLS } from "services";
import { NotificationManager } from "common/notifications";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
};

export function CreateCalculationUnit({ isOpen, toggle, getData }: Props) {
  const [loading, toggleLoading] = useToggleState();
  const [isChecked, toggleChecked] = useToggleState();
  const [data, setData] = useState<PriceEngin.CreateCalculationUnit>({
    title: "",
    description: "",
    operationType: 0,
    valueType: 0,
    itemValue: 0,
    minValue: 0,
    maxValue: 0,
    hasManualValue: false,
    calculationLevels: [0],
    availableForPurchase: true,
    availableForSale: true,
  });

  useEffect(() => {
    if (isOpen) {
      setData({
        title: "",
        description: "",
        operationType: 0,
        valueType: 0,
        itemValue: 0,
        minValue: 0,
        maxValue: 0,
        hasManualValue: false,
        calculationLevels: [0],
        availableForPurchase: true,
        availableForSale: true,
      });
    }
  }, [isOpen]);

  const onChangeHandler = (key: any, val?: number) => {
    return (value: any) =>
      setData((p: any) => ({ ...p, [key]: val || val === 0 ? val : value }));
  };

  const onSubmitHandler = () => {
    toggleLoading();
    const url = URLS.CREATE_CALCULATION_UNITS;
    ServiceApi.post(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleLoading();
        toggle();
        getData();
      })
      .catch(() => toggleLoading());
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Form onSubmit={onSubmitHandler}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>priceEngine.calculationUnits.addCalculationUnits</Text>
            </span>
            <Button variant={"light"} onClick={toggle} size="sm">
              <Icon icon="times" className="mr-2" />
              <Text>global.buttons.close</Text>
            </Button>
          </Drawer.Header>

          <Drawer.Body className="space-y-6">
            <InputGroup
              rules={rules.required}
              label={"priceEngine.calculationUnits.title"}
              value={data?.title}
              setValue={onChangeHandler("title")}
            />
            <CheckBox
              value={data?.hasManualValue}
              label="has Manual Value"
              setValue={onChangeHandler("hasManualValue")}
            />
            <div className="space-y-4">
              <span>
                <Text>priceEngine.calculationUnits.calculationUnitTypes</Text>
              </span>
              <div className="grid grid-cols-2 gap-2">
                <>
                  {data?.hasManualValue ? (
                    <>
                      {hasManualUnitTypes.map(unit => (
                        <div
                          key={unit.label}
                          className={`rounded-md p-4 ${data?.valueType === unit.value
                              ? "bg-primary-light"
                              : "bg-gray-100"
                            }`}
                        >
                          <RadioButton
                            label={unit.label}
                            value={data?.valueType === unit.value}
                            setValue={onChangeHandler("valueType", unit.value)}
                          />
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      {unitTypes.map(unit => (
                        <div
                          key={unit.label}
                          className={`rounded-md p-4 ${data?.valueType === unit.value
                              ? "bg-primary-light"
                              : "bg-gray-100"
                            }`}
                        >
                          <RadioButton
                            label={unit.label}
                            value={data?.valueType === unit.value}
                            setValue={onChangeHandler("valueType", unit.value)}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </>
              </div>
            </div>
            {[0, 1].includes(data?.valueType) && (
              <div className="space-y-4">
                <span>+ / -</span>
                <div className="flex gap-4">
                  {unitOperationType.map(type => (
                    <RadioButton
                      key={type.label}
                      label={type.label}
                      value={data?.operationType === type.value}
                      setValue={onChangeHandler("operationType", type.value)}
                    />
                  ))}
                </div>
              </div>
            )}
            {data?.valueType < 4 && !data?.hasManualValue && (
              <InputGroup
                label={"priceEngine.calculationUnits.defaultValue"}
                value={data?.itemValue}
                setValue={onChangeHandler("itemValue")}
                type="number"
              />
            )}
            {data?.hasManualValue && (
              <div className="flex gap-4">
                <InputGroup
                  label={"priceEngine.calculationUnits.minValue"}
                  value={data?.minValue}
                  setValue={onChangeHandler("minValue")}
                  type="number"
                  append={data?.valueType === 0 ? "%" : "€"}
                />
                <InputGroup
                  label={"priceEngine.calculationUnits.maxValue"}
                  value={data?.maxValue}
                  setValue={onChangeHandler("maxValue")}
                  type="number"
                  append={data?.valueType === 0 ? "%" : "€"}
                />
              </div>
            )}
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button size="sm" variant={"light"} onClick={toggle}>
              <Text>global.cancel</Text>
            </Button>
            <Button size="sm" type="submit" loading={loading}>
              <Text>priceEngine.calculationUnits.addCalculationUnit</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Form>
    </Drawer>
  );
}
