export const productLabelTemplate = {
  addProductLabelTemplate: "Neue Etikettenvorlage hinzufügen",
  editProductLabelTemplate: "Etikettenvorlage bearbeiten",
  title: "Titel",
  active: "aktiv",
  isGlobal: "globale gültig",
  file: "Ordner",
  metaData: "Metadaten",
  addMetaData: "neue Metadaten hinzufügen",
  key: "Schlüssel",
  value: "Wert",
  add: "hinzufügen",
  duplicateKey: "Schlüssel kann nicht dupliziert werden",
  language: "Sprache",
};
