import { NotificationManager } from "common/notifications";
import { Button, Icon, Modal, NoItems, Skeleton, Text } from "components";
import { useToggleState } from "hooks";
import { useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";

type AssignPlaceType = {
  isOpen: boolean;
  toggle: () => void;
  onConfirm: () => void;
  ids: any;
  id: string;
};

type LocationsItems = {
  code: string;
  name: string;
  storeCode: string;
  id: string;
};

export default function AssignPlace(props: AssignPlaceType) {
  const [data, setData] = useState<any>([]);
  const [selectedIndex, setIndex] = useState<number>();
  const [loading, setLoading] = useToggleState();
  const [loadingList, setLoadingList] = useToggleState();

  useEffect(() => {
    setData([]);
    setIndex(undefined);
    if (props.isOpen) {
      searchLocations();
    }
  }, [props.isOpen]);

  const searchLocations = () => {
    setLoadingList();
    const url = URLS.GET_LOCATIONS_URL;
    const config = { pageNumber: 1, pageSize: 100, keyword: null };
    ServiceApi.get(url, { params: config })
      .then(({ data }) => {
        setData(data.items);
        setLoadingList();
      })
      .catch(() => setLoadingList());
  };

  const onSubmit = () => {
    const location = data[selectedIndex as number];
    setLoading();
    const url = URLS.ADD_PLACES(props.id);
    delete location.id;
    const body = location;
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title"
        );
        setLoading();
        props.onConfirm();
        props.toggle();
      })
      .catch(() => setLoading());
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} modalClassName="z-40">
      <Modal.Header>
        <Text>productManagement.products.Details.assignPlace</Text>
      </Modal.Header>
      <Modal.Body className="overflow-auto min-h-[300px] space-y-4">
        {/* <SearchBox
          variant="gray"
          // onSubmit={(e) => searchLocations(e as string)}
          disabled={loadingList}
          className="[&>*]:w-full"
          formClassName={"w-full"}
        /> */}

        <div className="space-y-6">
          {loadingList && [1, 2, 3].map((key) => <Skeleton.Input key={key} />)}
          {!data.length && !loadingList ? (
            <div className="flex flex-col items-center w-full">
              <NoItems />
            </div>
          ) : (
            data?.map((item: LocationsItems, index: number) => {
              return (
                <div
                  onClick={() => {
                    if (!props.ids.includes(item?.code)) setIndex(index);
                  }}
                  className={`${
                    (index === selectedIndex ||
                      props.ids.includes(item?.code)) &&
                    "bg-primary-light"
                  } shadow-high p-4 rounded-xl flex justify-between items-center cursor-pointer hover:bg-primary-light`}
                >
                  <div className="space-y-1">
                    <h6 className="text-heading-6 text-gray-800 font-semibold">
                      {item.name}
                    </h6>
                    <div>
                      <span className="text-body-base text-gray-500 font-normal">
                        {item.code}
                      </span>
                    </div>
                  </div>
                  <div className="text-body-base text-gray-800">
                    {item.storeCode}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="flex items-center justify-between">
        <Button size="sm" onClick={props.toggle} variant="white">
          <Icon className="mr-2" icon={"times"} />
          <Text>global.buttons.close</Text>
        </Button>
        <Button
          type="button"
          className=""
          size="sm"
          onClick={onSubmit}
          disabled={selectedIndex === undefined}
          loading={loading}
        >
          <Text>global.buttons.submit</Text>
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
