import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {
  Breadcrumb,
  SearchBox,
  NoItems,
  Skeleton,
  DatePicker,
  Table,
  Button,
  Text,
  Pagination,
  Icon,
  Dropdown,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {SalesService} from "types";
import DetailsDrawer from "./details";
import {saveAs} from "file-saver";
import Filter from "./filter";
interface Params {
  keyword?: string;
  fromDate?: string;
  toDate?: string;
  orderId?: string;
  channelCode?: string;
  pageNumber?: number;
  pageSize?: number;
  sortBy: string;
}

const LineItemType = [
  "PimProduct",
  "IwofurnProduct",
  "ManualProduct",
  "ProductService",
  "ManualBundle",
  "PimBundle",
  "PimBundleItem",
  "SellOffProduct",
  "SellOffBundle",
  "SellOffBundleItem",
];

const SaleCommissionSource = ["PimProduct", "SupplierProgram"];

interface CommissionReportType {
  items: SalesService.SaleCommissionQueryDto[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
}

export default function CommissionReports() {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialParams = {
    pageNumber: parseInt(searchParams.get("pageNumber") as string) || 1,
    pageSize: parseInt(searchParams.get("pageSize") as string) || 10,
    channelCode: undefined,
    keyword: undefined,
    fromDate: undefined,
    toDate: undefined,
    employeeId: undefined,
    orderId: undefined,
    sortBy: "DESC",
  };
  const {convertDate, convertAmount} = useConverter();
  const [isOpen, toggleDrawer] = useToggleState();
  const [loading, toggle] = useToggleState();
  const [isOpenFilter, toggleFilter] = useToggleState();
  const [loadingExcel, toggleExcel] = useToggleState();
  const [dateFilter, dispatchDateFilter] = useState({
    id: "all",
    label: "All",
  });
  const [params, setParams] = useState<Params>(initialParams);

  const isFilter = [
    "channelCode",
    "keyword",
    "fromDate",
    "toDate",
    "orderId",
    "employeeId",
  ].some(item => params[item as keyof Params]);

  const countDefinedValues = Object.entries(params).filter(
    ([key, value]) =>
      !!value && !["pageNumber", "pageSize", "sortBy"].includes(key),
  ).length;

  const [commissionReports, setCommissionReports] =
    useState<CommissionReportType>();

  const [selectedCommission, dispatchCommission] =
    useState<SalesService.SaleCommissionQueryDto>();

  const getCommissionReports = () => {
    toggle();
    const url = URLS.GET_COMMISSION_REPORTS;
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        toggle();
        setCommissionReports(data);
      })
      .catch(() => toggle());
  };

  useEffect(() => {
    getCommissionReports();
  }, [params]);

  const onChangeHandler = (key: string) => {
    return (value: any) => setParams((p: any) => ({...p, [key]: value}));
  };

  const createSalesCommissionAmountType = (commission: any) => {
    const value = commission?.saleCommissionInfo?.calculationType
      ? convertAmount(commission?.saleCommissionInfo?.amount)
      : commission?.saleCommissionInfo?.amount + "%";
    return value;
  };

  const exportExcel = () => {
    toggleExcel();
    const url = URLS.EXPORT_COMMISSION_EXCEL;

    let filters = {...params};
    delete filters.pageNumber;
    delete filters.pageSize;

    ServiceApi.get(url, {
      responseType: "arraybuffer", // Ensures binary data
      params: {
        ...filters,
      },
    })
      .then(response => {
        const dirtyFileName = response.headers["content-disposition"];
        const regex =
          /filename[^;=\n]*=(?:(\\?['"])(.*?)\1|(?:[^\s]+'.*?')?([^;\n]*))/;
        var fileName = dirtyFileName.match(regex)[3];
        let blob = new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, fileName);
        toggleExcel();
      })
      .catch(() => toggleExcel());
  };

  const resetFilter = () => {
    setParams(initialParams);
    dispatchDateFilter({
      id: "all",
      label: "All",
    });
  };

  const dateFilterList = [
    {id: "all", label: "All"},
    {id: "week", label: "Last 7 days"},
    {id: "month", label: "Last 30 days"},
    {id: "custom", label: "Custom range"},
  ];

  const onDateFilterChange = (type: string) => {
    if (type === "all") {
      setParams(p => ({...p, fromDate: undefined, toDate: undefined}));
    } else if (type === "week") {
      const today = new Date();
      const todayISO = today.toISOString();
      const sevenDaysAgo = new Date();
      sevenDaysAgo.setDate(today.getDate() - 7);
      const sevenDaysAgoISO = sevenDaysAgo.toISOString();
      setParams(p => ({...p, fromDate: sevenDaysAgoISO, toDate: todayISO}));
    } else if (type === "month") {
      const today = new Date();
      const todayISO = today.toISOString();
      const Thirty = new Date();
      Thirty.setDate(today.getDate() - 30);
      const ThirtyISO = Thirty.toISOString();
      setParams(p => ({...p, fromDate: ThirtyISO, toDate: todayISO}));
    }
  };

  return (
    <>
      <div className="space-y-4">
        <h3 className="text-heading-3">
          <Breadcrumb />
        </h3>
        <div className="flex items-center justify-between">
          <div>
            <SearchBox
              value={params?.keyword}
              onSubmit={onChangeHandler("keyword")}
              totalItems={commissionReports?.totalItems}
              disabled={loading}
            />
          </div>
          <div className="flex items-center gap-2">
            {isFilter && (
              <Button
                disabled={loading}
                variant={"white"}
                textColor="danger"
                size="sm"
                onClick={resetFilter}
              >
                <Icon icon="circle-xmark"></Icon>
                <span className="ml-1">Reset Filter</span>
              </Button>
            )}
            <Button
              disabled={loading}
              variant={"white"}
              size="sm"
              onClick={toggleFilter}
            >
              <span className="ml-1">Filters</span>
              {!!countDefinedValues && (
                <span className="text-primary">{countDefinedValues}</span>
              )}
            </Button>
          </div>
          {/* <div> */}
          <Button onClick={exportExcel} loading={loadingExcel}>
            <Icon icon="file-excel" className="mr-1" />
            <Text>excel</Text>
          </Button>
          {/* </div> */}
        </div>
        <div className="flex items-center justify-between gap-4">
          <div className="flex items-center gap-2">
            <div className="col-span-1">
              <span className="text-heading-5 text-gray-700">
                <Text>salesManagment.commissionReports.createDate</Text>:
              </span>
            </div>
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                type="button"
                className="flex min-w-[10rem] space-x-2 rounded-md bg-white p-3 text-body-base font-medium uppercase text-primary"
              >
                <span>{dateFilter?.label} </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 min-w-fit p-2">
                <ul className="w-max text-gray-700">
                  {dateFilterList?.map((filter: any) => (
                    <li
                      onClick={() => {
                        dispatchDateFilter(filter);
                        onDateFilterChange(filter.id);
                      }}
                      className={`h6 px-4 py-2 font-medium first:mb-1 ${
                        filter.code === dateFilter?.label &&
                        "bg-primary-light text-primary "
                      } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                    >
                      {filter?.label}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
            <div>
              <DatePicker
                dense
                maxDate={params?.toDate ? params?.toDate : new Date()}
                placeholderText="priceEngine.purchasePrice.selectDate"
                value={params?.fromDate}
                onChange={onChangeHandler("fromDate")}
                disabled={dateFilter?.id !== "custom"}
              />
            </div>
            <div>
              <DatePicker
                dense
                minDate={params?.fromDate ? params?.fromDate : null}
                maxDate={new Date()}
                placeholderText="priceEngine.purchasePrice.selectDate"
                value={params?.toDate}
                onChange={onChangeHandler("toDate")}
                disabled={dateFilter?.id !== "custom"}
              />
            </div>
          </div>
          {/* <div> */}
          <Button
            onClick={exportExcel}
            loading={loadingExcel}
            disabled={loading}
            size="sm"
          >
            <Icon icon="file-excel" className="mr-1" />
            <Text>Excel</Text>
          </Button>
          {/* </div> */}
        </div>
        <div className="flex flex-wrap items-center gap-4">
          <span className="text-heading-5 text-gray-700">
            <Text>salesManagment.commissionReports.sortBy</Text>
          </span>
          <div className="flex gap-4 *:cursor-pointer *:rounded-xl *:px-4 *:py-2 *:text-heading-6">
            <span
              className={`p-3 text-heading-6  ${params.sortBy === "DESC" ? "bg-primary text-white" : "bg-white text-gray-500"}`}
              onClick={() => setParams(p => ({...p, sortBy: "DESC"}))}
            >
              <Text>salesManagment.commissionReports.newest</Text>
            </span>
            <span
              className={`${params.sortBy === "ASC" ? "bg-primary text-white" : "bg-white text-gray-500"}`}
              onClick={() => setParams(p => ({...p, sortBy: "ASC"}))}
            >
              <Text>salesManagment.commissionReports.oldest</Text>
            </span>
          </div>
        </div>
        {loading ? (
          [...Array(6)].map((index: number) => <Skeleton.List key={index} />)
        ) : !!commissionReports?.items?.length ? (
          <Table>
            {commissionReports?.items?.map(
              (commission: SalesService.SaleCommissionQueryDto) => {
                return (
                  <tr className="*:space-y-2 *:text-left *:text-body-2 *:text-gray-600">
                    <td>
                      <p className="text-heading-6 font-semibold text-gray-800">
                        {convertDate(commission.orderDate)}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.employeeCode
                        </Text>{" "}
                        : {commission.employeeCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.customerCode
                        </Text>{" "}
                        : {commission.customerNumber}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.channelCode
                        </Text>{" "}
                        : {commission.channelCode}
                      </p>
                    </td>
                    <td>
                      <p>
                        <Text>
                          salesManagment.commissionReports.orderNumber
                        </Text>{" "}
                        : {commission.orderNumber}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.supplierCode
                        </Text>{" "}
                        : {commission.supplierCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.lineItemType
                        </Text>{" "}
                        : {LineItemType[commission.lineItemType as number]}
                      </p>
                    </td>
                    <td>
                      <p>
                        <Text>
                          salesManagment.commissionReports.saleCommissionAmountType
                        </Text>
                        :{" "}
                        <span className={"font-medium text-success-active"}>
                          {createSalesCommissionAmountType(commission)}{" "}
                        </span>
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.secondEmployeeCode
                        </Text>
                        : {commission.secondEmployeeCode}
                      </p>
                      <p>
                        <Text>
                          salesManagment.commissionReports.totalSalePrice
                        </Text>{" "}
                        : {convertAmount(commission.totalSalePrice)}
                      </p>
                      <p>
                        <Text>salesManagment.commissionReports.quantity</Text> :
                        x{commission.quantity}
                      </p>
                    </td>
                    <td className="table-cell  gap-4 xl:table-cell">
                      <Button
                        variant="light"
                        size="sm"
                        onClick={() => {
                          toggleDrawer();
                          dispatchCommission(commission);
                        }}
                      >
                        <Text>global.buttons.details</Text>
                      </Button>
                    </td>
                  </tr>
                );
              },
            )}
          </Table>
        ) : (
          <NoItems />
        )}
        <Pagination
          totalItems={commissionReports?.totalItems}
          totalPages={commissionReports?.totalPages}
          pageNumber={params?.pageNumber || 1}
          pageSize={params?.pageSize}
          setActivePage={onChangeHandler("pageNumber")}
          onPageSizeChange={onChangeHandler("pageSize")}
        />
      </div>
      <DetailsDrawer
        isOpen={isOpen}
        toggle={toggleDrawer}
        commission={selectedCommission}
      />
      <Filter
        isOpen={isOpenFilter}
        toggle={toggleFilter}
        dispatchParams={setParams}
        params={params}
        isFilter={isFilter}
        resetFilter={resetFilter}
      />
    </>
  );
}
