import {Icon, NoItems, Skeleton, Text, Wrapper} from "components";
import {Fragment, useEffect, useState} from "react";
import {ServiceApi} from "services";
type statusType = {
  count: number;
  title: string | null;
  value: string;
};
type sellOfProductType = {
  icon: string;
  label: string;
  value: number;
  className: string;
};
const sellOfProducts: sellOfProductType[] = [
  {
    icon: "clock-three",
    label: "dashboard.sellOffProduct.preparation",
    value: 0,
    className: "bg-warning-light text-warning",
  },
  {
    icon: "cart-shopping",
    label: "dashboard.sellOffProduct.active",
    value: 0,
    className: "bg-success-light text-success",
  },
  {
    icon: "clipboard-check",
    label: "dashboard.sellOffProduct.sold",
    value: 0,
    className: "bg-info-light text-info",
  },
  {
    icon: "square-xmark",
    label: "dashboard.sellOffProduct.closed",
    value: 0,
    className: "bg-danger-light text-danger",
  },
];
export default function SellOffProducts() {
  const controller = new AbortController();
  const [data, setData] = useState<sellOfProductType[]>([]);
  const [loading, setLoading] = useState(false);
  const getData = () => {
    setLoading(true);
    const url = "/productservice/api/selloffproducts/search-sell-off";
    const body = {pageNumber: 1, pageSize: 10};
    const config = {signal: controller.signal};
    ServiceApi.post(url, body, config)
      .then(({data}) => {
        const status: statusType[] = data?.status;
        const result = sellOfProducts?.map(product => {
          const resultItem = status?.find(
            e => e.value.toLowerCase() === product.label.toLowerCase(),
          );
          product.value = resultItem?.count ?? 0;
          return product;
        });
        setData(result);
      })
      .catch(() => {
        setData([]);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    return () => controller.abort();
  }, []);

  return (
    <Wrapper>
      <Wrapper.Header>
        <h2 className="text-gray-800">
          <Text>dashboard.sellOffProducts</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body className="space-y-4">
        <ul className="space-y-4">
          {loading ? (
            <Fragment>
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Fragment>
          ) : data && data.length !== 0 ? (
            data?.map(e => (
              <li
                key={e.label}
                className={`flex items-center gap-4 rounded p-4 ${e.className}`}
              >
                <span className="flex-center h-5 w-5">
                  <Icon icon={e.icon} />
                </span>
                <h5 className="flex-1 font-normal text-gray-700">
                  <Text>{e.label}</Text>
                </h5>
                <h3 className="text-gray-700">{e.value}</h3>
              </li>
            ))
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
