import {DatePicker, Icon, Text, HandleName} from "components";
import {useContext} from "react";
import {AttributeContext} from ".";
import {PimContext} from "..";
import {findValue} from "../../helpers/find-attribute.value";
import {updateValue} from "../../helpers/update-attribute-value";

const Time = () => {
  const {
    attribute,
    values,
    channel,
    language,
    id,
    setValues,
    flag,
    setAttribute,
    toggle,
    onChangeValue,
    showInChannel,
    attributeGroupIndex,
    attributeIndex,
    attributeTemplateId,
    disabled,
    isDefault,
    channelTitle,
  } = useContext(AttributeContext);
  const {channels} = useContext(PimContext);
  const label = attribute.isRequired ? (
    <>
      {showInChannel && (
        <Icon icon="star" size="sm" className="mr-1 text-primary" />
      )}
      <HandleName
        translations={attribute?.translates}
        code={attribute?.code}
        keyName="labelTitle"
      />
      <Icon icon="star" size="sm" className="ml-1 text-danger" />{" "}
      {attribute?.hasChanged && channel !== "CH000D" && (
        <div className="inline-block rounded-md bg-warning-light p-2 text-warning-active">
          <Text>productManagement.products.Details.warning</Text>
          <Icon icon="star" size="sm" className="ml-1 text-warning " />
        </div>
      )}
    </>
  ) : (
    <>
      {showInChannel && (
        <Icon icon="eye-slash" size="sm" className="mr-1 text-primary" />
      )}
      <HandleName
        translations={attribute?.translates}
        code={attribute?.code}
        keyName="labelTitle"
      />
      {attribute?.hasChanged && channel !== "CH000D" && (
        <div className="ml-2 inline-block rounded-md bg-warning-light p-2 text-warning-active">
          <Text>productManagement.products.Details.warning</Text>
          <Icon icon="star" size="sm" className="ml-1 text-warning " />
        </div>
      )}
    </>
  );

  const {value} = findValue(
    attribute,
    values?.[attributeGroupIndex],
    attributeTemplateId,
    channel,
    language,
    isDefault,
  );

  const onChangeHandler = (e: any) => {
    const {data, changedAttribute} = updateValue(
      attribute,
      values?.[attributeGroupIndex],
      attributeTemplateId,
      e,
      channel,
      language,
      undefined,
      isDefault,
    );
    const changedValues = [...values];
    const changedIndex = changedValues[attributeGroupIndex]?.findIndex(
      (item: any) => item.attributeTemplateId === attributeTemplateId,
    );
    changedValues[attributeGroupIndex][changedIndex] = changedAttribute;
    setValues(changedValues);
    onChangeValue(data, attribute.code);
  };
  const perChannelTemplate = (value: any) => {
    channels?.map((channel: any) => {
      const {data, changedAttribute} = updateValue(
        attribute,
        values?.[attributeGroupIndex],
        attributeTemplateId,
        value,
        channel.code,
        language,
      );
      const changedValues = [...values];
      const changedIndex = changedValues[attributeGroupIndex]?.findIndex(
        (item: any) => item.attributeTemplateId === attributeTemplateId,
      );
      changedValues[attributeGroupIndex][changedIndex] = changedAttribute;
      setValues(changedValues);
      onChangeValue(data, attribute.code);
    });
  };
  let items = [
    {
      title: <Text>productManagement.products.Details.compareValues</Text>,
      id: "compare",
      onClick: () => {
        setAttribute(attribute);
        toggle();
      },
    },
  ];
  if (attribute.perChannel && !disabled) {
    items.push(
      attribute.perChannel && {
        title: (
          <Text>productManagement.products.Details.setValueForAllChannel</Text>
        ),
        id: "setValue",
        onClick: () => {
          perChannelTemplate(value);
        },
      },
    );
  }
  return (
    <DatePicker
      placeholder={attribute?.translations?.[0]?.placeHolder}
      label={label}
      onChange={onChangeHandler}
      value={value && new Date(value)}
      dateFormat="h:mm aa"
      showTimeSelect
      showTimeSelectOnly
      flag={attribute?.perLanguage ? flag[language] : null}
      hint={
        attribute?.perChannel && channel !== "CH000D"
          ? {label: channelTitle}
          : null
      }
      actions={items}
    />
  );
};

export default Time;
