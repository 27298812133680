import {NotificationManager} from "common/notifications";
import {
  Button,
  DeleteModal,
  Icon,
  Skeleton,
  Status,
  Text,
  Wrapper,
  InputGroup,
  NoItems,
  HandleName,
  SelectSearchable,
} from "components";
import {useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import AttributeDrawer from "./AttributeDrawer";
import {onFilter} from "utils";

type Props = {
  id: string;
  selected: string;
};

const AttributeGroups = ({id, selected}: Props) => {
  const {i18n} = useTranslation();
  const [isOpen, toggle] = useToggleState();
  const [data, setData] = useState<any>([]);
  const [dataList, setDataList] = useState<any>([]);
  const [loading, setLoading] = useToggleState();
  const [buttonLoading, setButtonLoading] = useToggleState();
  const [deleteLoading, setDeleteLoading] = useToggleState();
  const [selectedAttribute, setSelectedAttribute] = useState<any>([]);
  const [selectedAvailableAttr, setSelectedAvailableAttr] = useState<any>("");
  const [deleteModal, setDeleteModal] = useToggleState();
  const [deleteData, setDeleteData] = useState<any>({});
  const [searchValue, setSearchValue] = useState("");

  const getAttribute = () => {
    setLoading();
    const url2 = URLS.GET_ALL_ATTRIBUTE(id);
    ServiceApi.get(url2)
      .then(({data}) => {
        setData(data);
        setDataList(data);
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    if (id && selected === id) {
      getAttribute();
    }
  }, [id]);

  const addAttrToProductFamilies = () => {
    setButtonLoading();
    const body = {
      attributeGroupTemplateId: selectedAvailableAttr?.value,
    };
    const url = URLS.ADD_ATTRIBUTE_TO_PIM_PRODUCT_FAMILY_URL(id);
    ServiceApi.put(url, body)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        setButtonLoading();
        setSelectedAvailableAttr("");
        getAttribute();
      })
      .catch(() => setButtonLoading());
  };

  const onDeleteHandler = () => {
    setDeleteLoading();
    const body = {
      attributeGroupTemplateId: deleteData.attributeGroupTemplateId,
    };
    const url = URLS.DELETE_ATTRIBUTE_FROM_PIM_PRODUCT_FAMILY_URL(id);
    ServiceApi.put(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        setDeleteModal();
        setDeleteLoading();
        getAttribute();
      })
      .catch(() => {
        setDeleteModal();
        setDeleteLoading();
      });
  };

  const onSearchHandler = (e: string) => {
    let keys = ["translates", "code"];
    if (e !== "") {
      setDataList(onFilter(data, keys, e));
    } else {
      setDataList(data);
    }
    setSearchValue(e);
  };

  const handleSearchAttr = async (filter: string) => {
    const url = URLS.GET_AVAILABLE_ATTRIBUTE(id);
    const params = {
      keyword: filter,
    };
    return ServiceApi.get(url, {params}).then(({data}) => {
      const attrList = data.map((attr: any) => {
        const label = attr?.translates?.find(function (item: any) {
          return (
            i18n.language?.toLocaleLowerCase() ===
              item.language?.toLocaleLowerCase() && item
          );
        });
        return {
          value: attr.attributeGroupTemplateId,
          label:
            label?.labelTitle || `No name in ${i18n.language} [${attr.code}]`,
        };
      });
      return attrList;
    });
  };

  const promiseOptionsAttribute = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      // setTimeout(() => {
      resolve(handleSearchAttr(inputValue));
      // }, 200);
    });

  return (
    <>
      {loading || !id ? (
        <div className="space-y-4">
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </div>
      ) : (
        <Wrapper>
          <Wrapper.Body className="space-y-6">
            <div className="grid grid-cols-3 gap-4 rounded border border-dashed border-primary bg-primary-light p-4">
              <div>
                <SelectSearchable
                  value={selectedAvailableAttr || ""}
                  cacheOptions
                  loadOptions={promiseOptionsAttribute}
                  defaultOptions
                  onChange={e => setSelectedAvailableAttr(e)}
                />
              </div>
              <div>
                <Button
                  className="h-full"
                  onClick={addAttrToProductFamilies}
                  loading={buttonLoading}
                  disabled={!selectedAvailableAttr}
                >
                  <Text>
                    productManagement.masterData.productFamilies.addToFamily
                  </Text>
                </Button>
              </div>
            </div>
            {data?.length !== 0 && (
              <div className="grid grid-cols-5 items-center gap-4">
                <InputGroup
                  value={searchValue}
                  setValue={onSearchHandler}
                  placeholder="productManagement.masterData.attributesGroups.searchAttribute"
                  disabled={loading}
                />
                <p className="text-body-2">
                  {searchValue.length === 0
                    ? dataList?.length
                    : dataList?.length}{" "}
                  <Text>global.items</Text>
                </p>
              </div>
            )}
            {dataList?.length !== 0 ? (
              <div className="w-full ">
                <table
                  className={`-my-4 min-w-full border-separate border-spacing-y-4 text-center [&_td:first-child]:rounded-l-2xl [&_td:first-child]:text-left [&_td:last-child]:rounded-r-2xl [&_td:last-child]:text-right [&_td]:p-4 [&_tr]:rounded-2xl [&_tr]:bg-gray-100 [&_tr]:shadow`}
                >
                  <tbody>
                    {dataList?.map((attr: any) => {
                      return (
                        <tr>
                          <td>
                            <span className="text-heading-6 font-semibold">
                              <HandleName
                                translations={attr?.translates}
                                code={attr?.code}
                                keyName="labelTitle"
                              />
                            </span>
                          </td>
                          <td>
                            <span className="text-body-2 font-medium text-gray-500">
                              {attr.code}
                            </span>
                          </td>
                          <td className="space-x-4">
                            {attr.availability === 2 && (
                              <Status.ProductFamilies
                                id={
                                  "productManagement.masterData.productFamilies.inherit"
                                }
                              />
                            )}
                            {attr.availability !== 2 && (
                              <Button
                                size="sm"
                                variant="white"
                                onClick={() => {
                                  setDeleteData(attr);
                                  setDeleteModal();
                                }}
                              >
                                <Icon
                                  icon={"trash-xmark"}
                                  className="text-danger"
                                />
                              </Button>
                            )}
                            <Button
                              variant="white"
                              disabled={attr.templates?.length === 0}
                              onClick={() => {
                                setSelectedAttribute(
                                  attr.attributeGroupTemplateId,
                                );
                                toggle();
                              }}
                            >
                              <Text>
                                productManagement.masterData.productFamilies.attributes
                              </Text>
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className="flex w-full flex-col items-center space-y-4">
                <NoItems />
              </div>
            )}
          </Wrapper.Body>
        </Wrapper>
      )}
      <AttributeDrawer
        isOpen={isOpen}
        toggle={toggle}
        selectedAttribute={selectedAttribute}
      />
      <DeleteModal
        isOpen={deleteModal}
        loading={deleteLoading}
        toggle={setDeleteModal}
        onConfirm={onDeleteHandler}
        selected={{name: deleteData.code}}
      />
    </>
  );
};

export default AttributeGroups;
