import { useEffect } from "react";
import {
  Button,
  Modal,
  Form,
  InputGroup,
  Text,
  Icon,
  Skeleton,
  Toggler,
} from "components";
import { ServiceApi } from "services";
import { URLS } from "services";
import { useDataState, usePermission, useToggleState } from "hooks";
import { rules } from "constant";
import { NotificationManager } from "common/notifications";

type detailsModalType = {
  isOpen: boolean;
  toggle: (value?: boolean) => void;
  getData: () => void;
  selected?: string;
};

export default function DetailsModal({
  isOpen,
  toggle,
  getData,
  selected,
}: detailsModalType) {
  const updatePermission = usePermission("PS_UpdatePimProductCheckList");
  const [data, setData, setBaseData, isChanged] = useDataState({
    title: "",
    description: "",
    isActive: true
  });
  const [loading, toggleLoading] = useToggleState();
  const [loadingButton, toggleLoadingButton] = useToggleState();

  useEffect(() => {
    if (isOpen) {
      getDataDetails();
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  const getDataDetails = () => {
    toggleLoading()
    const url = URLS.PIM_CHECK_LIST_DETAILS_URL(selected);
    ServiceApi.get(url)
      .then(({ data }) => {
        setBaseData(data);
      })
      .finally(() => {
        toggleLoading();
      });
  };

  const submit = () => {
    toggleLoadingButton();
    const url = URLS.PIM_CHECK_LIST_DETAILS_URL(selected);
    ServiceApi.put(url, data)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        getData();
      })
      .finally(() => {
        toggle();
        toggleLoadingButton();
      });
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} modalClassName="z-40">
      <Form onSubmit={submit}>
        <Modal.Header className="flex flex-row">
          <h6 className="text-heading-2 text-gray-800">
            <Text>
              productManagement.products.checklist.checkListDetails
            </Text>
          </h6>
        </Modal.Header>
        <Modal.Body className="max-h-100 overflow-auto">
          <div className="space-y-6">
            {loading ? (
              <>
                <div className="mb-6">
                  <h6 className="text font-normal text-gray-800">
                    <Text>
                      productManagement.products.checklist.title
                    </Text>
                  </h6>
                </div>
                <Skeleton.Input />
              </>
            ) : (
              <InputGroup
                label="productManagement.products.checklist.title"
                value={data.title}
                className="border-white"
                setValue={title => setData(p => ({ ...p, title }))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loading ? (
              <>
                <div className="mb-6">
                  <h6 className="text font-normal text-gray-800">
                    <Text>
                      productManagement.products.checklist.shortDescription
                    </Text>
                  </h6>
                </div>
                <Skeleton.TextArea />
              </>
            ) : (
              <InputGroup
                as="short-textarea"
                label="productManagement.products.checklist.shortDescription"
                value={data.description}
                className="border-white"
                setValue={description => setData(p => ({ ...p, description }))}
                disabled={!updatePermission}
              />
            )}
            <div>
              {loading ? <Skeleton.Button /> :
                <Toggler
                  className="!w-fit"
                  label={
                    "productManagement.products.checklist.active"
                  }
                  value={data.isActive}
                  setValue={isActive => setData(p => ({ ...p, isActive }))}
                  disabled={!updatePermission}
                />}
            </div>
          </div>
        </Modal.Body>
        {updatePermission && (
          <Modal.Footer className="flex items-center justify-between">
            <Button type="button" onClick={toggle} variant="white">
              <Icon className="mr-2" icon={"times"} />
              <Text>global.buttons.close</Text>
            </Button>
            <Button type="submit" loading={loadingButton} disabled={!isChanged}>
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Modal.Footer>
        )}
      </Form>
    </Modal>
  );
}
