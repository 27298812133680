import {AxiosRequestConfig} from "axios";
import {ComponentProps, useEffect, useRef, useState} from "react";
import imageLoadingGif from "../assets/icons/image Loading.gif";
import ImagePlaceHolder from "assets/image/ImagePlaceholder.svg";
import UserplaceHolder from "assets/image/UserPlaceholder.svg";
import CompanyPlaceHolder from "assets/image/company.svg";
import Text from "./Text";
import Button from "./Button";
import Icon from "./Icon";
import LoadingSvg from "./LoadingSvg";

import {ServiceApi} from "services";
import {useToggleState} from "hooks";
type ImageProps = {
  src?: string | null;
  alt?: string;
  className?: string;
  imageClassName?: string;
  logo?: boolean;
  onClick?: () => void;
  placeholder?: "user" | "image" | "company";
  editor?: boolean;
  info?: boolean;
  cover?: boolean;
  setCover?: boolean;
  onInfoClick?: () => void;
  onSetCover?: () => void;
  setCoverLoading?: boolean;
  isDynamic?: boolean;
  onDeleteHandler?: () => void;
  loadingSize?: "sm" | "md";
};
// type imageProps = {
//   src?: string;
//   alt?: string;
//   // thumbnail?: string;
//   isDynamic?: boolean;
//   onClick?: () => void
// };
type imgProps = Omit<ComponentProps<"img">, keyof ImageProps>;
export default function Image({
  src,
  alt = "",
  className = "",
  placeholder = "image",
  imageClassName = "",
  logo = false,
  editor = false,
  info = false,
  setCoverLoading = false,
  setCover,
  cover,
  onClick,
  onInfoClick,
  onSetCover,
  onDeleteHandler,
  isDynamic = false,
  loadingSize,
}: ImageProps & imgProps) {
  const [loading, toggle] = useToggleState();
  const imageRef = useRef<HTMLImageElement>(null);
  const [source, setSrc] = useState<any>();
  const hasSrc = !!src;

  const imgPlaceHolder =
    placeholder === "user"
      ? UserplaceHolder
      : placeholder === "company"
        ? CompanyPlaceHolder
        : ImagePlaceHolder;

  const imageSrcHandler = () => {
    const element = imageRef.current;
    if (!hasSrc || !element) return;
    if (isDynamic) {
      //url
      toggle();
      const url = src;
      if (url.includes("/get-file")) {
        const config: AxiosRequestConfig = {
          responseType: "blob",
          params: {withoutSubscriptionKey: true},
        };
        ServiceApi.get(url, config)
          .then(({data}) => {
            setSrc(URL.createObjectURL(data));
            toggle();
          })
          .catch(() => {
            setSrc(imgPlaceHolder);
            toggle();
          });
        return;
      } else {
        toggle();
        setSrc(url);
      }
    }
    const image = new window.Image();
    image.src = src;
    image.onload = () => setSrc(src);
    image.onerror = () => setSrc(imgPlaceHolder);
  };
  useEffect(imageSrcHandler, [isDynamic, src, hasSrc]);

  const onClickHandler = () => {
    onClick && onClick();
  };

  return (
    <div
      onClick={onClickHandler}
      className={`${
        logo ? "flex justify-center" : "flex-center"
      } relative overflow-hidden rounded ${className}`}
    >
      {cover && (
        <Button
          type="button"
          size="sm"
          variant="white"
          textColor="primary"
          className="absolute left-2 top-2  opacity-80"
        >
          <Text>global.buttons.coverPhoto</Text>
        </Button>
      )}
      <div className="absolute right-2 top-2 flex gap-2">
        {onDeleteHandler && (
          <Button
            type="button"
            size="sm"
            className="!h-[32px] !w-[32px] !p-2 opacity-80"
            variant={"danger"}
            onClick={e => {
              onDeleteHandler();
              e.stopPropagation();
            }}
          >
            <Icon icon="trash" />
          </Button>
        )}
        {editor && (
          <Button
            type="button"
            size="sm"
            className="!h-[32px] !w-[32px] !p-2 opacity-80"
          >
            <Icon icon="edit" />
          </Button>
        )}
      </div>
      {info && (
        <Button
          type="button"
          size="sm"
          className="absolute bottom-2 right-2  opacity-80"
          onClick={e => {
            onInfoClick && onInfoClick();
            e.stopPropagation();
          }}
        >
          <Icon icon="info" />
        </Button>
      )}
      {setCover && (
        <Button
          type="button"
          size="sm"
          className="absolute bottom-2 left-2  opacity-80"
          loading={setCoverLoading}
          onClick={e => {
            onSetCover && onSetCover();
            e.stopPropagation();
          }}
        >
          <Text>global.buttons.setAsCoverPhoto</Text>
        </Button>
      )}
      {loading ? (
        <LoadingSvg size={loadingSize} />
      ) : (
        <img
          ref={imageRef}
          src={source || imgPlaceHolder}
          // src={hasSrc ? imageLoadingGif : imgPlaceHolder}
          alt={alt}
          // loading={loading ?? "lazy"}
          data-src={src}
          className={`${imageClassName} object-contain`}
        />
      )}
    </div>
  );
}
