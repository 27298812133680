import {NotificationManager} from "common/notifications";
import {
  Button,
  Drawer,
  Icon,
  InputGroup,
  Skeleton,
  Text,
  Select,
} from "components";
import {useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import {SalesService} from "types";

type Props = {
  isOpen: boolean;
  inventoryConfigExisted: boolean;
  toggle: () => void;
  channelCode: string;
  pimProductId: string;
};

export default function PushToSellOff({
  isOpen,
  toggle,
  channelCode,
  pimProductId,
  inventoryConfigExisted,
}: Props) {
  let [searchParams] = useSearchParams();
  const [quantity, setQuantity] = useState(1);
  const [loading, toggleLoading] = useToggleState();
  const [loadingTemplate, toggleLoadingTemplate] = useToggleState();
  const [loadingInventory, toggleInventory] = useToggleState();
  const [selectedPhaseId, setSelectedPhaseId] = useState(null);

  const [inventories, setInventories] =
    useState<SalesService.GetPimProductAvailableInInventoryResultDto[]>();
  const [params] = useState<any>({
    channelCode: searchParams.get("channelCode"),
  });
  const [items, setItems] = useState([]);

  useEffect(() => {
    !isOpen && setQuantity(1);
    if (isOpen && inventoryConfigExisted) {
      getInventories();
    }
    if (isOpen) {
      getSellPhaseTemplates();
    }
  }, [isOpen]);

  const onSubmit = () => {
    toggleLoading();
    const url = URLS.PUSH_TO_SELL_OFF(pimProductId, channelCode);
    const body = {
      sellPhaseTemplateId: selectedPhaseId,
      quantity,
    };
    ServiceApi.post(url, body)
      .then(() => {
        toggle();
        toggleLoading();
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
      })
      .catch(err => toggleLoading());
  };

  const getInventories = () => {
    toggleInventory();
    const url = URLS.GET_PUBLISHED_PRODUCT_INVENTORY(pimProductId);
    const config = {params};
    ServiceApi.get(url, config)
      .then(({data}) => {
        toggleInventory();
        setInventories(data);
      })
      .catch(() => toggleInventory());
  };

  const getSellPhaseTemplates = () => {
    toggleLoadingTemplate();
    const url = URLS.GET_SELL_PHASE_TEMPLATES;
    ServiceApi.get(url)
      .then(({data}) => {
        const items = data.items.map((item: any) => ({
          id: item.sellPhaseTemplateId,
          name: item.title,
        }));
        setItems(items);
        toggleLoadingTemplate();
      })
      .catch(() => toggleLoadingTemplate());
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center justify-between text-heading-2 font-semibold text-gray-800">
          <Text>Push to Sell-off product</Text>
          <Button
            variant={"light"}
            size="sm"
            className="flex items-center"
            onClick={toggle}
          >
            <span>
              <Text>global.buttons.close</Text>
            </span>
            <Icon icon="close" className="ml-2" />
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          {loadingInventory ? (
            <div className="space-y-4">
              <Skeleton.TextArea />
              <Skeleton.Input />
            </div>
          ) : (
            <>
              <div className="space-y-2 rounded border border-dashed border-gray-600 p-4 ">
                <h3 className="!mb-4">Inventory</h3>
                {inventoryConfigExisted ? (
                  <>
                    {inventories?.map(
                      (
                        inventory: SalesService.GetPimProductAvailableInInventoryResultDto,
                      ) => {
                        const isAvailable =
                          !!inventory.quantities?.iv?.availabletoreserve;
                        return (
                          <>
                            <h6 className="text-secondary w-1/3 text-base font-normal">
                              Stock{" "}
                              <span className="uppercase">
                                #{inventory?.dimensions?.locationid}
                              </span>
                            </h6>
                            {isAvailable ? (
                              <p className="flex-1 text-base font-normal text-gray-900">
                                x
                                {inventory.quantities?.iv?.availabletoreserve ??
                                  0}
                              </p>
                            ) : (
                              <p className="flex-1 text-base font-normal text-warning">
                                <Text>
                                  products.details.inventoryNotAvailable
                                </Text>
                              </p>
                            )}
                          </>
                        );
                      },
                    )}
                  </>
                ) : (
                  <p className="text-danger">
                    <Text>errors.SS14300</Text>
                  </p>
                )}
              </div>

              <p className="text-gray-800">
                <Text>Quantity</Text>
              </p>
              <div className="flex items-center gap-2">
                <Button
                  light
                  textColor="primary"
                  onClick={() => {
                    setQuantity(p => +p - 1);
                  }}
                  disabled={quantity === 1}
                >
                  <Icon icon="minus" />
                </Button>
                <InputGroup
                  value={quantity || 0}
                  setValue={e => {
                    setQuantity(+e);
                  }}
                  center
                  type="number"
                />
                <Button
                  light
                  textColor="primary"
                  onClick={() => {
                    setQuantity(p => +p + 1);
                  }}
                >
                  <Icon icon="plus" />
                </Button>
              </div>
              <p className="flex flex-1 items-center !text-sm font-normal">
                <Icon icon="info-circle" className="mr-1" />{" "}
                <Text>Number of product that you want to push to sell-off</Text>
              </p>
              <p className="text-gray-800">
                <Text>Sales Template (Optional)</Text>
              </p>
              {loadingTemplate ? (
                <Skeleton.Input />
              ) : (
                <Select
                  value={selectedPhaseId}
                  items={items}
                  setValue={val => setSelectedPhaseId(val)}
                />
              )}
            </>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between">
          <Button size="sm" onClick={toggle} variant="white">
            <Icon className="mr-2" icon={"times"} />
            <Text>global.buttons.close</Text>
          </Button>
          <Button
            size="sm"
            onClick={onSubmit}
            loading={loading}
            disabled={loadingInventory}
          >
            <Text>push</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}
