import {createClassNames} from "./classname";
import {PanelMenu} from "components/panelmenu/PanelMenu";
import {useMenu, useSelector} from "hooks";
import {Icon, LazyImage} from "components";
import Logo from "assets/logos/logo.svg";

const Sidebar = ({isActive, setIsActive}) => {
  const profile = useSelector(s => s.profile);

  const {sidebar, logo, menuHeader, drawer} = createClassNames(isActive);
  const {menu} = useMenu();
  return (
    <div className={sidebar} id="sidbar-b2b">
      <div className={menuHeader}>
        {isActive && profile?.companyAvatarThumbnailUrl ? (
          <LazyImage
            isDynamic
            className={`${logo} max-h-[40px]`}
            src={true ? profile?.companyAvatarThumbnailUrl : Logo}
            alt="moebelhof"
          />
        ) : (
          isActive && (
            <div
              className={`text-white ${
                profile?.companyAvatarThumbnailUrl ? "ml-1" : "ml-4"
              }`}
            >
              {profile?.companyName}
            </div>
          )
        )}
        <button
          className={drawer}
          onClick={() => {
            setIsActive(!isActive);
          }}
        >
          <Icon icon={"chevrons-left"} size="sm" />
        </button>
      </div>
      <aside className="overflow-auto text-gray-200">
        <PanelMenu model={menu} isActive={isActive} setIsActive={setIsActive} />
      </aside>
      <div className="absolute bottom-0 w-full p-4 text-center text-sm text-gray-600">
        {`Version ${process.env.REACT_APP_BUILD_NUMBER}`}
      </div>
    </div>
  );
};
export default Sidebar;
