const PIM = {
  // -------------------- PIM Product ----------------
  GET_PIM_PRODUCT_GROUPS: "productservice/api/pimproductgroups",
  GET_PIM_PRODUCT_GROUPS_DETAILS: id =>
    `productservice/api/pimproductgroups/${id}`,
  GET_BRAND_DETAILS: id => `productservice/api/brands/${id}`,
  POST_CLONE_PRODUCT: id => `productservice/api/pimproducts/${id}/clone`,
  POST_SEARCH_LIST_PRODUCTS: "productservice/api/pimproducts/search",
  CREATE_PIM_PRODUCT_URL: "productservice/api/pimproducts?",
  EDIT_PIM_PRODUCT_URL: id => `productservice/api/pimproducts/${id}`,
  PATCH_PIM_PRODUCT_URL: id => `productservice/api/pimproducts/${id}`,
  UPSERT_PIM_PRODUCT_ATTRIBUTE_URL: `productservice/api/pimproductattributes`,
  UPSERT_PIM_PRODUCT_DEFAULT_ATTRIBUTE_URL: `productservice//api/pimproductattributes/default-values`,
  GET_PIM_PRODUCT_FAMILIES_URL: "productservice/api/pimproductfamilies?",
  GET_PIM_PRODUCT_BY_ID_URL: id => `productservice/api/pimproducts/${id}`,
  GET_PIM_PRODUCT_LIST: "productservice/api/pimproducts",
  GET_PIM_PRODUCT_ATTRIBUTES: id =>
    `productservice/api/pimproductattributes/${id}`,
  DELETE_PRODUCT: productId =>
    `productservice/api/selloffproducts/${productId}`,
  UNPUBLISH_PRODUCT: pimProductId =>
    `productservice/api/pimproducts/${pimProductId}/unpublishfromchannel`,
  ADD_BRAND_URL: "productservice/api/brands",

  ADD_CATEGORY_TO_PIM: id =>
    `productservice/api/pimproducts/${id}/add-category`,
  REMOVE_CATEGORY_FROM_PIM: id =>
    `productservice/api/pimproducts/${id}/remove-category`,
  UPDATE_PIM_PRODUCT_PRICE_TEMPLATE_URL: id =>
    `productservice/api/pimproducts/${id}/set-price-template`,
  FILL_FORM_DEFAULT_VALUES:
    "productservice/api/pimproductattributes/fill-from-default-values",

  // --------------------EDIT PIM Product Channel --------------

  GET_CHANNEL_IS_PUBLISHED: (id, ChannelCode) =>
    `salesservice/api/salechannelmessage/status?aggregateId=${id}&channelode=${ChannelCode}`,

  GET_CHANNEL_LIST_BY_PIM_PRODUCT_ID_URL: id =>
    `productservice/api/channelproducts?pimProductId=${id}`,
  PUBLISH_PIM_PRODUCT_TO_CHANNEL_URL: id =>
    `productservice/api/pimproducts/${id}/publishtochannel`,
  GET_ALL_CHANNEL_URL: id => `productservice/api/channels?pimProductId=${id}`,
  GET_A_CHANNEL_PRODUCT_URL: (id, channelCode) =>
    `productservice/api/channelproducts/detail?pimProductId=${id}&channelCode=${channelCode}`,
  GET_PLACES: id => `productservice/api/pimproducts/places?pimProductId=${id}`,
  ADD_PLACES: id => `productservice/api/pimproducts/${id}/location`,
  REMOVE_PLACES: (id, locationId) =>
    `productservice/api/pimproducts/${id}/location/${locationId}`,
  // -------------------- PIM Product Media --------------

  GET_PIM_PRODUCT_MEDIA_LIST_URL: (pimProductId, pageNumber, pageSize) =>
    `productservice/api/pimproductmedias?pimProductId=${pimProductId}&pageNumber=${pageNumber}&pageSize=${pageSize}`,
  CREATE_PIM_PRODUCT_MEDIA_URL: "productservice/api/pimproductmedias",
  GET_PIM_PRODUCT_MEDIA_URL: mediaId =>
    `productservice/api/pimproductmedias/${mediaId}`,
  UPDATE_PIM_PRODUCT_MEDIA_URL: mediaId =>
    `productservice/api/pimproductmedias/${mediaId}`,
  UPDATE_PIM_PRODUCT_AVATAR_URL: id =>
    `productservice/api/pimproducts/${id}/avatar`,
  // -------------------- PIM Product Family --------------
  DELETE_PRODUCT_FAMILY: id => `productservice/api/pimproductfamilies/${id}`,
  GET_PRODUCT_FAMILIES_ALL_ATTRIBUTE: id =>
    `productservice/api/pimproductfamilies/${id}/all-attribute-groups`,

  CREATE_GET_PUT_DELETE_PIM_PRODUCT_FAMILY_URL: `productservice/api/pimproductfamilies`,
  PUT_DELETE_PIM_PRODUCT_FAMILY_URL: id =>
    `productservice/api/pimproductfamilies/${id}`,
  GET_GROUP_ATTRIBUTE: id =>
    `productservice/api/pimproductfamilies/${id}/group-attributes`,
  GET_ALL_ATTRIBUTE: id =>
    `productservice/api/pimproductfamilies/${id}/all-attribute-groups?pageNumber=1&pageSize=100`,
  GET_ATTRIBUTE_TEMPLATES: id =>
    `productservice/api/attributegrouptemplates/attribute-templates?attributeTemplateGroupId=${id}`,
  GET_AVAILABLE_ATTRIBUTE: id =>
    `/productservice/api/pimproductfamilies/${id}/available-attribute-groups`,
  GET_DETAIL_PIM_PRODUCT_FAMILY_URL:
    "productservice/api/pimproductfamilies/detail",
  ADD_ATTRIBUTE_TO_PIM_PRODUCT_FAMILY_URL: id =>
    `productservice/api/pimproductfamilies/${id}/add-attribute-group`,
  DELETE_ATTRIBUTE_FROM_PIM_PRODUCT_FAMILY_URL: id =>
    `productservice/api/pimproductfamilies/${id}/remove-attribute-group`,

  // --------------- PIM product preview ---------------
  CREATE_PREVIEW: pimProductId =>
    `productservice/api/pimproducts/${pimProductId}/channel-product-preview`,
  GET_PRODUCT_PREVIEW: (pimProductId, channelCode) =>
    `productservice/api/pimproducts/preview?pimProductId=${pimProductId}&channelCode=${channelCode}`,

  // ----------------- import PIM product ---------
  CHECK_CONFIGURATION_PROVIDER: (providerName, configurationVersion, lang) =>
    `productservice/api/b2bconfigurationproviders/${providerName}/${configurationVersion}/${lang}/import-status`,
  SEARCH_PIM_PRODUCT: `productservice/api/providerproductimports/basic-info`,
  IMPORT_CONFIGURATION_PROVIDER: `productservice/api/b2bconfigurationproviders`,
  GET_B2B_CONFIG_PROVIDERS: `productservice/api/b2bconfigurationproviders`,
  GET_B2B_PRODUCT_DETAIL: id =>
    `productservice/api/providerproductimports/${id}/b2b-detail`,
  IMPORT_PRODUCT: `productservice/api/pimproducts/import-from-provider-product-import`,
  GET_CONFIGURATION_PROVIDERS_ITEM: name =>
    `productservice/api/configurationproviders/${name}`,

  GET_MEASUREMENT_LIST_BY_CODE: code =>
    `productservice/api/measurementfamilies/${code}/bycode`,
  ADD_SHIPPING_METHOD_TO_PIM: id =>
    `productservice/api/pimproducts/${id}/add-shipping-method`,
  DELETE_SHIPPING_METHOD_FROM_PIM: id =>
    `productservice/api/pimproducts/${id}/remove-shipping-method`,
  SALES_PRICE_FULL_PERMISSION: `/priceengineservice/api/sale-price-calculation/simulate-sale-price-detail`,
  SALES_PRICE_NOT_FULL_PERMISSION: `/priceengineservice/api/sale-price-calculation/simulate-sale-price-simple`,
  PURCHASE_PRICE_SIMULATION: `/priceengineservice/api/purchase-price-calculation/simulate-purchase-price`,
  PURCHASE_PRICE_CALCULATION: `/priceengineservice/api/purchase-price-calculation/calculate-purchase-price`,
  IMPORT_FROM_EXCEL: `productservice/api/pimproducts/importfromexcel`,
  ADD_BUNDLE_ITEM: pimProductId =>
    `productservice/api/pimproducts/${pimProductId}/bundle-item`,
  DELETE_BUNDLE_ITEM: (pimProductId, bundleItemId) =>
    `productservice/api/pimproducts/${pimProductId}/bundle-item/${bundleItemId}`,
  UPDATE_BUNDLE_ITEM: (pimProductId, bundleItemId) =>
    `productservice/api/pimproducts/${pimProductId}/bundle-item/${bundleItemId}`,
  CALC_PIM_SALE_PRICE:
    "/priceengineservice/api/sale-price-calculation/calculate-pim-sale-price",
  // ----------------- PIM product availability status ---------
  ADD_PIM_AVAILABILITY_STATUS: pimProductId =>
    `/productservice/api/pimproducts/${pimProductId}/availability-status`,
  UPDATE_PIM_AVAILABILITY_STATUS: (pimProductId, statusId) =>
    `/productservice/api/pimproducts/${pimProductId}/availability-status/${statusId}`,
  GET_PIM_AVAILABILITY_STATUS: id =>
    `salesservice/api/salestoolspimproduct/${id}/availability-status`,
  // -------------------- PIM Product Sales person commission --------------
  ADD_SALES_PERSON_COMMISSION: id =>
    `productservice/api/pimproducTS/${id}/add-sales-person-commission`,
  // -------------------- PIM Product check list --------------
  PIM_CHECK_LIST_URL: "productservice/api/pimproductchecklists",
  PIM_CHECK_LIST_DETAILS_URL: id =>
    `productservice/api/pimproductchecklists/${id}`,
};

export default PIM;
