import {createElement, useState, FunctionComponent} from "react";
import {Breadcrumb, Text} from "components";
import {calculationLevel} from "constant";
import {Tenant, Brand, Product, Program, Supplier, Channel} from "./levels";

export default function CalculationLevel() {
  const levelEnum = [
    {value: 1, component: Tenant},
    {value: 8, component: Channel},
    {value: 16, component: Supplier},
    {value: 21, component: Brand},
    {value: 25, component: Program},
    {value: 28, component: Product},
  ];
  const [level, setLevel] = useState<number>(1);
  return (
    <div className="space-y-8">
      <Breadcrumb />
      <div className="flex items-center gap-4">
        {calculationLevel?.map(
          (lvl: any, index) =>
            lvl.value !== 0 && (
              <span
                key={index}
                className={`cursor-pointer rounded-full p-4 text-heading-6 font-semibold ${
                  level === lvl.value
                    ? "bg-primary text-white"
                    : "bg-white text-gray-500"
                }`}
                onClick={() => setLevel(lvl.value)}
              >
                <Text>{lvl.label}</Text>
              </span>
            ),
        )}
      </div>
      <div>
        {createElement(
          levelEnum.find(lvl => lvl.value === level)
            ?.component as FunctionComponent<any>,
        )}
      </div>
    </div>
  );
}
