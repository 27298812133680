import {NotificationManager} from "common/notifications";
import {
  Button,
  Icon,
  LoadingSvg,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {useToggleState} from "hooks";
import {Fragment, useContext, useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import {convertDate} from "utils";
import {PimContext} from "..";
import ChannelDetails from "../drawers/channels-details";
import Preview from "../drawers/preview";
import PublishToChannel from "../drawers/publish-to-channel";

const ChannelsTab = () => {
  const {productData, channels} = useContext(PimContext);
  const [isOpen, toggle] = useToggleState();
  const [loading, setLoading] = useToggleState();
  const [loadingChannel, toggleLoadingChannel] = useToggleState();
  const [loadingChannelStatus, setLoadingChannelStatus] = useState<string[]>(
    [],
  );
  const [publishLoading, setPublishLoading] = useToggleState();
  const [publishedList, setPublishedList] = useState<any>([]);
  const [activeChannelCode, setActiveChannelCode] = useState("");
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [channelDetails, setChannelDetails] = useState<any>({});
  const [currentPublishedChannel, setCurrentPublished] = useState<string[]>([]);

  const clearActiveChannelCode = () => {
    setActiveChannelCode("");
  };

  const getChannelDetails = (channel: any) => {
    setChannelDetails(channel);
  };

  const getPublishedPimChannels = () => {
    const clone: any = [];
    setLoading();
    const urls = channels.map((channel: any) =>
      ServiceApi.get(
        URLS.GET_CHANNEL_IS_PUBLISHED(productData?.id, channel.code),
      ),
    );
    Promise.all(urls)
      .then(([...responses]) => {
        responses.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setPublishedList([...clone]);
        setLoading();
      });
  };
  const removeUnpublishChannel = async (channel: any) => {
    setPublishedList((p: any) =>
      p?.filter((info: any) => info.channelCode !== channel?.code),
    );
  };

  useEffect(() => {
    if (!!channels.length) {
      getPublishedPimChannels();
    }
  }, [channels]);

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    const url = URLS.UNPUBLISH_PRODUCT(productData?.id);
    const body = {channelCode: channel.code};
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title",
        );
        setPublishLoading();
        removeUnpublishChannel(channel);
      })
      .catch(() => setPublishLoading());
  };

  const publishHandler = (channel: any) => {
    const code = channel.code;
    const isErp = code === "erpchannel";
    if (isErp) return setActiveChannelCode(code);
    publishToChannel({
      channelCode: code,
      legalEntityCodes: [channel?.legalEntity?.code],
    });
  };

  const createIntervalForChannels = (code: string) => {
    let channelInterval: any;
    setCurrentPublished(p => [...p, code]);
    channelInterval = setInterval(() => {
      ServiceApi.get(URLS.GET_CHANNEL_IS_PUBLISHED(productData?.id, code)).then(
        ({data, status}) => {
          if (status === 200) {
            setCurrentPublished(p => p?.filter((ls: any) => ls !== code));
            setPublishedList((p: any) => [...p, data]);
            clearInterval(channelInterval);
          }
        },
      );
    }, 3000);
  };

  const publishToChannel = (body: {
    channelCode: string;
    legalEntityCodes: string[];
  }) => {
    const url = URLS.PUBLISH_PIM_PRODUCT_TO_CHANNEL_URL(productData?.id);
    setLoadingChannelStatus(p => [...p, body?.channelCode]);
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title",
        );
        clearActiveChannelCode();
        createIntervalForChannels(body.channelCode);
      })
      .finally(() => {
        setLoadingChannelStatus(p =>
          p?.filter((ls: any) => ls !== body.channelCode),
        );
      });
  };

  return (
    <div className="space-y-4">
      {channels?.length !== 0 && (
        <div className="flex justify-between">
          <Button variant={"white"} textColor="primary" onClick={toggle}>
            <Icon icon="eye" className="mr-2" />
            <Text>
              productManagement.products.Details.previewCurrentVersion
            </Text>{" "}
            ({productData?.version})
          </Button>
        </div>
      )}
      {loadingChannel ? (
        [1, 2, 3, 4].map(() => <Skeleton.List />)
      ) : (
        <Table>
          {channels?.length !== 0 ? (
            channels?.map((channel: any) => {
              return (
                <>
                  {channel.code !== "CH000D" && (
                    <tr
                      className="text-heading-6 font-semibold"
                      key={channel?.code}
                    >
                      <td className="space-y-2">
                        <span>{channel?.title}</span>
                        <p className="text-body-2 font-medium">
                          <Text>productManagement.products.Details.code</Text> :{" "}
                          {channel.code}
                        </p>
                        <p className="text-body-2 font-medium">
                          <Text>
                            productManagement.products.Details.legalEntity
                          </Text>{" "}
                          : {channel?.legalEntity?.code}
                        </p>
                      </td>
                      <td>
                        {loading ||
                        currentPublishedChannel.includes(channel?.code) ? (
                          <LoadingSvg size="sm" />
                        ) : (
                          <>
                            {publishedList.filter(
                              (pub: any) => pub.channelCode === channel?.code,
                            ) && (
                              <p className="text-body-base font-normal">
                                {publishedList.find(
                                  (info: any) =>
                                    info.channelCode === channel?.code,
                                ) && (
                                  <>
                                    <Status.PimChannelStatus
                                      id={
                                        publishedList.find(
                                          (info: any) =>
                                            info.channelCode === channel?.code,
                                        )?.status
                                      }
                                    />
                                    <span className="ml-2">
                                      <Text>
                                        productManagement.products.Details.at
                                      </Text>
                                    </span>
                                  </>
                                )}

                                <span className="ml-2">
                                  {convertDate(
                                    publishedList.find(
                                      (info: any) =>
                                        channel?.code === info.channelCode,
                                    )?.createdAt,
                                  )}
                                </span>
                              </p>
                            )}
                          </>
                        )}
                      </td>
                      <WithPermission
                        permissions={["PS_PublishPimProductToChannel"]}
                      >
                        <Fragment>
                          <td className="flex flex-col gap-4 xl:table-cell">
                            {publishedList.find(
                              (info: any) => channel?.code === info.channelCode,
                            ) ? (
                              <>
                                <Button
                                  variant={"light"}
                                  size="sm"
                                  className="mr-2"
                                  onClick={() => {
                                    toggleDetails();
                                    getChannelDetails(
                                      publishedList.find(
                                        (pub: any) =>
                                          pub.channelCode === channel?.code,
                                      ),
                                    );
                                  }}
                                  // disabled={publishLoading}
                                >
                                  <Text>
                                    productManagement.masterData.productCategory.details
                                  </Text>
                                </Button>
                                <Button
                                  light
                                  variant="danger"
                                  size="sm"
                                  onClick={() => unPublishHandler(channel)}
                                  disabled={
                                    loading ||
                                    currentPublishedChannel === channel?.code
                                  }
                                  className="mr-2"
                                >
                                  <Text>
                                    productManagement.products.Details.unPublish
                                  </Text>
                                </Button>
                                <Button
                                  light
                                  size="sm"
                                  onClick={() => {
                                    publishHandler(channel);
                                  }}
                                  loading={loadingChannelStatus?.includes(
                                    channel?.code,
                                  )}
                                >
                                  <Text>
                                    productManagement.products.Details.rePublish
                                  </Text>
                                </Button>
                              </>
                            ) : (
                              <Button
                                light
                                size="sm"
                                onClick={() => {
                                  publishHandler(channel);
                                }}
                                loading={loadingChannelStatus?.includes(
                                  channel?.code,
                                )}
                              >
                                <Text>
                                  productManagement.products.Details.publishCurrentVersion
                                </Text>
                              </Button>
                            )}
                          </td>
                          <PublishToChannel
                            isOpen={activeChannelCode === channel.code}
                            toggle={clearActiveChannelCode}
                            onSubmit={({legalEntities}) =>
                              publishToChannel({
                                legalEntityCodes: legalEntities,
                                channelCode: channel.code,
                              })
                            }
                            loading={publishLoading}
                          />
                        </Fragment>
                      </WithPermission>
                    </tr>
                  )}
                </>
              );
            })
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
              <Text>
                productManagement.products.Details.thereIsNoChannelEnabledForYourCompany
              </Text>
              !
            </div>
          )}
        </Table>
      )}

      <Preview isOpen={isOpen} toggle={toggle} channels={channels} />
      <ChannelDetails
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        details={channelDetails}
      />
    </div>
  );
};

export default ChannelsTab;
