import {Button, Drawer, Icon, InputGroup, Text} from "components";
import {useTranslate} from "hooks";
import {useSearchParams} from "react-router-dom";
import Select from "react-select";
import {config} from "constant";
import {useEffect, useState} from "react";
import {label} from "yet-another-react-lightbox";
type drawerProps = {
  isOpen: boolean;
  toggle: () => void;
  resetFilter: () => void;
  onChange: (key: any) => any;
  data: any;
  params: any;
  setParams: (key: any) => void;
  setKeyword: (key: any) => void;
  keyword: any;
};

const FilterDrawer = ({
  isOpen,
  data,
  params,
  toggle,
  onChange,
  resetFilter,
  setParams,
  setKeyword,
  keyword,
}: drawerProps) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const translate = useTranslate();

  const [options, setOptions] = useState<any>({
    productFamilyItems: [],
    category: [],
    brand: [],
    supplier: [],
    program: [],
  });

  useEffect(() => {
    if (isOpen) {
      createProductFamilyItems();
      ["category", "brand", "supplier", "program"].map((key: string) =>
        createItems(key),
      );
    }
  }, [isOpen]);

  const createItems = (key: string) => {
    const clone = data?.[key]?.map((item: any) => ({
      value: item.value,
      label: item.title + "(" + item.count + ")",
    }));
    setOptions((p: any) => ({...p, [key]: clone}));
    clone?.unshift({value: null, label: translate("global.all")});

    return clone;
  };

  const createProductFamilyItems = () => {
    const clone = data?.pimProductFamily?.map((item: any) => ({
      value: item.value,
      label: item.title + "(" + item.count + ")",
    }));
    clone?.unshift({value: null, label: translate("global.all")});
    setOptions((p: any) => ({...p, productFamilyItems: clone}));
    return clone;
  };

  const onChangeHandler = (key: string, val: any) => {
    setSearchParams({
      pageNumber: "1",
      pageSize: params.pageSize,
    });
    setParams((prev: any) => {
      return {...prev, filter: true, pageNumber: 1, [key]: val};
    });
  };

  const submit = (e: any) => {
    e.preventDefault();
    setSearchParams({
      pageNumber: "1",
      pageSize: params.pageSize,
    });
    setParams((prev: any) => {
      return {...prev, pageNumber: 1, filter: true, searchFields: keyword};
    });
  };

  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu className="pr-0">
        <Drawer.Header className="pr-4 pt-6">
          <div className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold">
              <Text>productManagement.products.allProducts.filters</Text>
            </span>
            <div className="flex gap-x-2">
              {params.filter && (
                <Button
                  size="sm"
                  variant={"danger"}
                  light
                  onClick={() => resetFilter()}
                >
                  <Icon icon="times-circle" className="mr-1" />
                  <Text>
                    productManagement.products.allProducts.resetFilters
                  </Text>
                </Button>
              )}
              <Button size="sm" variant={"light"} onClick={toggle}>
                <Icon icon="times" className="mr-1" />
                <Text>productManagement.products.allProducts.close</Text>
              </Button>
            </div>
          </div>
        </Drawer.Header>
        <Drawer.Body className="space-y-4 px-6 py-8">
          <div className="flex-center flex gap-2">
            <form onSubmit={submit} className="w-full">
              <InputGroup
                label="productManagement.products.importProduct.keyword"
                value={keyword}
                placeholder="global.placeholders.search"
                setValue={value => setKeyword(value)}
              />
            </form>
            <div
              className="mt-8 flex h-[46px] w-[46px] cursor-pointer items-center justify-center rounded-md bg-gray-100 p-3"
              onClick={() => {
                setSearchParams({
                  pageNumber: "1",
                  pageSize: params.pageSize,
                });
                setParams((prev: any) => {
                  return {...prev, pageNumber: 1, searchFields: keyword};
                });
              }}
            >
              <Icon
                className="h-5 w-5 !leading-6 text-primary"
                icon={"search"}
              />
            </div>
          </div>
          <label
            data-lang-map={"productManagement.products.Details.productFamily"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.Details.productFamily"}</Text>
          </label>
          <Select
            defaultValue={{id: null, label: translate("global.all")}}
            options={options?.productFamilyItems}
            value={
              options?.productFamilyItems?.filter(
                (item: any) => item?.value === params?.pimProductFamilyId,
              )?.[0]
            }
            onChange={(e: any) => {
              onChangeHandler("pimProductFamilyId", e?.value || null);
            }}
            styles={config.styleSelectReact}
          />
          <label
            data-lang-map={
              "productManagement.products.allProducts.productCategory"
            }
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>
              {"productManagement.products.allProducts.productCategory"}
            </Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.category?.filter(
                (item: any) => item?.value === params?.categoryId,
              )?.[0]
            }
            options={options?.category}
            onChange={(e: any) => {
              onChangeHandler("categoryId", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.brand"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.brand"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.brand?.filter(
                (item: any) => item?.value === params?.brandId,
              )?.[0]
            }
            options={options?.brand}
            onChange={(e: any) => {
              onChangeHandler("brandId", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.suppliers"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.suppliers"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            value={
              options?.supplier?.filter(
                (item: any) => item?.value === params?.supplierId,
              )?.[0]
            }
            options={options?.supplier}
            onChange={(e: any) => {
              onChangeHandler("supplierId", e?.value || null);
            }}
          />
          <label
            data-lang-map={"productManagement.products.allProducts.program"}
            className="h6 block w-full truncate font-[400] text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
          >
            <Text>{"productManagement.products.allProducts.program"}</Text>
          </label>
          <Select
            styles={config.styleSelectReact}
            defaultValue={{id: null, label: translate("global.all")}}
            options={options?.program}
            value={
              options?.program?.filter(
                (item: any) => item?.value === params?.programId,
              )?.[0]
            }
            onChange={(e: any) => {
              onChangeHandler("programId", e?.value || null);
            }}
          />
          {/* <Select
            label="productManagement.products.allProducts.store"
            placeholder="Select"
            value={filterData.storeCode}
            items={createItems("store")}
            setValue={onChange("storeCode")}
          /> */}

          <div className="grid grid-cols-2 gap-2 gap-y-4">
            <span className="col-span-2 text-sm text-gray-800">
              <Text>productManagement.products.allProducts.priceRange</Text>
            </span>
            <InputGroup
              value={params?.priceFrom}
              setValue={onChange("priceFrom")}
              type="price"
            />
            <InputGroup
              value={params?.priceTo}
              setValue={onChange("priceTo")}
              type="price"
            />
          </div>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
};

export default FilterDrawer;
