export const salesChannels = {
  addSalesChannel: "Add Sales Channel",
  title: "Title",
  code: "Code",
  iln: "ILN (International Location Number)",
  legalEntity: "LegalEntity",
  description: "Description",
  activeBusinessChannels: "Active business channels",
  NoOnlineShop: "NOP online shop",
  general: "General",
  basicInfo: "Basic Info",
};
