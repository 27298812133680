import { Text, Wrapper } from "components";
import { flagCountry } from "constant";
import { Fragment, useContext } from "react";
import { AttributesTypes } from "utils";
import { AttributeContext } from "../index";

export default function Review() {
  const { data } = useContext<any>(AttributeContext);

  return (
    <Fragment>
      <h2 className="text-heading-2 text-gray-800">
        <Text>productManagement.masterData.attributes.review</Text>
      </h2>
      <Wrapper.Section className="grid grid-cols-3 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="text-gray-600 col-span-full">
          <Text>productManagement.masterData.attributes.attributeType</Text>
        </h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.attributeType</Text>
            </span>
          </div>
          <div>
            <h6 className="text-heading-6 text-gray-800">
              {AttributesTypes(data?.type)}
            </h6>
          </div>
        </div>
      </Wrapper.Section>
      <Wrapper.Section className="grid grid-cols-3 gap-4 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium">
        <h3 className="text-gray-600 col-span-full">
          <Text>productManagement.masterData.attributes.infoAndProperties</Text>
        </h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.attributeName</Text>
            </span>
          </div>
          <div className="space-y-4">
            {data?.translations.map((item: any) => {
              return (
                <div className="flex">
                  <div>
                    <img
                      className="block mr-4"
                      src={flagCountry[item.language]}
                      alt=""
                    />
                  </div>
                  <div>
                    <h6 className="text-heading-6 text-gray-800">
                      {item.labelTitle}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
          {/* <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.attributeCode</Text>
            </span>
          </div>
          <div>
            <h6 className="text-heading-6 text-gray-800">{data?.code}</h6>
          </div> */}
          <div>
            <span className="text-body-base text-gray-800">
              <Text>
                productManagement.masterData.attributes.shortDescription
              </Text>
            </span>
          </div>
          <div className="space-y-4">
            {data?.translations.map((item: any) => {
              return (
                <div className="flex">
                  <div>
                    <img
                      className="block mr-4"
                      src={flagCountry[item.language]}
                      alt=""
                    />
                  </div>
                  <div>
                    <h6 className="text-heading-6 text-gray-800">
                      {item.description}
                    </h6>
                  </div>
                </div>
              );
            })}
          </div>
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.isRequired</Text>
            </span>
          </div>
          <div>
            {" "}
            <h6 className="text-heading-6 text-gray-800">
              {data?.isRequired === false ? "No" : "Yes"}
            </h6>
          </div>
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.perLanguage</Text>
            </span>
          </div>
          <div>
            {" "}
            <h6 className="text-heading-6 text-gray-800">
              {data?.perLanguage === false ? "No" : "Yes"}
            </h6>
          </div>
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.perChannel</Text>
            </span>
          </div>
          <div>
            {" "}
            <h6 className="text-heading-6 text-gray-800">
              {data?.perChannel === false ? "No" : "Yes"}
            </h6>
          </div>
          <div>
            <span className="text-body-base text-gray-800">
              <Text>productManagement.masterData.attributes.order</Text>
            </span>
          </div>
          <div>
            {" "}
            <h6 className="text-heading-6 text-gray-800">
              {data?.sortOrder}
            </h6>
          </div>
        </div>
      </Wrapper.Section>
    </Fragment>
  );
}
