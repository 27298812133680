import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  DeleteModal,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {ServiceApi, URLS} from "services";
import AddModal from "./add-modal";
import DetailsModal from "./details-modal";

type ItemsType = {
  id: string;
  label: string;
  description: string;
};

type ListType = {
  items?: ItemsType[];
  pageNumber?: number;
  pageSize?: number;
  totalItems?: number;
  totalPages?: number;
};

function SalesChecklist() {
  const controller = new AbortController();
  const [data, setData] = useState<ListType>();
  const [loadingList, setLoadingList] = useState(true);
  const [selected, setSelected] = useState<ItemsType | null>();
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const [isOpen, toggle] = useToggleState(false);
  const [isOpenUpdate, toggleUpdate] = useToggleState(false);
  const [isOpenDeleteModal, toggleDeleteModal] = useToggleState(false);
  const [loadingButton, setLoadingButton] = useState(false);

  const updatePermission = usePermission("SC_UpdateCheckListItem");

  const handleSetParams = (key: any) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = URLS.GET_CHECK_LIST_URL;
    const config = {params};
    setLoadingList(true);
    ServiceApi.get(url, {...config, signal: controller.signal}).then(res => {
      if (res?.data) {
        const {data} = res;
        setData(data);
        setLoadingList(false);
      }
    });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]); // eslint-disable-line react-hooks/exhaustive-deps

  const submitDelete = (id: any) => {
    setLoadingButton(true);
    const url = URLS.DELETE_CHECK_LIST_URL(id);
    ServiceApi.delete(url)
      .then(({data}) => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        getData();
        setLoadingButton(false);
        toggleDeleteModal();
      })
      .catch(() => setLoadingButton(false));
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <h3 className="text-heading-3">
            <Breadcrumb />
          </h3>
        </div>
        <WithPermission permissions={["SC_CreateCheckListItem"]}>
          {loadingList ? (
            <Skeleton.Button />
          ) : (
            <Button onClick={() => toggle()}>
              <Icon className="mr-2" icon={"plus"} />
              <Text>
                salesManagment.sellOffSales.salesChecklist.addCheckList
              </Text>
            </Button>
          )}
        </WithPermission>
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        disabled={loadingList}
        totalItems={data?.totalItems}
        onReload={getData}
      />
      {loadingList ? (
        <>
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </>
      ) : (
        <>
          {data?.items?.length !== 0 ? (
            <>
              <Table>
                {data?.items?.map(item => {
                  return (
                    <tr key={item.id}>
                      <td className="text-heading-6 text-gray-800">
                        <button
                          type="button"
                          onClick={() => {
                            if (!updatePermission) return;
                            setSelected(item);
                            toggleUpdate();
                          }}
                          className="flex-center inline-flex w-fit gap-2 text-wrap leading-6"
                        >
                          <h6>{item.label}</h6>
                        </button>
                      </td>
                      <td className="text-wrap text-left text-body-base leading-6 text-gray-800">
                        <span className="ml-1">{item.description}</span>
                      </td>
                      <td>
                        <div className="flex flex-row-reverse">
                          <div>
                            <WithPermission
                              permissions={["SC_DeleteCheckListItem"]}
                            >
                              <Button
                                size="sm"
                                variant="danger"
                                light
                                onClick={() => {
                                  setSelected(item);
                                  toggleDeleteModal();
                                }}
                              >
                                <Icon icon={"trash"} />
                              </Button>
                            </WithPermission>
                          </div>
                          <div>
                            <WithPermission
                              permissions={["SC_GetCheckListItem"]}
                            >
                              <Button
                                type="button"
                                className="mr-2"
                                onClick={() => {
                                  setSelected(item);
                                  toggleUpdate();
                                }}
                                variant="light"
                                size="sm"
                              >
                                <Text>global.buttons.details</Text>
                              </Button>
                            </WithPermission>
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </Table>
              <Pagination
                totalItems={data?.totalItems}
                totalPages={data?.totalPages || 1}
                pageNumber={
                  params?.get("pageNumber")
                    ? parseInt(params?.get("pageNumber") as string)
                    : 1
                }
                pageSize={
                  params?.get("pageSize")
                    ? parseInt(params?.get("pageSize") as string)
                    : config.pageSize
                }
                setActivePage={handleSetParams("pageNumber")}
              />
            </>
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
            </div>
          )}
        </>
      )}
      <AddModal isOpen={isOpen} toggle={toggle} getData={getData} />
      <DetailsModal
        isOpen={isOpenUpdate}
        toggle={toggleUpdate}
        getData={getData}
        selected={selected?.id}
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={toggleDeleteModal}
        selected={{name: selected?.label, id: selected?.id}}
        loading={loadingButton}
        onConfirm={submitDelete}
      />
    </div>
  );
}
export default withPermission(SalesChecklist, ["SC_GetCheckListItem"]);
