import {
  AddressViewer,
  Breadcrumb,
  Button,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import {config} from "constant";
import {withPermission} from "hoc";
import {usePermission} from "hooks";
import {useEffect, useState} from "react";
import {Link, useSearchParams} from "react-router-dom";
import {ServiceApi} from "services";
import STORE from "services/urls/store";

const StoreList = () => {
  const controller = new AbortController();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState({});
  const [searchParams] = useSearchParams();

  const originalFilterData = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const detailsPermission = usePermission("AS_GetStore");

  const handleSetParams = key => {
    return value => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };

  const getData = () => {
    let params = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    const url = STORE.ADD_STORE_URL;
    const config = {params};
    ServiceApi.get(url, {...config, signal: controller.signal})
      .then(({data}) => {
        setData(data);
      })
      .catch(() => {
        setData({});
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]);
  return (
    <div className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <Breadcrumb />
        </div>
        <WithPermission permissions={["AS_CreateStore"]}>
          <Button as={Link} to="new" aria-label="cy-store-add">
            <Text>company.stores.addStore</Text>
          </Button>
        </WithPermission>
      </div>
      <div className="flex items-center gap-4">
        <SearchBox
          value={params?.get("keyword") || ""}
          onSubmit={handleSetParams("keyword")}
          disabled={loading}
          totalItems={data.totalItems}
          onReload={getData}
        />
      </div>
      {[1, 2, 3, 4, 5]
        .filter(() => loading)
        .map(e => (
          <Skeleton.ImageList key={e} />
        ))}
      {!loading &&
      data &&
      Object.keys(data).length !== 0 &&
      data.items.length !== 0 ? (
        <>
          <Table>
            {data.items?.map(e => (
              <tr key={e.id}>
                <td>
                  <Link
                    to={detailsPermission ? e.id : ""}
                    className="flex-center inline-flex w-fit gap-2"
                    replace={!detailsPermission}
                  >
                    <Table.Logo src={e.mainPhotoUrl} alt={e.title} />
                    <div className="space-y-4">
                      <h6 className="text-heading-6">{e.title}</h6>
                      <AddressViewer address={e.address} />
                    </div>
                  </Link>
                </td>
                <td className="text-heading-5">{e.code}</td>
                <td>
                  <Status.Store id={e.isActive} />
                </td>
                <td>
                  <WithPermission permissions={["AS_GetStore"]}>
                    <Button as={Link} to={e.id} variant="light" size="sm">
                      <Text>global.buttons.view</Text>
                    </Button>
                  </WithPermission>
                </td>
              </tr>
            ))}
          </Table>
          <Pagination
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            pageNumber={
              params?.get("pageNumber")
                ? parseInt(params?.get("pageNumber"))
                : 1
            }
            pageSize={parseInt(params?.get("pageSize"))}
            setActivePage={handleSetParams("pageNumber")}
            onPageSizeChange={e => setParams(p => ({...p, pageSize: e}))}
          />
        </>
      ) : (
        !loading && (
          <div className="flex w-full flex-col items-center space-y-4">
            <NoItems />
          </div>
        )
      )}
    </div>
  );
};

export default withPermission(StoreList, ["AS_GetStore"]);
