import { NotificationManager } from "common/notifications";
import {
  Button,
  Icon,
  LoadingSvg,
  NoItems,
  Skeleton,
  Status,
  Table,
  Text,
  WithPermission,
} from "components";
import { useToggleState } from "hooks";
import { Fragment, useEffect, useState } from "react";
import { ServiceApi, URLS } from "services";
import { convertDate } from "utils";
import ChannelDetails from "./drawers/channels-details";

type Props = {
  id: string;
  publishUrl: (id: string) => string;
  unpublishUrl: (id: string) => string;
};

const Channels = ({ id, publishUrl, unpublishUrl }: Props) => {
  const [loading, setLoading] = useToggleState();
  const [loadingChannel, toggleLoadingChannel] = useToggleState();
  const [publishLoading, setPublishLoading] = useToggleState();
  const [publishedList, setPublishedList] = useState<any>([]);
  const [isOpenDetails, toggleDetails] = useToggleState();
  const [channelDetails, setChannelDetails] = useState<any>({});
  const [channels, setChannels] = useState<any>([]);
  const [selectedChannelCode, setSelectedChannelCode] = useState<any>({
    code: "",
    action: "",
  });
  const [currentPublishedChannel, setCurrentPublished] = useState<string[]>([]);
  const [loadingChannelStatus, setLoadingChannelStatus] = useState<string[]>(
    [],
  );

  const getChannels = () => {
    const url = URLS.GET_ALL_SALES_CHANNELS;
    toggleLoadingChannel();
    ServiceApi.get(url)
      .then(({ data }) => {
        setChannels(data);
      })
      .catch(() => {
        setChannels([]);
        toggleLoadingChannel();
      })
      .finally(() => {
        toggleLoadingChannel();
      });
  };

  const getChannelDetails = (channel: any) => {
    setChannelDetails(channel);
  };

  const getPublishedChannels = () => {
    const clone: any = [];
    setLoading();
    const urls = channels.map((channel: any) =>
      ServiceApi.get(URLS.GET_CHANNEL_IS_PUBLISHED(id, channel.code)),
    );
    Promise.all(urls)
      .then(([...responses]) => {
        responses.map((res: any) => clone.push(res?.data));
      })
      .finally(() => {
        setPublishedList([...clone]);
        setLoading();
      });
  };
  const removeUnpublishChannel = async (channel: any) => {
    setPublishedList((p: any) =>
      p?.filter((info: any) => info.channelCode !== channel?.code),
    );
  };

  useEffect(() => {
    if (id) {
      if (!!channels.length) {
        getPublishedChannels();
      }
    }
  }, [channels, id]);

  useEffect(() => {
    getChannels();
  }, [id]);

  const unPublishHandler = (channel: any) => {
    setPublishLoading();
    setSelectedChannelCode({ code: channel.code, action: "unpublish" });
    const url = unpublishUrl(id);
    const body = { channelCode: channel.code };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.unpublish-msg",
          "global.toast.unpublish-title",
        );
        setPublishLoading();
        removeUnpublishChannel(channel);
      })
      .catch(() => setPublishLoading());
  };

  const publishHandler = (channel: any) => {
    const code = channel.code;
    setSelectedChannelCode({ code, action: "publish" });
    publishToChannel({
      channelCode: code,
      legalEntityCodes: [channel?.legalEntity?.code],
    });
  };

  const createIntervalForChannels = (code: string) => {
    let channelInterval: any;
    setCurrentPublished(p => [...p, code]);
    channelInterval = setInterval(() => {
      ServiceApi.get(URLS.GET_CHANNEL_IS_PUBLISHED(id, code)).then(
        ({ data, status }) => {
          if (status === 200) {
            setCurrentPublished(p => p?.filter((ls: any) => ls !== code));
            setPublishedList((p: any) => [...p, data]);
            clearInterval(channelInterval);
          }
        },
      );
    }, 3000);
  };

  const publishToChannel = (body: {
    channelCode: string;
    legalEntityCodes: string[];
  }) => {
    setPublishLoading();
    const url = publishUrl(id);
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.publish-msg",
          "global.toast.publish-title",
        );
        createIntervalForChannels(body.channelCode);
      })
      .finally(() => {
        setPublishLoading();
        setLoadingChannelStatus(p =>
          p?.filter((ls: any) => ls !== body.channelCode),
        );
      });
  };

  return (
    <div className="space-y-4">
      {loadingChannel ? (
        [1, 2, 3, 4].map(() => <Skeleton.List />)
      ) : (
        <Table>
          {channels?.length !== 0 ? (
            channels?.map((channel: any) => {
              return (
                <tr
                  className="text-heading-6 font-semibold"
                  key={channel?.code}
                >
                  <td className="space-y-2">
                    <span>{channel?.title}</span>
                    <p className="text-body-2 font-medium">
                      <Text>productManagement.products.Details.code</Text> :{" "}
                      {channel.code}
                    </p>
                    <p className="text-body-2 font-medium">
                      <Text>
                        productManagement.products.Details.legalEntity
                      </Text>{" "}
                      : {channel?.legalEntity?.code}
                    </p>
                  </td>
                  <td>
                    {loading ||
                      currentPublishedChannel.includes(channel?.code) ? (
                      <LoadingSvg size="sm" />
                    ) : (
                      <>
                        {publishedList.filter(
                          (pub: any) => pub.channelCode === channel?.code,
                        ) && (
                            <p className="text-body-base font-normal">
                              {publishedList.find(
                                (info: any) => info.channelCode === channel?.code,
                              ) && (
                                  <>
                                    <Status.PimChannelStatus
                                      id={
                                        publishedList.find(
                                          (info: any) =>
                                            info.channelCode === channel?.code,
                                        )?.status
                                      }
                                    />
                                    <span className="ml-2">
                                      <Text>
                                        productManagement.products.Details.at
                                      </Text>
                                    </span>
                                  </>
                                )}

                              <span className="ml-2">
                                {convertDate(
                                  publishedList.find(
                                    (info: any) =>
                                      channel?.code === info.channelCode,
                                  )?.createdAt,
                                )}
                              </span>
                            </p>
                          )}
                      </>
                    )}
                  </td>
                  <WithPermission permissions={["PS_PublishSupplierToChannel"]}>
                    <Fragment>
                      <td className="flex flex-col gap-4 xl:table-cell">
                        {publishedList.find(
                          (info: any) => channel?.code === info.channelCode,
                        ) ? (
                          <>
                            <Button
                              variant={"light"}
                              size="sm"
                              className="mr-2"
                              type="button"
                              onClick={() => {
                                toggleDetails();
                                getChannelDetails(
                                  publishedList.find(
                                    (pub: any) =>
                                      pub.channelCode === channel?.code,
                                  ),
                                );
                              }}
                              disabled={publishLoading}
                            >
                              <Text>
                                productManagement.masterData.suppliers.details
                              </Text>
                            </Button>
                            <Button
                              light
                              variant="danger"
                              size="sm"
                              onClick={() => unPublishHandler(channel)}
                              type="button"
                              disabled={publishLoading || loading}
                              loading={
                                publishLoading &&
                                selectedChannelCode.code === channel.code &&
                                selectedChannelCode.action === "unpublish"
                              }
                              className="mr-2"
                            >
                              <Text>
                                productManagement.masterData.suppliers.unPublish
                              </Text>
                            </Button>
                            <Button
                              light
                              size="sm"
                              onClick={() => publishHandler(channel)}
                              type="button"
                              disabled={publishLoading || loading}
                              loading={
                                (publishLoading &&
                                  selectedChannelCode.code === channel.code &&
                                  selectedChannelCode.action === "publish") ||
                                loadingChannelStatus?.includes(channel?.code)
                              }
                            >
                              <Text>
                                productManagement.masterData.suppliers.rePublish
                              </Text>
                            </Button>
                          </>
                        ) : (
                          <Button
                            light
                            size="sm"
                            type="button"
                            onClick={() => publishHandler(channel)}
                            disabled={publishLoading || loading}
                            loading={
                              (publishLoading &&
                                selectedChannelCode.code === channel.code &&
                                selectedChannelCode.action === "publish") ||
                              loadingChannelStatus?.includes(channel?.code)
                            }
                          >
                            <Text>
                              productManagement.masterData.suppliers.publish
                            </Text>
                          </Button>
                        )}
                      </td>
                    </Fragment>
                  </WithPermission>
                </tr>
              );
            })
          ) : (
            <div className="flex w-full flex-col items-center space-y-4">
              <NoItems />
              <Text>
                productManagement.masterData.suppliers.thereIsNoChannel
              </Text>
              !
            </div>
          )}
        </Table>
      )}

      <ChannelDetails
        isOpen={isOpenDetails}
        toggle={toggleDetails}
        details={channelDetails}
      />
    </div>
  );
};

export default Channels;
