import { Channels } from "components";
import { useContext } from "react";
import { ProgramContext } from "../details";
import { URLS } from "services";

export default function General() {
  const { data } = useContext(ProgramContext);

  return (
    <Channels
      id={data?.id}
      publishUrl={URLS.PUBLISH_PROGRAM_TO_CHANNEL}
      unpublishUrl={URLS.UNPUBLISH_PROGRAM_FROM_CHANNEL} />
  );
}
