import {useEffect, useState} from "react";
import {
  Drawer,
  Button,
  Icon,
  Text,
  DeleteModal,
  Wrapper,
  InputGroup,
  DatePicker,
  CheckBox,
} from "components";
import {useToggleState} from "hooks";
import {ServiceApi, URLS} from "services";
import {NotificationManager} from "common/notifications";
import {PriceEngin} from "types";

type Props = {
  isOpen: boolean;
  toggle: () => void;
  getData: () => void;
  id: string;
  unit: any;
};

type AddProps = {
  refId: string;
  level?: number;
  refCode?: string;
  refTitle?: string;
  validToDate?: string;
  validFromDate?: string;
  calculationUnitCode?: string;
  calculationUnitId?: string;
};

export default function EditUnit({
  isOpen,
  toggle,
  getData,
  id,
  unit,
  level,
  refId,
  refCode,
  refTitle,
}: Props & AddProps) {
  const [submitLoading, toggleSubmit] = useToggleState();
  const [isOpenDelete, toggleDelete] = useToggleState();
  const [deleteLoading, toggleDeleteLoading] = useToggleState();
  const [isChecked, toggleCheck] = useToggleState();
  const [data, setData] = useState<PriceEngin.CalculationUnitItem & AddProps>();

  useEffect(() => {
    setData(unit);
  }, [unit]);

  const onDeleteHandler = () => {
    toggleDeleteLoading();
    const url = URLS.REMOVE_CALCULATION_UNIT_CUSTOMIZATION_ITEM(id, unit?.id);
    ServiceApi.delete(url)
      .then(() => {
        NotificationManager.success(
          "global.toast.delete-msg",
          "global.toast.delete-title",
        );
        toggleDeleteLoading();
        toggleDelete();
        toggle();
        getData();
      })
      .catch(() => toggleDeleteLoading());
  };

  const onChangeHandler = (key: string) => {
    return (value: any) => setData((p: any) => ({...p, [key]: value}));
  };

  const OnSubmitHandler = () => {
    toggleSubmit();
    const {
      title,
      calculationUnitCode,
      itemValue,
      active,
      operationType,
      valueType,
      validFromDate,
      validToDate,
      calculationUnitId,
    } = data!;
    const url = URLS.ADD_CALCULATION_UNIT_CUSTOMIZATION_ITEM;
    const body = {
      title,
      level,
      refIdentifier: refId,
      refCode,
      refTitle,
      calculationUnitId: calculationUnitId,
      calculationUnitCode: calculationUnitCode,
      calculationUnitTitle: title,
      operationType,
      valueType,
      itemValue,
      active,
      validFromDate: isChecked ? validFromDate : "",
      validToDate: isChecked ? validToDate : "",
    };
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.add-msg",
          "global.toast.add-title",
        );
        toggleSubmit();
        toggle();
        getData();
      })
      .catch(() => toggleSubmit());
  };

  return (
    <>
      <Drawer isOpen={isOpen} toggle={toggle}>
        <Drawer.Menu>
          <Drawer.Header className="flex items-center justify-between">
            <span className="text-heading-2 font-semibold text-gray-800">
              <Text>priceEngine.calculationLevel.details</Text>
            </span>
            <div className="flex gap-2">
              <Button
                variant={"danger"}
                light
                type="button"
                size="sm"
                onClick={toggleDelete}
              >
                <Icon icon="trash" className="mr-2" />
                <Text>Delete calculation unit </Text>
              </Button>
              <Button
                variant={"light"}
                type="button"
                onClick={toggle}
                size="sm"
              >
                <Icon icon="times" className="mr-2" />
                <Text>global.buttons.close</Text>
              </Button>
            </div>
          </Drawer.Header>
          <Drawer.Body className="flex flex-col gap-4 space-y-4">
            <span>
              <Text>priceEngine.calculationLevel.calculationUnit</Text>
            </span>
            {
              <>
                <Wrapper className="shadow-card">
                  <Wrapper.Body className="flex flex-col space-y-4 !p-4">
                    <div
                      className="flex justify-between gap-2"
                      // onClick={() => onSelectUnitHandler(unit)}
                    >
                      <div className="flex flex-col space-y-2">
                        <span className="text-heading-5 font-semibold text-gray-800">
                          {data?.title}
                        </span>
                        <p className="text-body-base font-medium text-gray-500">
                          #{data?.calculationUnitCode}
                        </p>
                      </div>
                    </div>
                    <p className="truncate text-body-base font-medium text-gray-500">
                      {data?.description}
                    </p>
                  </Wrapper.Body>
                </Wrapper>
                <InputGroup
                  label={"priceEngine.calculationLevel.value"}
                  value={data?.itemValue}
                  setValue={onChangeHandler("itemValue")}
                  type="number"
                />
                <CheckBox
                  value={isChecked}
                  setValue={toggleCheck}
                  label="priceEngine.calculationLevel.addValidationPeriod"
                />
                {/* <InputGroup
                  label={"priceEngine.calculationdatas.shortDescription"}
                  value={onChangeHandler("itemValue")}
                  as={"short-textarea"}
                /> */}
                <div className="grid grid-cols-2 gap-4">
                  <DatePicker
                    minDate={new Date()}
                    maxDate={data?.validToDate ? data?.validToDate : null}
                    placeholderText="priceEngine.purchasePrice.selectDate"
                    label="priceEngine.purchasePrice.from"
                    value={data?.validFromDate}
                    onChange={onChangeHandler("validFromDate")}
                  />
                  <DatePicker
                    minDate={
                      data?.validFromDate ? data?.validFromDate : new Date()
                    }
                    placeholderText="priceEngine.purchasePrice.selectDate"
                    label="priceEngine.purchasePrice.to"
                    value={data?.validToDate}
                    onChange={onChangeHandler("validToDate")}
                  />
                </div>
              </>
            }
          </Drawer.Body>
          <Drawer.Footer className="flex justify-between">
            <Button size="sm" variant={"light"} onClick={toggle} type="button">
              <Text>global.cancel</Text>
            </Button>
            <Button
              size="sm"
              type="submit"
              loading={submitLoading}
              onClick={OnSubmitHandler}
            >
              <Text>global.buttons.saveChanges</Text>
            </Button>
          </Drawer.Footer>
        </Drawer.Menu>
      </Drawer>
      <DeleteModal
        isOpen={isOpenDelete}
        toggle={toggleDelete}
        selected={{name: unit?.title}}
        loading={deleteLoading}
        onConfirm={onDeleteHandler}
      />
    </>
  );
}
