import React, {useMemo} from "react";
import {useMountEffect, useMenu, usePermission} from "hooks";
import {useLocation} from "react-router";
import {classNames} from "utils";
import {Icon} from "components";
import {Text} from "components";
import {isUUID} from "utils";
import Menu from "./Menu";
import {useSelector} from "hooks";
import {appPermissions} from "constant";
import {Permission} from "types";

type PanelMenuSubProps = {
  isActive: boolean;
  model: any;
  multiple?: boolean;
  selectedMenu: string;
  className: string;
  setSelectedMenu: CallableFunction;
};

export const PanelMenuSub = (props: PanelMenuSubProps) => {
  const permissions = useSelector(s => s.permissions);
  const menuList = useMenu();
  const location = useLocation();

  const hasPermission = (...permissionsKey: Permission[]) => {
    if (permissionsKey.includes("withoutPermission")) return true;
    if (!permissionsKey?.length) return false;
    return !!permissionsKey?.some(permissionKey =>
      permissions?.includes(appPermissions[permissionKey]),
    );
  };

  useMemo(() => {
    const pathname = location.pathname;
    const pathnameItems = pathname.split("/").slice(1);
    let items: any = [];
    pathnameItems.forEach(key => {
      const parentItems = items.at(-1)?.items ?? menuList.menu;
      if (isUUID(key)) {
        items.push({label: "global.details"});
      } else if (key === "new") {
        items.push({label: "global.addNew"});
      } else {
        const item = parentItems.find((e: any) => e.path === key);
        items.push(item);
      }
    });
    items.unshift({label: "menu.dashboard"});
    items = items.map((e: any) => e?.label);
    props.setSelectedMenu(items[items.length - 1] || items[0]);
  }, [location.pathname, menuList.menu]);

  const [activeItemState, setActiveItemState] = React.useState<any>(null);

  const findActiveItem = () => {
    if (activeItemState) {
      return props.model.filter((item: any) => item.expanded);
    }

    return null;
  };

  const onItemClick = (event: any, item: any) => {
    if (item.disabled) {
      event.preventDefault();

      return;
    }

    if (!item.url) {
      event.preventDefault();
    }

    let activeItem: any = activeItemState;
    let active = isItemActive(item);

    if (active) {
      item.expanded = false;
      setActiveItemState(
        props.multiple
          ? activeItem.filter((a_item: any) => a_item !== item)
          : null,
      );
    } else {
      item.expanded = true;
      setActiveItemState(props.multiple ? [...(activeItem || []), item] : item);
    }
    if (item.command) {
      props.setSelectedMenu(item.label);
      item.command({
        originalEvent: event,
        item,
      });
    }
  };

  const isItemActive = (item: any) => {
    return (
      activeItemState &&
      (props.multiple
        ? activeItemState.indexOf(item) > -1
        : activeItemState === item)
    );
  };

  useMountEffect(() => {
    setActiveItemState(findActiveItem());
  });

  const createSeparator = (index: any) => {
    const key = "separator_" + index;

    return <li key={key} className="menu-separator"></li>;
  };

  const createSubmenu = (item: any) => {
    if (item.items) {
      return (
        <PanelMenuSub
          model={item.items}
          className="panelmenu-root-submenu"
          multiple={props.multiple}
          isActive={props.isActive}
          setSelectedMenu={props.setSelectedMenu}
          selectedMenu={props.selectedMenu}
        />
      );
    }

    return null;
  };

  const createMenuItem = (item: any, index: number) => {
    const permission = hasPermission(item.permission);
    if (item.visible === false || !permission) {
      return null;
    }

    const linkClassName = classNames("menuitem-link text-body-base w-full", {
      disabled: item.disabled,
      "clickable-menu": item.command,
    });
    const icon = (
      <span
        className="flex items-center"
        key={index}
        style={{flex: "0 0 24px", height: "24px"}}
      >
        <Icon icon={item.icon} className="mr-2" />
      </span>
    );
    const label = item.label && <Text>{item.label}</Text>;
    const submenu = createSubmenu(item);
    let content = (
      <a
        key={item.url}
        href={item.url || "#"}
        className={linkClassName}
        target={item.target}
        onClick={event => onItemClick(event, item)}
        role="menuitem"
        aria-disabled={item.disabled}
      >
        <span className="flex items-center">
          {icon}
          {label}
        </span>
      </a>
    );
    return (
      <>
        {props.isActive && (
          <Menu key={item.label}>
            <Menu.Item
              key={item.label}
              selected={props.selectedMenu === item.label && props.isActive}
              active={location.pathname.includes(item.path)}
            >
              <Menu.Toggle
                key={item.label}
                clickable={item.items?.length > 0}
                isActive={props.isActive}
                className={`h-[32px]`}
                selected={props.selectedMenu === item.label}
              >
                {content}
              </Menu.Toggle>
              <Menu.Body>{submenu}</Menu.Body>
            </Menu.Item>
          </Menu>
        )}
      </>
    );
  };

  const createItem = (item: any, index: number) => {
    return item.separator
      ? createSeparator(index)
      : createMenuItem(item, index);
  };

  const createMenu = () => {
    return props.model ? props.model.map(createItem) : null;
  };

  const className = classNames("submenu-list", props.className);
  const menu = createMenu();

  return (
    <ul className={className} role="tree" key={new Date().toDateString()}>
      {menu}
    </ul>
  );
};

PanelMenuSub.displayName = "PanelMenuSub";
