import {NotificationManager} from "common/notifications";
import {
  Badge,
  Button,
  Icon,
  ImageUploader,
  LazyImage,
  LoadingSvg,
  Text,
} from "components";
import {useConverter, useToggleState} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";
import ProductFamilyPath from "../helpers/product-family-path";
import InfoModal from "./drawers/info";
import {assetTypes} from "constant";
import {divide, sumBy} from "lodash";

const PimProductProviderOrigin = ["None", "EMV", "Iwofurn", "Excel"];
//Types
type Props = {
  productData: any;
  loading: boolean;
  setData: any;
  setPatchData: any;
  getPIMProduct: any;
  totalVks: any;
  activeKey: any;
  status: boolean;
};

const Info = ({
  productData,
  loading,
  getPIMProduct,
  totalVks,
  activeKey,
  status,
}: Props) => {
  const {convertAmount} = useConverter();
  const [isOpen, toggle] = useToggleState();
  const [isOpenInfo, toggleInfo] = useToggleState();
  const [programBrand, setProgramBrand] = useState<any>();
  const [totalPrice, setTotalPrice] = useState({vk1: 0, vk2: 0, linePrice: 0});

  useEffect(() => {
    //setBrand
    if (productData?.supplierId && productData?.brandId) {
      findBrandInfo();
    } else {
      setProgramBrand({});
    }
  }, [productData?.brandId]);

  const findBrandInfo = () => {
    const id = URLS.GET_BRAND_DETAILS(productData?.brandId);
    ServiceApi.get(id).then(({data}) => {
      setProgramBrand(data);
    });
  };

  const onUploadHandler = (image: any) => {
    const url = URLS.UPDATE_PIM_PRODUCT_AVATAR_URL(productData?.id);
    ServiceApi.patch(url, image).then(() => {
      NotificationManager.success(
        "global.toast.edit-msg",
        "global.toast.edit-title",
      );
      getPIMProduct();
    });
  };

  useEffect(() => {
    if (!!totalVks.length) {
      const result = {
        vk1: sumBy(totalVks, "vk1"),
        vk2: sumBy(totalVks, "vk2"),
        linePrice: sumBy(totalVks, "linePrice"),
      };
      setTotalPrice(result);
    }
  }, [totalVks]);
  return (
    <>
      <div className="flex space-x-4">
        <LazyImage
          isDynamic
          className="h-[191px] w-[349px] cursor-pointer bg-gray-100"
          src={productData?.avatar?.url}
          onClick={toggle}
          editor
          imageClassName="w-[349px] h-[191px]"
        />
        <section className="space-y-4">
          {loading ? (
            <div className="w-96 animate-pulse space-y-4">
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
              <div className="col-span-2 h-2 rounded bg-slate-200"></div>
              <div className="col-span-1 h-2 rounded bg-slate-200"></div>
            </div>
          ) : (
            <>
              <div className="flex items-center space-x-4">
                <h5 className="text-gray-800">{productData?.originalName}</h5>
                {!productData?.pimProductType ? (
                  <Badge variant={"gray"} className="flex w-fit items-center">
                    <Text>
                      {PimProductProviderOrigin[productData?.provider?.origin]}
                    </Text>
                  </Badge>
                ) : (
                  <Badge
                    variant={"warning"}
                    className="flex w-fit items-center"
                  >
                    <Text>Bundle</Text>
                  </Badge>
                )}
              </div>
              <div className="flex gap-x-10 gap-y-4 text-body-2">
                <div className="space-y-4">
                  {productData?.pimProductFamilyId && (
                    <p className=" w-fit rounded bg-success-light p-2 font-medium leading-5">
                      <span className="font-medium text-gray-600 ">
                        <ProductFamilyPath
                          productData={productData}
                          pimProductFamilyId={productData?.pimProductFamilyId}
                        />
                      </span>
                    </p>
                  )}
                  <p className="font-medium text-gray-500">
                    <Text>
                      productManagement.products.Details.articleNumber
                    </Text>
                    <span className="font-medium text-gray-700">
                      :{" "}
                      {productData?.articleNumber ? (
                        <span aria-label="cy-article-number">{`#${productData?.articleNumber}`}</span>
                      ) : (
                        "--"
                      )}
                    </span>
                  </p>
                  {!productData?.pimProductType && (
                    <p className="font-medium text-gray-500">
                      <Text>
                        productManagement.products.Details.supplierArticleNumber
                      </Text>
                      <span className="font-medium text-gray-700">
                        {" "}
                        :{" "}
                        {productData?.supplierArticleNumber
                          ? productData?.supplierArticleNumber
                          : "--"}
                      </span>
                    </p>
                  )}
                  {productData?.referenceKey && (
                    <div>
                      <Text>
                        productManagement.products.allProducts.referenceKey
                      </Text>
                      :<span className="ml-1">{productData?.referenceKey}</span>
                    </div>
                  )}
                  <p className="font-medium text-gray-500">
                    <Text>
                      productManagement.products.Details.currentProductVersion
                    </Text>
                    <span className="font-medium text-gray-700">
                      {" "}
                      : {productData?.version ? productData?.version : "--"}
                    </span>
                  </p>
                </div>
              </div>
            </>
          )}
        </section>
      </div>
      <div className="flex flex-none flex-col items-end justify-between">
        {loading ? (
          <div className="ml-auto h-9 w-20 rounded bg-slate-200"></div>
        ) : (
          <>
            <Button light className="min-h-10 space-x-2" onClick={toggleInfo}>
              <span>
                <Text>productManagement.products.Details.moreInfo</Text>
              </span>
              <Icon icon="info-circle" />
            </Button>
            <div className="mt-4 flex h-full flex-col items-end justify-between gap-2">
              {productData?.pimProductType ? (
                <>
                  {status ? (
                    <LoadingSvg size="sm" />
                  ) : (
                    <div className="flex min-w-64 flex-col gap-6 rounded bg-gray-100 p-6">
                      {
                        <div className="flex items-center justify-between">
                          <span className="text-body-base text-heading-6 text-gray-600">
                            <Text>sum vk2</Text> :{" "}
                          </span>
                          <span className="text-heading-4 font-semibold text-primary">
                            {convertAmount(totalPrice?.vk2)}
                          </span>
                        </div>
                      }
                      {
                        <div className="flex items-center justify-between">
                          <span className="text-body-base text-heading-6 text-gray-600">
                            <Text>sum vk1</Text> :{" "}
                          </span>
                          <span className="text-heading-4 font-semibold text-primary">
                            {convertAmount(totalPrice?.vk1)}
                          </span>
                        </div>
                      }

                      {
                        <div className="flex items-center justify-between">
                          <span className="text-body-base text-heading-6 text-gray-600">
                            <Text>sum salesPrice</Text> :{" "}
                          </span>
                          <span className="text-heading-4 font-semibold text-primary">
                            {convertAmount(totalPrice?.linePrice)}
                          </span>
                        </div>
                      }
                    </div>
                  )}
                </>
              ) : (
                <></>
              )}
            </div>
          </>
        )}

        <div>
          <div className="flex gap-2">
            {productData?.brandName && (
              <p className="flex items-center gap-2 border-r-[2px] border-gray-300 pr-2">
                {programBrand?.logoUrl && (
                  <LazyImage
                    isDynamic
                    logo
                    src={programBrand?.logoUrl}
                    className="h-6 w-auto !rounded-none border-none"
                  />
                )}
                <span className="font-medium text-gray-700">
                  {" "}
                  {productData?.brandName}
                </span>
              </p>
            )}
            <p className="flex items-center gap-2">
              {productData?.supplierInfo?.logoUrl && (
                <LazyImage
                  isDynamic
                  logo
                  src={productData?.supplierInfo?.logoUrl}
                  className="h-6 w-auto !rounded-none border-none"
                />
              )}
              <span className="font-medium text-gray-700">
                {" "}
                {productData?.supplierName}
              </span>
            </p>
          </div>
        </div>
      </div>
      <ImageUploader
        isOpen={isOpen}
        toggle={toggle}
        image={productData?.avatar?.url}
        onUpload={image => onUploadHandler(image)}
        type={assetTypes.PimProduct}
      />
      <InfoModal isOpen={isOpenInfo} toggle={toggleInfo} data={productData} />
    </>
  );
};

export default Info;
