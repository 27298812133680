import {Text} from "components";
import {config} from "constant";
import {useContext, useEffect, useState} from "react";
import {AttributeContext} from "..";

export default function AttributeType() {
  const {setData} = useContext<any>(AttributeContext);
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    setSelected(10);
    setData((p: any) => ({...p, type: 10}));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="space-y-8">
      <div className="flex items-center">
        <h2 className="text-gray-800">
          <Text>
            productManagement.masterData.attributes.chooseAttributeType
          </Text>
        </h2>
      </div>
      <div className="space-y-4">
        <div className="grid grid-cols-4 gap-4">
          {config?.attributeTypesOptions?.map((item: any) => {
            return (
              <div
                key={item.type}
                className={`h-[110px] w-full cursor-pointer rounded-xl text-center shadow-[0_4px_20px_rgba(56,71,109,0.09)] ${
                  selected === item.type ? "bg-primary-light" : ""
                }`}
              >
                <div
                  className="flex h-full w-full items-center justify-center"
                  onClick={() => {
                    setSelected(item.type);
                    setData((p: any) => ({...p, type: item.type}));
                  }}
                >
                  <div className="space-y-4 text-center">
                    <div>
                      <img
                        className="ml-auto mr-auto block text-primary"
                        src={item.icon}
                        alt=""
                      />
                    </div>
                    <div>
                      <h6 className="font-normal text-gray-800">{item.name}</h6>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
