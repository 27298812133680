import PurchasePrice from "./purchase-price";
import SalesPrice from "./sales-price";

const PriceTemplates = [
  {
    path: "purchase-price",
    children: [...PurchasePrice],
  },
  {
    path: "sales-price",
    children: [...SalesPrice],
  },
];

export default PriceTemplates;
