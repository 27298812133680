import {NotificationManager} from "common/notifications";
import {
  Accordion,
  Button,
  Dropdown,
  HandleName,
  Icon,
  SelectLang,
  Skeleton,
  Text,
  Toggler,
  WithPermission,
  Wrapper,
} from "components";
import {flagCountry} from "constant";
import {useSelector, useToggleState, useTranslate, usePermission} from "hooks";
import {compact, flatten, isEmpty} from "lodash";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {ServiceApi, URLS} from "services";
import {PimContext} from "..";
import {findValue} from "../../helpers/find-attribute.value";
import FormFieldGenerator from "../attributes";
import CopyDefaultValuesDrawer from "../../all-products-drawers/copy-default";
import {findChannelName} from "../utils";

const AttributesTab = () => {
  const editPermission = usePermission("PS_UpdatePimProduct");
  const {i18n} = useTranslation();
  const {productData, setAttributeChanged, attributeChanged, channels} =
    useContext(PimContext);
  const [loading, setLoading] = useToggleState();
  const [isOpenCopy, toggleCopy] = useToggleState();
  const companySetting = useSelector(s => s.companySetting);
  const [submitLoading, setSubmitLoading] = useToggleState();
  const [attributes, setAttributes] = useState<any>([]);
  const [flatAttributes, setFlatAttributes] = useState<any>([]);
  const [groupFilter, setGroupfilter] = useState<any>("all");
  const [selectedChannel, setSelectedChannel] = useState<any>("");
  const [showOriginalAttribute, setShowOriginalAttribute] =
    useState<any>(false);
  const [selectedFilter, setSelectedFilter] = useState<any>("");
  const [items, setItems] = useState<any>([]);
  const [lang, setLang] = useState<string>("de");
  const [values, setValues] = useState<any>({});
  const [baseData, setBaseData] = useState<any>({});
  const [changedAttributes, setChangedAttributesValues] = useState<any>([]);
  const translate = useTranslate();

  useEffect(() => {
    setAttributeChanged(!!changedAttributes.length);
  }, [changedAttributes]);

  useEffect(() => {
    let arr: any = {};
    let arrItem: any = [];
    companySetting?.contentLanguages?.forEach((item: any) => {
      arr[item] = item;
      arrItem.push({
        name: item,
        id: item,
      });
    });
    setItems(arrItem);
  }, []);

  useEffect(() => {
    ChangedAttributesFunction();
  }, [values]);

  const getProductAttributes = () => {
    setLoading();
    const url = URLS.GET_PIM_PRODUCT_ATTRIBUTES(productData?.id);
    ServiceApi.get(url)
      .then(({data}) => {
        setAttributes(data);

        const initBaseData = data.map((item: any) => item.attributes);
        const test = initBaseData.map((item: any, index: number) => {
          return item?.map((attr: any) => ({
            ...attr,
            attributeGroupIndex: index,
          }));
        });
        setFlatAttributes(flatten(test));
        setBaseData(initBaseData);
        setValues(initBaseData);
        if (!selectedChannel) {
          setSelectedChannel(channels?.[0]?.code);
        }
        setSelectedFilter("all");
        setLoading();
      })
      .catch(() => setLoading());
  };

  useEffect(() => {
    getProductAttributes();
  }, [productData?.id]);

  const findMissingAttributes = (attribute: any, index: number) => {
    const requiredFields = attribute.filter((item: any) => {
      const {value} = findValue(
        item,
        values?.[index],
        item.attributeTemplateId,
        selectedChannel,
        lang,
      );
      return item.isRequired && !value;
    });
    if (requiredFields.length) {
      return (
        <>
          <Icon icon="star-exclamation" className="mr-1 text-danger" />
          <span className="mr-6">
            {requiredFields.length}{" "}
            <span>
              <Text>
                productManagement.products.Details.missingRequiredAttribute
              </Text>
            </span>
          </span>
        </>
      );
    }
    return null;
  };
  const findChangedAttributes = (attribute: any, index: number) => {
    const changedFields = attribute.filter((item: any) => {
      return item.hasChanged;
    });
    if (changedFields.length) {
      const clone = [...changedAttributes];
      changedFields?.map((item: any) => {
        const {target} = findValue(
          item,
          values?.[index],
          item.attributeTemplateId,
          selectedChannel,
          lang,
        );

        if (!isEmpty(target)) {
          const isExist = clone.find(
            (innerItem: any) => innerItem?.code === item.code,
          );
          if (!isExist) {
            clone.push({
              code: item.code,
              values: target.map((item: any) => item),
            });
          } else {
            const objIndex = clone.findIndex(
              (innerItem: any) => innerItem.code === item.code,
            );
            clone[objIndex] = {
              ...clone[objIndex],
              values: target.map((item: any) => item),
            };
          }
        }
      });
      return (
        <>
          <Icon icon="star-exclamation" className="mr-1 text-warning" />
          <span className="mr-6">
            {changedFields.length}{" "}
            <span>
              <Text>productManagement.products.Details.changedAttribute</Text>
            </span>
          </span>
        </>
      );
    }
    return null;
  };
  const ChangedAttributesFunction = () => {
    const arrayValues = attributes.map((attribute: any, index: number) => {
      const changedFields = attribute.attributes.filter((item: any) => {
        return item.hasChanged;
      });
      if (changedFields.length) {
        const clone = [...changedAttributes];
        changedFields?.map((item: any) => {
          const {target} = findValue(
            item,
            values?.[index],
            item.attributeTemplateId,
            selectedChannel,
            lang,
          );

          if (!isEmpty(target)) {
            const isExist = clone.find(
              (innerItem: any) => innerItem?.code === item.code,
            );
            if (!isExist) {
              clone.push({
                code: item.code,
                values: target.map((item: any) => item),
              });
            } else {
              const objIndex = clone.findIndex(
                (innerItem: any) => innerItem.code === item.code,
              );
              clone[objIndex] = {
                ...clone[objIndex],
                values: target.map((item: any) => item),
              };
            }
          }
        });
        return clone;
      }
    });
    const changedDefaultValues: any = compact(arrayValues).flat();
    if (changedDefaultValues.length > 0) {
      setChangedAttributesValues(changedDefaultValues);
    }
  };

  const findTranslatedName = (channel: any) => {
    return channel?.title;
  };

  const onChangeLanguage = (lang: any) => {
    setLang(lang);
  };

  const checkTranslations = (translates: any, isRequired: boolean) => {
    const defaultLang = translates?.find(
      (item: any) =>
        item.language.toLowerCase() ===
        companySetting.defaultDashboardLanguage.toLowerCase(),
    );
    const isValid = !!defaultLang?.value;
    const cloneTranslate = translates?.filter((item: any) => item?.value);
    if (isValid || !isRequired) {
      return cloneTranslate;
    } else
      NotificationManager.error(
        translate("global.toast.add-default-language-value"),
        translate("global.validation"),
      );
  };

  const onSubmitHandler = () => {
    let isAllItemsValidated = true;
    changedAttributes.map((attr: any) => {
      const values = attr.values?.[0];
      if (values["language"]) {
        const validatedValues = checkTranslations(attr.values, attr.isRequired);
        if (validatedValues) attr.values = validatedValues;
        else isAllItemsValidated = false;
      }
    });
    if (!isAllItemsValidated) return;
    setSubmitLoading();
    const body = {
      pimProductId: productData?.id,
      attributes: changedAttributes,
    };
    const url = URLS.UPSERT_PIM_PRODUCT_ATTRIBUTE_URL;
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setSubmitLoading();
        setChangedAttributesValues([]);
        getProductAttributes();
      })
      .catch(() => setSubmitLoading());
  };

  const onSubmitDefaultValue = () => {
    setSubmitLoading();

    let allValues: any[] = [];

    values.map((val: any) => {
      val.map((item: any) => allValues.push(item));
    });

    const defaultValues = allValues?.map((attr: any) => ({
      code: attr.code,
      values: attr?.pimProductAttribute?.pimProductAttributeDefaultValueDto,
    }));

    const bodyData = defaultValues?.filter(
      (attr: any) => attr?.values && attr?.values.length,
    );

    const body = {
      pimProductId: productData?.id,
      attributes: bodyData,
    };

    const url = URLS.UPSERT_PIM_PRODUCT_DEFAULT_ATTRIBUTE_URL;
    ServiceApi.post(url, body)
      .then(() => {
        NotificationManager.success(
          "global.toast.edit-msg",
          "global.toast.edit-title",
        );
        setSubmitLoading();
        setChangedAttributesValues([]);
        getProductAttributes();
      })
      .catch(() => setSubmitLoading());
  };

  const onChangeValue = (values: any, code: string, isRequired = false) => {
    const clone = [...changedAttributes];
    const isExist = clone.find((item: any) => item?.code === code);

    if (!isExist) {
      clone.push({
        code,
        values,
        isRequired,
      });
    } else {
      const objIndex = clone.findIndex((item: any) => item?.code === code);
      clone[objIndex] = {...clone[objIndex], values};
    }
    setChangedAttributesValues(clone);
  };

  const filterItems = [
    {id: "all", name: "productManagement.products.Details.allAtributeFilter"},
    {
      id: "missingRequierd",
      name: "productManagement.products.Details.missingRequiredAtributeFilter",
    },
    {
      id: "missing",
      name: "productManagement.products.Details.allMissingAtributeFilter",
    },
    {
      id: "hasWarning",
      name: "productManagement.products.Details.hasWarningAtributeFilter",
    },
  ];

  return (
    <>
      <div className="grid-col-1 grid gap-4 xl:grid-cols-3">
        {/* Header DropDowns */}
        <section className="sticky top-[64px] z-20 col-span-full flex flex-wrap items-center gap-4 bg-light py-2">
          <h6 className="font-normal">
            <Text>productManagement.products.Details.channels</Text>
          </h6>
          {loading ? (
            <Skeleton.Button />
          ) : (
            <Dropdown>
              <Dropdown.Toggle
                as="button"
                type="button"
                className="flex min-w-[10rem] space-x-2 rounded-md bg-white p-3 text-body-base font-medium uppercase text-gray-600"
              >
                <span>{findChannelName(channels, selectedChannel)} </span>
              </Dropdown.Toggle>
              <Dropdown.Menu className="mt-2 min-w-fit p-2">
                <ul className="w-max text-gray-700">
                  {channels?.map((channel: any) => (
                    <li
                      key={channel?.code}
                      onClick={() => setSelectedChannel(channel.code)}
                      className={`h6 px-4 py-2 font-medium first:mb-1 ${
                        channel.code === selectedChannel &&
                        "bg-primary-light text-primary "
                      } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                    >
                      {findTranslatedName(channel)}
                    </li>
                  ))}
                </ul>
              </Dropdown.Menu>
            </Dropdown>
          )}
          <h6 className="font-normal">
            <Text>productManagement.products.Details.language</Text>
          </h6>
          {loading ? (
            <Skeleton.Button />
          ) : (
            <SelectLang
              value={lang}
              items={items}
              onChangeLanguage={onChangeLanguage}
              className="bg-white"
            />
          )}
          <div>
            <Toggler
              label="productManagement.products.Details.showInvisibleAttributes"
              size="sm"
              value={showOriginalAttribute}
              setValue={e => setShowOriginalAttribute(e)}
            />
          </div>
          <Button
            variant={"white"}
            size="sm"
            className="!h-full"
            onClick={toggleCopy}
          >
            <Icon icon="copy" />
            <span className="ml-1">Copy Value on Channels</span>
          </Button>
          <div className="ml-auto">
            {loading ? (
              <Skeleton.Button />
            ) : (
              <div className="flex items-center gap-2">
                {selectedChannel !== "CH000D" && (
                  <Dropdown>
                    <Dropdown.Toggle
                      as="button"
                      type="button"
                      className="flex min-w-[10rem] space-x-2 rounded-md bg-white p-3 text-body-base font-medium uppercase text-gray-600"
                    >
                      <span>
                        {
                          <Text>
                            {
                              filterItems.filter(
                                (filter: any) => filter?.id === selectedFilter,
                              )?.[0]?.name
                            }
                          </Text>
                        }{" "}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2 min-w-fit p-2">
                      <ul className="w-max text-gray-700">
                        {filterItems.map((filter: any) => (
                          <li
                            key={filter?.id}
                            onClick={() => {
                              setSelectedFilter(filter.id);
                            }}
                            className={`h6 cursor-pointer rounded-lg px-4 py-2 text-body-base font-medium first:mb-1 hover:bg-primary-light hover:text-primary`}
                          >
                            <Text>{filter.name}</Text>
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        </section>
        {!loading && (
          <section className="col-span-full mb-2 flex flex-wrap items-center gap-4">
            <Button
              size="sm"
              variant={groupFilter === "all" ? "primary" : "white"}
              className="!rounded-full"
              onClick={() => setGroupfilter("all")}
            >
              <Text>productManagement.products.Details.allInGroupFilter</Text>
            </Button>
            {attributes?.map((attribute: any) => {
              return (
                attribute?.attributes?.length > 0 && (
                  <Button
                    size="sm"
                    className="!rounded-full"
                    variant={
                      groupFilter === attribute.translates?.[0]?.labelTitle
                        ? "primary"
                        : "white"
                    }
                    onClick={() =>
                      setGroupfilter(attribute.translates?.[0]?.labelTitle)
                    }
                  >
                    {
                      <HandleName
                        translations={attribute?.translates}
                        code={attribute?.code}
                        keyName="labelTitle"
                      />
                    }
                  </Button>
                )
              );
            })}
          </section>
        )}
        <section className="col-span-full space-y-6">
          {loading
            ? [1, 2, 3, 4].map(key => <Skeleton.List key={key} />)
            : attributes?.map((attribute: any, index: any) => {
                let sortAttribute = attribute.attributes?.sort(
                  (a: any, b: any) => {
                    return a.sortOrder - b.sortOrder;
                  },
                );
                return (
                  <>
                    {(groupFilter === "all" ||
                      groupFilter ===
                        attribute?.translates?.[0]?.labelTitle) && (
                      <Wrapper
                        key={attribute.attributeGroupTemplateId}
                        className="grid grid-cols-1 items-start text-gray-800 [&>div:nth-child(even)]:col-span-2 [&>div:nth-child(even)]:text-body-base [&>div:nth-child(odd)]:col-span-3 [&>div:nth-child(odd)]:text-heading-6 [&>div:nth-child(odd)]:font-medium"
                      >
                        <Accordion>
                          {sortAttribute?.length > 0 && (
                            <Accordion.Item
                              active={
                                index === 0 ||
                                groupFilter !== "all" ||
                                selectedFilter !== "all"
                              }
                              className="border-none"
                            >
                              <Accordion.Toggle className="sticky top-[120px] z-10 flex justify-between rounded-xl border-b bg-white">
                                <div className="flex items-center gap-[10px] px-6">
                                  <div>
                                    <Icon
                                      size="1x"
                                      icon={"chevron-down"}
                                      className="transition-transform group-[.accordion-item.active]:rotate-180"
                                    />
                                  </div>
                                  <div>
                                    <h4 className="font-normal text-gray-800">
                                      {
                                        <HandleName
                                          translations={attribute?.translates}
                                          code={attribute?.code}
                                          keyName="labelTitle"
                                        />
                                      }
                                    </h4>
                                  </div>
                                </div>
                                {selectedChannel !== "CH000D" && (
                                  <div className="flex space-x-2">
                                    <div className="text-body-2 text-gray-600">
                                      {findMissingAttributes(
                                        attribute.attributes,
                                        index,
                                      )}
                                    </div>
                                    <div className="text-body-2 text-gray-600">
                                      {findChangedAttributes(
                                        attribute.attributes,
                                        index,
                                      )}
                                    </div>
                                  </div>
                                )}
                              </Accordion.Toggle>
                              <Accordion.Body className="grid grid-cols-1 px-6 ">
                                <FormFieldGenerator
                                  id={attribute.attributeGroupTemplateId}
                                  attributes={attribute.attributes}
                                  attributeGroupIndex={index}
                                  values={values}
                                  language={lang}
                                  channel={selectedChannel}
                                  setValues={setValues}
                                  flag={flagCountry}
                                  filter={selectedFilter}
                                  showOriginalAttribute={showOriginalAttribute}
                                  onChangeValue={onChangeValue}
                                  disabled={!editPermission}
                                  channelTitle={findChannelName(
                                    channels,
                                    selectedChannel,
                                  )}
                                  flatAttributes={flatAttributes}
                                />
                              </Accordion.Body>
                            </Accordion.Item>
                          )}
                        </Accordion>
                      </Wrapper>
                    )}
                  </>
                );
              })}
        </section>
      </div>
      {!loading && attributeChanged && (
        <WithPermission permissions={["PS_UpdatePimProduct"]}>
          <Wrapper className="sticky bottom-0 z-10 shadow-card">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                type="button"
                variant="primary"
                onClick={
                  selectedChannel === "CH000D"
                    ? onSubmitDefaultValue
                    : onSubmitHandler
                }
                // disabled={!(isChanged || isChangedAttribute)}
                disabled={!attributeChanged}
                loading={submitLoading}
              >
                <Text>productManagement.products.Details.saveAttributes</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      )}
      <CopyDefaultValuesDrawer
        isOpen={isOpenCopy}
        toggle={toggleCopy}
        getProductAttributes={getProductAttributes}
        languages={items}
      />
    </>
  );
};
export default AttributesTab;
