import {
  AddressViewer,
  Breadcrumb,
  Form,
  Icon,
  LazyImage,
  Skeleton,
  Tabs,
  Text,
  Wrapper,
  Badge,
} from "components";
import { useDataState } from "hooks";
import { variantType } from "types";
import {
  Fragment,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import { ServiceApi, URLS } from "services";
import { ObjectUtils } from "utils";

//... tabs
import AccountInfo from "./tabs/account-info";
import CommunicationChannels from "./tabs/communication_channels";
import General from "./tabs/general";
import GeneralAsCompany from "./tabs/general-as-company";
import Address from "./tabs/location";
import { useSearchParams } from "react-router-dom";

export const CustomerContext = createContext<any>({});
const approvalStatus = [
  {
    text: "customers.customerList.none",
    variant: "secondary",
  },
  {
    text: "customers.customerList.pending",
    variant: "warning",
  },
  {
    text: "customers.customerList.approved",
    variant: "success",
  },
  {
    text: "customers.customerList.suspended",
    variant: "danger",
  },
];
export default function Details() {
  const [searchParams] = useSearchParams();
  const controller = new AbortController();
  const { customerId } = useParams();
  const type = searchParams.get("type");
  const [loading, setLoading] = useState(true);
  const [headerData, setHeaderData] = useState<any>({});
  const [data, setData, setBaseData] = useDataState<any>({});
  const [tabs, setTabs] = useState([
    {
      label: "customers.customerList.general",
      component: type === "0" ? General : GeneralAsCompany,
    },
    {
      label: "customers.customerList.address",
      component: Address,
    },
    {
      label: "customers.customerList.communicationChannels",
      component: CommunicationChannels,
    },
  ]);

  const getData = () => {
    const requestUrl =
      type === "0"
        ? URLS.DETAILS_CUSTOMERS_URL
        : URLS.DETAILS_CUSTOMERS_URL_AS_COMPANY;

    const url = requestUrl(customerId);
    ServiceApi.get(url, { signal: controller.signal })
      .then(res => {
        if (res?.data) {
          let result = JSON.parse(JSON.stringify(res?.data));
          if (result?.customerAccountId) {
            setTabs(p => [
              ...tabs,
              {
                label: "customers.customerList.accountInfo",
                component: AccountInfo,
              },
            ]);
          }
          setBaseData(result);
          setHeaderData(result);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []);
  //UI
  return (
    <Tabs activeKey={tabs[0].label}>
      <Form className="relative space-y-6">
        <Breadcrumb />
        <Wrapper>
          <Wrapper.Body className="flex flex-col items-start gap-5 lg:flex-row lg:justify-between">
            <div className="h-[114px] w-[114px]">
              <LazyImage
                placeholder={
                  data?.customerType === 0 || loading ? "user" : "company"
                }
                isDynamic
                src={data?.avatar?.thumbnailUrl}
                alt={data?.cimNumber}
                className="aspect-image h-full w-full cursor-pointer lg:aspect-auto [&_img]:h-full [&_img]:w-full [&_img]:object-cover"
              />
            </div>

            <div className="space-y-4 lg:flex-1">
              {loading ? (
                <Fragment>
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                  <Skeleton.Input />
                </Fragment>
              ) : (
                <Fragment>
                  <div className="flex flex-col space-y-3">
                    <div className="flex items-center justify-between">
                      <h5 className="text-heading-5 font-semibold text-gray-800">
                        {`${headerData?.firstName} ${headerData?.lastName}`}
                      </h5>
                    </div>
                    {
                      <div className="flex items-center gap-2">
                        <div>
                          <Icon
                            className="font-black text-gray-500"
                            size="1x"
                            icon="user-tag"
                          />
                        </div>
                        <span className="text-body-2 font-medium text-gray-600">
                          <Text>customers.customerList.cimNumber</Text>:
                        </span>
                        <span className="text-body-2 font-medium text-gray-500">
                          {` ${headerData?.cimNumber}`}
                        </span>
                      </div>
                    }

                    {type === "0" && (
                      <div className="flex items-center gap-2">
                        <div>
                          <Icon
                            className="font-black text-gray-500"
                            size="1x"
                            icon="mobile"
                          />
                        </div>
                        <div>
                          <span className="text-body-2 font-medium  text-gray-500">
                            {`${headerData?.mobileNumber?.countryCode} ${headerData?.mobileNumber?.number}`}
                          </span>
                        </div>
                      </div>
                    )}
                    {type === "1" && (
                      <>
                        <div className="flex items-center gap-2">
                          <div>
                            <Icon
                              className="font-black text-gray-500"
                              size="1x"
                              icon="hashtag"
                            />
                          </div>
                          <span className="text-body-2 font-medium text-gray-600">
                            <Text>customers.customerList.TaxID</Text>:
                          </span>
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              {headerData?.taxId
                                ? `${headerData?.taxId}`
                                : "--"}
                            </span>
                          </div>
                        </div>
                        <div className="flex items-center gap-2">
                          <div>
                            <Icon
                              className="font-black text-gray-500"
                              size="1x"
                              icon="clipboard"
                            />
                          </div>
                          <span className="text-body-2 font-medium text-gray-600">
                            <Text>
                              customers.customerList.registrationNumber
                            </Text>
                            :
                          </span>
                          <div>
                            <span className="text-body-2 font-medium  text-gray-500">
                              {headerData?.companyRegistrationNumber
                                ? `${headerData?.companyRegistrationNumber}`
                                : "--"}
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                    <AddressViewer address={headerData.address} />
                  </div>
                </Fragment>
              )}
            </div>
            <div className="flex flex-col items-end justify-end gap-4 ">
              {type === "1" && (
                <>
                  <Badge
                    variant={
                      approvalStatus[headerData?.approvalStatus]
                        ?.variant as variantType
                    }
                    className="w-fit"
                  >
                    <Text>
                      {approvalStatus[headerData?.approvalStatus]?.text}
                    </Text>
                  </Badge>
                  {headerData?.phoneNumber && (
                    <div>
                      <Icon
                        className="mr-2 font-black text-gray-500"
                        size="1x"
                        icon="phone"
                      />
                      <span className="text-body-2 font-medium text-gray-500">
                        {`${headerData?.phoneNumber?.countryCode} ${headerData?.phoneNumber?.number}`}
                      </span>
                    </div>
                  )}
                </>
              )}

              {data.customerAccountId && (
                <>
                  <span className="rounded-md border border-gray-300 p-2 text-sm">
                    <Icon icon="mobile" className="mr-2 text-danger" />
                    Mobile account
                  </span>
                </>
              )}
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="!py-0">
            <Tabs.ButtonGroup>
              {tabs.map(e => (
                <Tabs.Button key={e.label} eventKey={e.label}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        {loading && (
          <Wrapper>
            <Wrapper.Body className="grid grid-cols-1 gap-4 lg:grid-cols-2">
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
              <Skeleton.Input />
            </Wrapper.Body>
          </Wrapper>
        )}
        <div>
          {tabs.map(e => (
            <Tabs.Item key={e.label} eventKey={e.label}>
              <CustomerContext.Provider
                value={{
                  userData: data,
                  setUserData: setData,
                  loading: loading,
                  getData: getData,
                }}
              >
                {!ObjectUtils.isEmpty(data) && createElement(e.component)}
              </CustomerContext.Provider>
            </Tabs.Item>
          ))}
        </div>
      </Form>
    </Tabs>
  );
}
