export const dataStorage = {
  "cloudStorage": "Cloud-Speicher",
  "pulsAzureBlobStorage": "Puls Azure Blob-Speicher",
  "personalAzureBlobStorage": "Persönlicher Azure Blob-Speicher",
  "blobConfigId": "Blob-Speicher-ID",
  "key": "Schlüssel",
  "account": "Konto",
  "connectionString": "Verbindungszeichenfolge",
  "localStorage": "Lokaler Speicher",
  "eloFileManagement": "Elo-Dateiverwaltung",
  "url": "URL",
  "username": "Benutzername",
  "password": "Passwort",
  "cloudStorageUpdateMessage": "Cloud-Speicher erfolgreich aktualisiert.",
  "localStorageUpdateMessage": "Lokaler Speicher erfolgreich aktualisiert.",
  "cloudStorageRemoveErrorMessage": "Löschen vor Erstellung nicht möglich.",
  "localStorageRemoveErrorMessage": "Löschen vor Erstellung nicht möglich.",
  "pimProduct": "PIM-Produkt",
  "customerProfile": "Kundenprofil",
  "offerDocs": "Angebotsdokumente",
  "SellOfProduct": "Produktabverkauf",
  "Company": "Firma",
  "User": "Benutzer",
  "Applications": "Anwendungen",
  "generalBlob": "Allgemeiner Blob",
  "personalBlob": "Persönlicher Blob",
  "personalElo": "Persönliches Elo",
  "none": "Keine",
  "storage": "Speicher",
  "access": "Zugang",
  "public": "Öffentlich",
  "customer": "Kunde",
  "thirdParty": "3rd Party Applikation",
  "dashboard": "Dashboard"
};
