import { useNavigate } from "react-router";
import useSelector from "./useSelector";

export const useMenu = () => {
  const navigate = useNavigate();
  const permissions = useSelector(s => s.permissions);

  const PRICE_ENGINE_PRICETEMPLATE_PERMISSION = [
    "PE_GetPurchasePriceCalculation",
    "PE_GetSalePriceCalculation",
  ].some(r => permissions.includes(r));

  const PRICE_ENGINE_ROOT_PERMISSION =
    [
      "PE_GetCalculationUnit",
      "PE_GetCalculationUnitCustomization",
      "PE_GetSalePriceSimulation",
    ].some(r => permissions.includes(r)) ||
    PRICE_ENGINE_PRICETEMPLATE_PERMISSION;

  const MASTERDATA_ROOT_PERMISSIONS = [
    "PS_GetAttributeTemplate",
    "PS_GetAttributeGroupTemplate",
    "PS_GetPimProductFamily",
    "PS_GetProductCategory",
    "PS_GetPimProductGroup",
    "PS_GetMeasurementFamily",
    "PS_GetSupplier",
    "PS_GetBrand",
    "PS_GetShippingMethod",
  ].some(r => permissions.includes(r));

  const PRODUCTS_ROOT_PERMISSIONS = ["PS_GetPimProduct"].some(r =>
    permissions.includes(r),
  );

  const PRODUCT_MANAGMENT_ROOT_PERMISSONS =
    PRODUCTS_ROOT_PERMISSIONS || MASTERDATA_ROOT_PERMISSIONS;

  const SELLOFFSALES_PERMISSIONS = [
    "SC_GetSellOffProduct",
    "SC_GetSellPhaseTemplate",
    "SC_GetCheckListItem",
    "PS_GetSellOffCommission",
    "SC_GetPreparationPhase",
  ].some(r => permissions.includes(r));

  const SALESAREA_PERMISSIONS = ["PS_GetLocation"].some(r =>
    permissions.includes(r),
  );

  const SALESCHANNEL_PERMISSIONS = ["SC_GetSaleChannel"].some(r =>
    permissions.includes(r),
  );

  const SALESMANAGMENT_PERMISSIONS =
    SALESCHANNEL_PERMISSIONS ||
    SALESAREA_PERMISSIONS ||
    SELLOFFSALES_PERMISSIONS;

  const MOBILEAPP_PERMISSIONS = ["PS_GetPosition", "PS_GetInfoPage"].some(r =>
    permissions.includes(r),
  );

  const APPLICATION_PERMISSIONS = MOBILEAPP_PERMISSIONS;

  const THIRDPARTY_PERMISSIONS = [
    "CMS_GetCrmConfig",
    "SC_GetSaleServiceErpInventoryConfig",
    "AsS_GetBlobConfig",
    "PS_GetIwofurnConfiguration",
  ].some(r => permissions.includes(r));

  const COMPANY_PERMISSIONS = [
    "AS_GetMyCompany",
    "AS_GetStore",
    "AS_GetEmployee",
    "AS_GetLegalEntity",
  ].some(r => permissions.includes(r));

  const CUSTOMERS_PERMISSIONS = ["AS_GetCustomer"].some(r =>
    permissions.includes(r),
  );

  const GLOBALSETTING_PERMISSIONS = [
    "AS_GetApiKey",
    "AS_GetCompanySSO",
    "AsS_GetBlobConfig",
    "SC_GetSaleServiceDocumentTemplate",
  ].some(r => permissions.includes(r));

  const USERMANAGMENT_PERMISSIONS = [
    "AS_GetUser",
    "AS_GetUserGroup",
    "AS_GetRole",
  ].some(r => permissions.includes(r));

  const ADDRESS_CONFIGURATION_PERMISSIONS = ["AS_GetCountry"].some(r =>
    permissions.includes(r),
  );

  const CONFIGURATION_PERMISSIONS =
    GLOBALSETTING_PERMISSIONS ||
    USERMANAGMENT_PERMISSIONS ||
    ADDRESS_CONFIGURATION_PERMISSIONS;

  //MENU LIST

  const menu = [
    //dashboard
    {
      label: "menu.dashboard",
      path: "/ ",
      icon: "gauge",
      permission: "withoutPermission",
      command: () => {
        navigate("/");
      },
    },
    //productManagement
    {
      label: "menu.productManagement.root",
      path: "productManagement",
      icon: "box",
      permission: PRODUCT_MANAGMENT_ROOT_PERMISSONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.productManagement.masterData.root",
          path: "master-data",
          icon: "grip-dots",
          permission: MASTERDATA_ROOT_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.productManagement.masterData.Attributes",
              icon: "rectangle-list",
              path: "attributes",
              pathname: "/productManagement/master-data/attributes",
              command: () => {
                navigate("/productManagement/master-data/attributes");
              },
              permission: "PS_GetAttributeTemplate",
            },
            {
              label: "menu.productManagement.masterData.AttributeGroups",
              icon: "layer-group",
              path: "attribute-groupes",
              command: () => {
                navigate("/productManagement/master-data/attribute-groupes");
              },
              permission: "PS_GetAttributeGroupTemplate",
            },
            {
              label: "menu.productManagement.masterData.ProductFamilies",
              icon: "diagram-project",
              path: "product-families",
              command: () => {
                navigate("/productManagement/master-data/product-families");
              },
              permission: "PS_GetPimProductFamily",
            },
            {
              label: "menu.productManagement.masterData.ProductCategories",
              icon: "list-tree",
              path: "product-categories",
              command: () => {
                navigate("/productManagement/master-data/product-categories");
              },
              permission: "PS_GetProductCategory",
            },
            {
              label: "menu.productManagement.masterData.ProductGroup",
              icon: "rectangle-list",
              path: "product-group",
              command: () => {
                navigate("/productManagement/master-data/product-group");
              },
              permission: "PS_GetPimProductGroup",
            },
            {
              label: "menu.productManagement.masterData.MeasurementUnits",
              icon: "ruler-combined",
              path: "measurement-units",
              command: () => {
                navigate("/productManagement/master-data/measurement-units");
              },
              permission: "PS_GetMeasurementFamily",
            },
            {
              label: "menu.productManagement.masterData.Suppliers",
              icon: "industry-windows",
              path: "suppliers",
              command: () => {
                navigate("/productManagement/master-data/suppliers");
              },
              permission: "PS_GetSupplier",
            },
            {
              label: "menu.productManagement.masterData.Brands",
              icon: "copyright",
              path: "brands",
              command: () => {
                navigate("/productManagement/master-data/brands");
              },
              permission: "PS_GetBrand",
            },
            {
              label: "menu.productManagement.masterData.ShippingMethods",
              icon: "copyright",
              path: "shipping",
              command: () => {
                navigate("/productManagement/master-data/shipping");
              },
              permission: "PS_GetShippingMethod",
            },
            {
              label: "menu.productManagement.masterData.ProductLabelTemplates",
              icon: "tags",
              path: "product-label-templates",
              command: () => {
                navigate(
                  "/productManagement/master-data/product-label-templates",
                );
              },
              permission: "SC_GetProductLabelTemplate",
            },
          ],
        },
        {
          label: "menu.productManagement.products.root",
          icon: "grip-dots",
          path: "products",
          permission: PRODUCTS_ROOT_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.productManagement.products.AllProducts",
              icon: "grid-2",
              path: "all-products",
              command: () => {
                navigate("/productManagement/products/all-products");
              },
              permission: "PS_GetPimProduct",
            },
            {
              label: "menu.productManagement.products.publishedProducts",
              icon: "grid-2",
              path: "published-products",
              permission: "withoutPermission",
              command: () => {
                navigate("/productManagement/products/published-products");
              },
            },
            {
              label: "menu.productManagement.products.checkList",
              icon: "circle-small",
              path: "check-list",
              permission: "PS_GetPimProductCheckList",
              command: () => {
                navigate("/productManagement/products/check-list");
              },
            },
          ],
        },
      ],
    },
    //salesManagement
    {
      label: "menu.salesManagement.root",
      icon: "cart-circle-check",
      path: "salesmanagment",
      permission: SALESMANAGMENT_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.salesManagement.sellOffSales.root",
          icon: "grip-dots",
          path: "sell-off-sales",
          permission: SELLOFFSALES_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.salesManagement.sellOffSales.sellOffProducts",
              icon: "box-open",
              path: "sell-off-products",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sell-off-products");
              },
              permission: "SC_GetSellOffProduct",
            },
            {
              label: "menu.salesManagement.sellOffSales.salesTemplates",
              icon: "timeline-arrow",
              path: "sales-templates",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sales-templates");
              },
              permission: "SC_GetSellPhaseTemplate",
            },
            {
              label: "menu.salesManagement.sellOffSales.salesChecklist",
              icon: "square-sliders-vertical",
              path: "sales-check-list",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/sales-check-list");
              },
              permission: "SC_GetCheckListItem",
            },
            // {
            //   label: "menu.salesManagement.sellOffSales.SellOffCommissions",
            //   icon: "file-invoice-dollar",
            //   path: "sell-off-commissions",
            //   command: () => {
            //     navigate("/salesmanagment/sell-off-sales/sell-off-commissions");
            //   },
            //   permission: "PS_GetSellOffCommission",
            // },
            {
              label: "menu.salesManagement.sellOffSales.preparationPhase",
              icon: "file-invoice-dollar",
              path: "preparation-phase",
              command: () => {
                navigate("/salesmanagment/sell-off-sales/preparation-phase");
              },
              permission: "SC_GetPreparationPhase",
            },
          ],
        },
        {
          label: "menu.salesManagement.kitchenSales.root",
          icon: "grip-dots",
          path: "kitchen-sales",
          permission: "withoutPermission",
          items: [
            {
              label: "menu.salesManagement.kitchenSales.salesList",
              icon: "circle-small",
              path: "sales-list",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/sales-list");
              },
              permission: "withoutPermission",
            },
            {
              label: "menu.salesManagement.kitchenSales.salesReport",
              icon: "print-magnifying-glass",
              path: "sales-report",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/sales-report");
              },
              permission: "withoutPermission",
            },
            {
              label: "menu.salesManagement.kitchenSales.configuration",
              icon: "square-sliders-vertical",
              path: "configurations",
              command: () => {
                navigate("/salesmanagment/kitchen-sales/configurations");
              },
              permission: "withoutPermission",
            },
          ],
        },
        {
          label: "menu.salesManagement.salesArea.root",
          icon: "grip-dots",
          path: "sales-area",
          permission: SALESAREA_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.salesManagement.salesArea.locations",
              icon: "circle-small",
              path: "locations",
              command: () => {
                navigate("/salesmanagment/sales-area/locations");
              },
              permission: "PS_GetLocation",
            },
          ],
        },
        {
          label: "menu.salesManagement.salesChannels.root",
          icon: "circle-small",
          path: "sales-channels",
          command: () => {
            navigate("/salesmanagment/sales-channels");
          },
          permission: "SC_GetSaleChannel",
        },
        {
          label: "menu.salesManagement.commissionReports.root",
          icon: "circle-small",
          path: "commission-reports",
          command: () => {
            navigate("/salesmanagment/commission-reports");
          },
          permission: "withoutPermission",
        },
      ],
    },
    //applications
    {
      label: "menu.applications.root",
      icon: "laptop-mobile",
      path: "applications",
      permission: APPLICATION_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.applications.mobileApp.root",
          icon: "grip-dots",
          path: "mobile-app",
          permission: MOBILEAPP_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.applications.mobileApp.positions",
              icon: "chart-tree-map",
              path: "positions",
              command: () => {
                navigate("/applications/mobile-app/positions");
              },
              permission: "PS_GetPosition",
            },
            {
              label: "menu.applications.mobileApp.infoPages",
              icon: "file-word",
              path: "info-pages",
              command: () => {
                navigate("/applications/mobile-app/info-pages");
              },
              permission: "PS_GetInfoPage",
            },
          ],
        },
      ],
    },
    //THIRD PARTY
    {
      label: "menu.thirdParty.root",
      icon: "gear",
      path: "thirdParty",
      permission: THIRDPARTY_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.thirdParty.crmConfiguration",
          icon: "square-sliders-vertical",
          path: "crm-configuration",
          command: () => {
            navigate("/thirdParty/crm-configuration");
          },
          permission: "CMS_GetCrmConfig",
        },
        {
          label: "menu.thirdParty.erpConfiguration",
          icon: "square-sliders-vertical",
          path: "erp-configuration",
          command: () => {
            navigate("/thirdParty/erp-configuration");
          },
          permission: "SC_GetSaleServiceErpInventoryConfig",
        },
        {
          label: "menu.thirdParty.nopCommerce",
          icon: "square-sliders-vertical",
          path: "nop-commerce",
          command: () => {
            navigate("/thirdParty/nop-commerce");
          },
          permission: "AsS_GetBlobConfig",
        },
        {
          label: "menu.thirdParty.iwofurn",
          icon: "square-sliders-vertical",
          path: "iwofurn",
          command: () => {
            navigate("/thirdParty/iwofurn");
          },
          permission: "PS_GetIwofurnConfiguration",
        },
      ],
    },
    //company
    {
      label: "menu.company.root",
      icon: "buildings",
      path: "company",
      permission: COMPANY_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.company.profile",
          icon: "memo-pad",
          path: "profile",
          command: () => {
            navigate("/company/profile");
          },
          permission: "AS_GetMyCompany",
        },
        {
          label: "menu.company.stores",
          icon: "store",
          path: "stores",
          command: () => {
            navigate("/company/stores");
          },
          permission: "AS_GetStore",
        },
        {
          label: "menu.company.employees",
          icon: "building-user",
          path: "employees",
          command: () => {
            navigate("/company/employees");
          },
          permission: "AS_GetEmployee",
        },
        {
          label: "menu.company.legalEntities",
          icon: "gavel",
          path: "legal-entities",
          command: () => {
            navigate("/company/legal-entities");
          },
          permission: "AS_GetLegalEntity",
        },
      ],
    },
    // customers
    {
      label: "menu.customers.root",
      icon: "users-viewfinder",
      path: "customers",
      permission: CUSTOMERS_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.customers.customerList",
          icon: "address-card",
          path: "customer-list",
          command: () => {
            navigate("/customers/customer-list");
          },
          permission: "AS_GetCustomer",
        },
      ],
    },
    //configurations
    {
      label: "menu.configurations.root",
      icon: "gear",
      path: "configurations",
      permission: CONFIGURATION_PERMISSIONS ? "withoutPermission" : "",
      items: [
        {
          label: "menu.configurations.globalSettings.root",
          icon: "grip-dots",
          path: "global-settings",
          permission: GLOBALSETTING_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.configurations.globalSettings.apiKeys",
              icon: "key",
              path: "api-keys",
              command: () => {
                navigate("/configurations/global-settings/api-keys");
              },
              permission: "AS_GetApiKey",
            },
            {
              label: "menu.configurations.globalSettings.ssoSettings",
              icon: "circle-small",
              path: "sso-settings",
              command: () => {
                navigate("/configurations/global-settings/sso-settings");
              },
              permission: "AS_GetCompanySSO",
            },
            {
              label: "menu.configurations.globalSettings.dataStorage",
              icon: "circle-small",
              path: "data-storage",
              command: () => {
                navigate("/configurations/global-settings/data-storage");
              },
              permission: "AsS_GetBlobConfig",
            },
            {
              label: "menu.configurations.globalSettings.templates",
              icon: "circle-small",
              path: "templates",
              command: () => {
                navigate("/configurations/global-settings/templates");
              },
              permission: "SC_GetSaleServiceDocumentTemplate",
            },
          ],
        },
        {
          label: "menu.configurations.userManagement.root",
          icon: "grip-dots",
          path: "user-managment",
          permission: USERMANAGMENT_PERMISSIONS ? "withoutPermission" : "",
          items: [
            {
              label: "menu.configurations.userManagement.usersList",
              icon: "users",
              path: "users-list",
              command: () => {
                navigate("/configurations/user-managment/users-list");
              },
              permission: "AS_GetUser",
            },
            {
              label: "menu.configurations.userManagement.userGroups",
              icon: "screen-users",
              path: "user-groups",
              command: () => {
                navigate("/configurations/user-managment/user-groups");
              },
              permission: "AS_GetUserGroup",
            },
            {
              label: "menu.configurations.userManagement.roles",
              icon: "circle-small",
              path: "roles",
              command: () => {
                navigate("/configurations/user-managment/roles");
              },
              permission: "AS_GetRole",
            },
          ],
        },
        {
          label: "menu.configurations.addressConfiguration.root",
          icon: "map-location",
          path: "address-configuration",
          permission: ADDRESS_CONFIGURATION_PERMISSIONS
            ? "withoutPermission"
            : "",
          items: [
            {
              label: "menu.configurations.addressConfiguration.countries",
              icon: "flag",
              path: "countries",
              command: () => {
                navigate("/configurations/address-configuration/countries");
              },
              permission: "AS_GetCountry",
            },
          ],
        },
      ],
    },
    //price engine
    {
      label: "menu.priceEngine.root",
      icon: "gear",
      path: "price-engine",
      permission: PRICE_ENGINE_ROOT_PERMISSION ? "withoutPermission" : "",
      items: [
        {
          label: "menu.priceEngine.priceTemplates.root",
          icon: "grip-dots",
          path: "price-templates",
          permission: PRICE_ENGINE_PRICETEMPLATE_PERMISSION
            ? "withoutPermission"
            : "",
          items: [
            {
              label: "menu.priceEngine.priceTemplates.purchasePrice",
              icon: "circle-small",
              path: "purchase-price",
              permission: "PE_GetPurchasePriceCalculation",
              command: () => {
                navigate("/price-engine/price-templates/purchase-price");
              },
            },
            {
              label: "menu.priceEngine.priceTemplates.salesPrice",
              icon: "circle-small",
              path: "sales-price",
              permission: "PE_GetSalePriceCalculation",
              command: () => {
                navigate("/price-engine/price-templates/sales-price");
              },
            },
          ],
        },
        {
          label: "menu.priceEngine.calculationUnites.root",
          icon: "grip-dots",
          path: "calculation-units",
          permission: "PE_GetCalculationUnit",
          command: () => {
            navigate("/price-engine/calculation-units");
          },
        },
        {
          label: "menu.priceEngine.calculationLevelManagements.root",
          icon: "circle-small",
          path: "calculation-level",
          permission: "PE_GetCalculationUnitCustomization",
          command: () => {
            navigate("/price-engine/calculation-level");
          },
        },
        {
          label: "menu.priceEngine.priceSimulator.root",
          icon: "circle-small",
          path: "price-simulator",
          permission: "PE_GetSalePriceSimulation",
          command: () => {
            navigate("/price-engine/price-simulator");
          },
        },
      ],
    },
    //channelMessage
    {
      label: "menu.channelMessages.root",
      icon: "messages",
      path: "channel-messages",
      command: () => {
        navigate("/channel-messages");
      },
      permission: "CMS_GetChannelMessage",
    },
    //Sales Channel Message
    {
      label: "menu.salesChannelMessages.root",
      icon: "messages",
      path: "sales-channel-messages",
      command: () => {
        navigate("/sales-channel-messages");
      },
      permission: "SC_GetSaleChannelMessage",
    },
  ];
  return { menu };
};
