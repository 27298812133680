import {NotificationManager} from "common/notifications";
import {
  Breadcrumb,
  Button,
  Footer,
  Form,
  InputGroup,
  Skeleton,
  Text,
  WithPermission,
  Wrapper,
} from "components";
import {rules} from "constant";
import {withPermission} from "hoc";
import {useDataState, usePermission} from "hooks";
import {useEffect, useState} from "react";
import {ServiceApi, URLS} from "services";

function SsoSettings() {
  const controller = new AbortController();
  const updatePermission = usePermission("AS_UpdateCompanySSO");
  const [loadingButton, setLoadingButton] = useState(false);
  const [dataFirst, setDataFirst] = useState(false);
  const [data, setData, setBaseData, isChanged] = useDataState<any>({});
  const [loadingPage, setLoadingPage] = useState(true);

  const submit = () => {
    setLoadingButton(true);
    const url = URLS.EDIT_COMPANY_SSO;
    if (dataFirst) {
      ServiceApi.put(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.edit-msg",
            "global.toast.edit-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    } else {
      ServiceApi.post(url, data)
        .then(() => {
          NotificationManager.success(
            "global.toast.add-msg",
            "global.toast.add-title",
          );
        })
        .finally(() => {
          setLoadingButton(false);
          getData();
        });
    }
  };

  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setLoadingPage(true);
    await ServiceApi.get(URLS.GET_COMPANY_SSO, {signal: controller.signal})
      .then(res => {
        setBaseData(res?.data);
        if (res?.data.length === 0) {
          setDataFirst(false);
        } else {
          setDataFirst(true);
        }
      })
      .catch(() => {
        setBaseData([]);
      })
      .finally(() => {
        setLoadingPage(false);
      });
  };

  return (
    <Form onSubmit={submit} className="relative space-y-6">
      <h3 className="mr-auto text-heading-3">
        <Breadcrumb />
      </h3>
      <Wrapper>
        <Wrapper.Body className="grid grid-cols-1 gap-8 lg:grid-cols-2 lg:gap-8">
          <div className="space-y-8">
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.title"
                value={data.title}
                setValue={title => setData((p: any) => ({...p, title}))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.clientId"
                value={data.clientId}
                setValue={clientId => setData((p: any) => ({...p, clientId}))}
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.clientSecret"
                value={data.clientSecret}
                setValue={clientSecret =>
                  setData((p: any) => ({...p, clientSecret}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
            {loadingPage ? (
              <Skeleton.Input />
            ) : (
              <InputGroup
                label="configurations.globalSettings.ssoSettings.appTenantId"
                value={data.appTenantId}
                setValue={appTenantId =>
                  setData((p: any) => ({...p, appTenantId}))
                }
                rules={rules.required}
                disabled={!updatePermission}
              />
            )}
          </div>
        </Wrapper.Body>
      </Wrapper>
      <WithPermission permissions={["AS_UpdateCompanySSO"]}>
        <Footer show={!isChanged}>
          <Button type="button" onClick={getData} variant="white">
            <Text>global.buttons.discard</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            disabled={!isChanged}
            loading={loadingButton}
          >
            <Text>global.buttons.saveChanges</Text>
          </Button>
        </Footer>
      </WithPermission>
    </Form>
  );
}
export default withPermission(SsoSettings, ["AS_GetCompanySSO"]);
