import {
  Badge,
  Breadcrumb,
  Button,
  Icon,
  NoItems,
  Pagination,
  SearchBox,
  Skeleton,
  Table,
  Text,
} from "components";
import { config } from "constant";
import { useToggleState, usePermission } from "hooks";
import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ServiceApi } from "services";
import { infoPageType } from "../type";
import AddInfoPage from "./AddInfoPage";
import { withPermission } from "hoc";

type dataType = {
  items: infoPageType[];
  pageNumber: number;
  pageSize: number;
  totalItems: number;
  totalPages: number;
};
function InfoPageList() {
  const addPermissions = usePermission("PS_CreateInfoPage");
  const controller = new AbortController();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({} as dataType);
  const [searchParams] = useSearchParams();
  //original filter data for reset
  const originalFilterData: any = {
    pageNumber: 1,
    pageSize: config.pageSize,
    keyword: "",
  };

  const [params, setParams] = useSearchParams();

  const handleSetParams = (key: string) => {
    return (value: any) => {
      setParams(prev => {
        value !== null && value !== ""
          ? key === "pageNumber" && value === 1
            ? prev.delete(key)
            : prev.set(key, value)
          : prev.delete(key);
        key !== "pageNumber" && prev.delete("pageNumber");
        return prev;
      });
    };
  };
  const [isAddPageOpen, toggleAddPage] = useToggleState(false);
  const getData = () => {
    let params: any = originalFilterData;
    searchParams?.forEach((val, keyObj) => {
      params[keyObj] = val;
    });
    setLoading(true);
    const url = "/productservice/api/infopages";
    const config = { params };
    ServiceApi.get(url, { ...config, signal: controller.signal })
      .then(({ data }) => {
        setData(data);
      })
      .catch(() => {
        setData({} as dataType);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getData();
    return () => {
      controller.abort();
    };
  }, [params]);
  return (
    <section className="space-y-4">
      <div className="flex items-center">
        <div className="flex-1">
          <Breadcrumb />
        </div>
        {addPermissions && (
          <Button type="button" variant="primary" onClick={toggleAddPage}>
            <Icon icon="plus" /> <Text>Add page</Text>
          </Button>
        )}
      </div>
      <SearchBox
        value={params?.get("keyword") || ""}
        onSubmit={handleSetParams("keyword")}
        totalItems={data.totalItems}
        disabled={loading}
        onReload={getData}
      />
      {loading ? (
        <div className="space-y-4">
          <Skeleton.List />
          <Skeleton.List />
          <Skeleton.List />
        </div>
      ) : data && Object.keys(data).length !== 0 && data.items.length !== 0 ? (
        <>
          <Table>
            {data.items?.map(e => (
              <tr key={e.id}>
                <td className="space-y-2">
                  <h6 className="text-gray-800">
                    <Link to={e.id}>{e.title}</Link>
                  </h6>
                  <p className="text-body-2 text-gray-500">{e.code}</p>
                </td>
                <td>
                  <p className="text-body-1 max-w-sm text-gray-600">
                    {e.description}
                  </p>
                </td>
                <td>
                  {e.isActive ? (
                    <Badge variant="success">
                      <Text>applications.infoPage.inactiveStatus</Text>
                    </Badge>
                  ) : (
                    <Badge variant="danger">
                      <Text>applications.infoPage.layoutDetails</Text>
                    </Badge>
                  )}
                </td>
                <td>
                  <Button
                    as={Link}
                    to={e.id}
                    type="button"
                    variant="light"
                    size="sm"
                  >
                    <Text>applications.infoPage.infoPageDetailsButton</Text>
                  </Button>
                </td>
              </tr>
            ))}
          </Table>
          <Pagination
            pageNumber={
              params?.get("pageNumber")
                ? parseInt(params?.get("pageNumber") as string)
                : 1
            }
            pageSize={
              params?.get("pageSize")
                ? parseInt(params?.get("pageSize") as string)
                : config.pageSize
            }
            totalItems={data.totalItems}
            totalPages={data.totalPages}
            setActivePage={value =>
              setParams(p => ({ ...p, pageNumber: +value }))
            }
            onPageSizeChange={(value: any) =>
              setParams(p => ({ ...p, pageSize: +value }))
            }
          />
        </>
      ) : (
        <div className="flex w-full flex-col items-center space-y-4">
          <NoItems />
        </div>
      )}

      <AddInfoPage isOpen={isAddPageOpen} toggle={toggleAddPage} />
    </section>
  );
}

export default withPermission(InfoPageList, ["PS_GetInfoPage"]);
