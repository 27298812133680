import {Dropdown, Icon, Text} from "components";
import de from "date-fns/locale/de";
import {FC, memo, useEffect, useMemo, useRef, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {useTranslation} from "react-i18next";
import InputMask from "react-input-mask";
import {twMerge} from "tailwind-merge";
import {useTranslate} from "../../hooks";
import {rulesType} from "../../types";
import "./react-datepicker.scss";

//@ts-ignore
registerLocale("de", de);

interface Props {
  [x: string]: any;
  label?: any;
  hint?: any;
  flag?: string;
  actions?: any;
  rules?: rulesType;
  dense?: boolean;
}
//datePicker
const ReactDatePicker: FC<Props> = memo(
  ({
    label,
    flag,
    rules = [],
    hint,
    actions,
    onChange,
    placeholderText,
    minDate,
    maxDate,
    value,
    showTimeSelectOnly,
    disabled,
    showTimeSelect,
    dense,
  }) => {
    const datePickerRef = useRef<HTMLDivElement>(null);
    const {i18n} = useTranslation();
    const [selectedItem, setSelectedItem] = useState("");
    const currentLanguage = i18n.language;
    const [errorMessage, setErrorMessage] = useState("");
    const translate = useTranslate();

    const handleValue = useMemo(() => {
      const convertvalue = value;
      if (!convertvalue) return null;
      return new Date(convertvalue);
    }, [value]);

    useEffect(() => {
      datePickerRef.current?.classList.toggle("required", !!rules.length);
    }, [rules]);
    useEffect(() => {
      datePickerRef.current?.classList.toggle("error", !!errorMessage);
    }, [errorMessage]);

    useEffect(() => {
      const formControl = datePickerRef.current?.querySelector(".form-control");
      // @ts-ignore: Unreachable code error
      formControl.onValid = () => {
        return rules.every(rule => {
          const ruleValue = rule(value);
          const isValid = ruleValue === true;
          setErrorMessage(isValid ? "" : ruleValue);
          return isValid;
        });
      };
    }, [rules, value]);

    const dateFormat = showTimeSelectOnly
      ? "hh:mm"
      : currentLanguage === "de"
        ? showTimeSelect
          ? "dd.MM.yyyy hh:mm"
          : "dd.MM.yyyy"
        : showTimeSelect
          ? "yyyy-MM-dd hh:mm"
          : "yyyy-MM-dd";

    const mask = currentLanguage === "de" ? "99.99.9999" : "9999/99/99";
    const placeHolder = currentLanguage === "de" ? "dd.mm.yyyy" : "yyyy/mm/dd";

    function toIsoString(date: any) {
      const pad = function (num: any) {
        return (num < 10 ? "0" : "") + num;
      };

      const formattedDate =
        date.getFullYear() +
        "-" +
        pad(date.getMonth() + 1) +
        "-" +
        pad(date.getDate()) +
        "T" +
        pad(0) +
        ":" +
        pad(0) +
        ":" +
        pad(0) +
        ".000Z";

      return formattedDate;
    }
    return (
      <div ref={datePickerRef} className="input-group group w-full">
        {(label || flag || hint) && (
          <div className="mb-4 flex items-center justify-between space-x-4">
            {label && (
              <h6
                data-lang-map={label}
                className="h6 block truncate font-normal text-gray-800 after:text-danger group-[.input-group.required]:after:content-['*']"
              >
                <Text>{label}</Text>
              </h6>
            )}
            {(flag || hint) && (
              <div className="flex items-center gap-4">
                {hint && (
                  <span
                    className={`text-body-2 font-normal text-${hint?.color}`}
                  >
                    {hint?.label}
                  </span>
                )}
                <img className="block" src={flag} alt="" />
                {actions?.length > 0 && (
                  <Dropdown onSelect={setSelectedItem}>
                    <Dropdown.Toggle
                      as="button"
                      icon={true}
                      type="button"
                      className="text-body-base font-medium uppercase text-gray-600"
                    >
                      <Icon icon="ellipsis-vertical"></Icon>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="mt-2 min-w-fit p-2">
                      <ul className="w-max text-gray-700">
                        {actions?.map((e: any) => (
                          <li
                            key={e.id}
                            onClick={value => {
                              setSelectedItem(e.id);
                              e.onClick(value);
                            }}
                            className={`h6 px-4 py-2 font-medium first:mb-1 ${
                              e.id === selectedItem &&
                              "bg-primary-light text-primary "
                            } cursor-pointer rounded-lg text-body-base hover:bg-primary-light hover:text-primary`}
                          >
                            {e.title}
                          </li>
                        ))}
                      </ul>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
              </div>
            )}
          </div>
        )}
        <div
          className={twMerge(
            "input-box row relative w-full items-stretch rounded-md border border-gray-100 bg-gray-100 text-heading-5 font-normal text-gray-700 transition-colors focus-within:border-gray-500 group-[.input-group.error]:border-danger [&>*:first-child]:rounded-l-md [&>*:last-child]:rounded-r-md [&>*]:min-w-0 [&>textarea]:min-h-[14rem] [&>textarea]:py-2 [&>textarea]:leading-5",
            disabled && "cursor-not-allowed bg-gray-300",
          )}
        >
          <DatePicker
            dateFormat={dateFormat}
            locale={"de"}
            className={`form-control  w-full  disabled:cursor-not-allowed ${dense ? "h-9 text-sm" : "h-11"}`}
            selected={handleValue}
            minDate={minDate}
            maxDate={maxDate}
            disabled={disabled}
            isClearable
            onChange={date => {
              if (date) {
                const isoString = toIsoString(date);
                onChange && onChange(isoString);
              } else {
                onChange(date);
              }
            }}
            customInput={
              <InputMask mask={mask} maskChar={null} alwaysShowMask />
            }
            placeholderText={
              placeHolder
              // !!placeholderText ? translate(placeholderText) || "" : ""
            }
          />
        </div>
        {errorMessage && (
          <p className="mt-1 text-xs text-danger">
            <i className="bi bi-info-circle mr-1" />
            <Text>{errorMessage}</Text>
          </p>
        )}
      </div>
    );

    // return (
    //   <ReactDatePicker
    //     {...props}
    //     name={name}
    //     className={className}
    //     locale={currentLanguage}
    //     dateFormat={dateFormat}
    //     selected={value}
    //     onChange={onChange}
    //   />
    // );
  },
);

export default ReactDatePicker;
