export const errors = {
  error: "Fehler",
  required: "This field is required DE",

  "001": "ConfigNotFound",
  "002": "ConfigIsWrong",
  "003": "SyncError",
  "004": "the product in NOP not found",
  404: "Resource not found",
  100: "Kanal bereits zum Unternehmen hinzugefügt",

  1000: "Ortcode sollte eindeutig sein",
  1001: "Ungültiger Kanal",
  1003: "Markencode sollte im Unternehmen eindeutig sein",
  1004: "Ortcode sollte im Gebiet eindeutig sein",
  1006: "Attributgruppen-Template nicht gefunden",
  1007: "Marke konnte nicht gefunden werden",
  1008: "Marke existiert bereits",
  1009: "Checklistenpunkt konnte nicht gefunden werden",
  1010: "Checklistenpunkt-Beschriftung existiert bereits",
  1011: "Rechtsträger konnte nicht gefunden werden",
  1012: "Rechtsträgercode sollte im Unternehmen eindeutig sein",
  1013: "Messfamiliencode sollte eindeutig sein",
  1014: "Messfamilieneinheitencode sollte eindeutig sein",
  1015: "Messfamilie konnte nicht gefunden werden",
  1016: "PIM-Produktfamilie konnte nicht gefunden werden",
  1017: "Ort konnte nicht gefunden werden",
  1018: "PIM-Produktmedien konnten nicht gefunden werden",
  1019: "Lieferantenurlaub nicht gefunden",
  1020: "Lieblingsprodukt nicht gefunden",
  1021: "Nur Kanal von Nicht-kanalspezifischen Attributwerten kann Null sein",
  1022: "Kanalspezifische Attribute sollten eingebaute Kanäle verwenden",
  1023: "Nur Sprache von Nicht-sprachspezifischen Attributwerten kann Null sein",
  1024: "PIM-Produktmedienfelder dürfen keine doppelten Sprachen im selben Kanal haben",
  1025: "Medienfelder sollten entweder alle oder keine Kanalwerte haben",
  1026: "Medienfelder sollten entweder alle oder keine Sprachwerte haben",
  1027: "Erforderliche Elemente sollten einen Wert haben",
  1028: "Löschen des Checklistenpunkts ist möglich, wenn er nicht in der Vorbereitungsphase verwendet wird",
  1029: "Löschen des Checklistenpunkts ist möglich, wenn er nicht in der Verkaufsphase verwendet wird",
  1030: "Produktfamiliencode sollte im Unternehmen eindeutig sein",
  1031: "Produktfamilie existiert bereits",
  1032: "Zonencode sollte im Geschäft eindeutig sein",
  1033: "Geschäftscode sollte im Unternehmen eindeutig sein",
  1034: "Geschäft nicht gefunden",
  1035: "Produktfamilie nicht gefunden",
  1036: "Kanaleinstellung nicht gefunden",
  1037: "Kanal ist in dieser Kategorie nicht verfügbar",
  1038: "Unternehmen nicht gefunden",
  1039: "Unternehmenskanal nicht gefunden",
  1040: "Ort existiert bereits",
  1041: "Unternehmen existiert bereits",
  1042: "Ort nicht gefunden",

  2000: "Kanäle sollten in durchsuchbaren Attributkanälen eindeutig sein",
  2001: "Kanäle sollten in eingebauten Kanälen sein",
  2002: "Durchsuchbare Attributkanäle müssen existieren",
  2003: "Ausnahme-Kanäle für veröffentlichbare Attribute sollten eindeutig sein",
  2004: "Ausnahme-Kanäle für veröffentlichbare Attribute sollten existieren",
  2005: "Attributelement sollte nicht dupliziert werden",
  2006: "Attribut muss mindestens eine Übersetzung enthalten",
  2007: "Attributcode muss eindeutig sein",
  2008: "Standardwert des Attributelements muss in der Artikelliste-ID enthalten sein",
  2009: "Element muss im Attribut existieren",
  2010: "Attribut sollte nicht in Gruppe verwendet werden",
  2011: "Listentyp muss dieselbe Übersetzung im Elementübersetzen haben",
  2012: "Attributgruppencode muss eindeutig sein",
  2013: "Attributgruppe wird in PIM-Produktfamilie verwendet",
  2014: "Attribut kann in der Gruppe nicht wiederholt werden",
  2015: "Attribut muss in der Gruppe existieren",
  2016: "Attribut-Template-Reihenfolge sollte nicht kleiner als Null sein",
  2017: "Attributgruppenvorlagentyp darf nicht eingebaut sein",

  2100: "Attributvorlagentyp muss Liste sein",
  2101: "Attributvorlagenanbietertyp darf nicht eingebaut sein",

  3000: "Produktkategorie kann nicht gelöscht werden, wenn sie in PIM-Produkt verwendet wird",
  3001: "Produktkategorie kann nur in verfügbare Kanäle veröffentlicht werden",
  3002: "Produktkategorie kann nur in eingebaute Kanäle veröffentlicht werden",
  3003: "Produktkategoriecode muss eindeutig sein",
  3004: "Produktkategorie kann nicht ihr eigenes Elternteil sein",
  3005: "Elternteil der Wurzel-Produktkategorie kann nicht geändert werden",
  3006: "Die Produktkategorie und ihr Elternteil sollten in derselben Kategorie sein",
  3007: "Die Produktkategorie sollte keine Unter-Produktkategorie haben",
  3008: "Produktkategorie nicht gefunden",

  4000: "Lieferant nicht gefunden",
  4001: "Lieferanten-ILN muss eindeutig sein",
  4002: "Lieferant existiert bereits",
  4003: "Lieferant kann nicht gelöscht werden, wenn er in einem Ausverkaufsprodukt verwendet wird",
  4004: "Lieferant kann nur in eingebaute Kanäle veröffentlicht werden",
  4005: "Lieferantencode sollte im Unternehmen eindeutig sein",
  4006: "Lieferantenbankinformationen sind ungültig",
  4007: "Lieferanten ILN ist nicht valide",
  4010: "Rabatt nicht gefunden",
  4011: "Ungültiger Rabattbetrag",
  4012: "Ansprechpartner nicht gefunden",

  5000: "Akzeptierter Anzahlungbereich",
  5001: "Adresse darf nicht null sein",
  5003: "Wichtige Dokumente sollten unterschrieben sein",
  5004: "Angebot sollte Artikel mit Anzahlung enthalten",
  5005: "Anzahlung für Verkaufsangebot ist nicht festgelegt",

  6000: "Attribut nicht Gefunden",
  6001: "Konfigurierbares Programm Hat Kein Attribut",
  6002: "Programm nicht Gefunden",
  6003: "Programm-Attributcode sollte einzigartig sein",
  6004: "Info-Seite nicht gefunden",
  6005: "MediaId muss existieren, um Medien aus Lahoma-Bestellung zu entfernen",
  6006: "Bild Oder Dokument Kann Nicht Aus In Bearbeitung Befindlicher Lahoma-Bestellung Entfernt Werden",
  6007: "Dokument Muss Existieren Um Aus Lahoma-Bestellungen Entfernt Zu Werden",
  6008: "Mediarichtung Muss In Perspektivischen Medien Einzigartig Sein",
  6009: "Bestellung Veröffentlicht Lahoma Sollte Innerhalb Des Unternehmens Einzigartig Sein",
  6010: "Bild Oder Dokument Kann Nicht Aus Eingereichter Lahoma-Bestellung Entfernt Werden",
  6011: "Positionscode Muss Einzigartig Sein",
  6012: "Bannerposition-Komponenten Dürfen Nur Ein Element Haben",
  6013: "Komponente Existiert Nicht",
  6014: "Info-Seitenlayout Nicht Gefunden",
  6015: "Info-Seitencode Muss Einzigartig Sein",
  6016: "Kanaleinstellung Sollte Eine Aktive Einstellung Haben",
  6017: "Wert Ist Keine ItemId",
  6018: "Lahoma-Bestellung Nicht Gefunden",
  6019: "Position Nicht Gefunden",
  6020: "Programmcode Sollte Einzigartig Sein",
  6021: "Kein verfügbares Lieferantenprogramm gefunden",
  6022: "Lieferantenprogramm: Status wurde übernommen",
  6023: "Status des Liefernatenprogramms inkongruent",

  7000: "PIM-Produkt nicht gefunden",
  7001: "Attributgruppe sollte nicht im PIM-Produkt-Familienstammbaum verwendet werden",
  7002: "Attributgruppe sollte im PIM-Produkt-Familienstammbaum existieren",
  7003: "Blatt kann im PIM-Produkt-Familienstammbaum entfernt werden",
  7004: "PIM-Produkt-Familiencode sollte einzigartig sein",
  7005: "PIM-Produkt-Familie konnte nicht entfernt werden, wenn es in PIM-Produkt verwendet wird",
  7006: "PIM-Produkt-Standort konnte nicht wiederholt werden",
  7007: "PIM-Produkt-Medien könnten in eingebaute Kanäle veröffentlicht werden",
  7008: "Artikelnummer sollte einzigartig sein",
  7009: "Attributwerte dürfen nicht null oder leer sein",
  7010: "PIM-Produktattribute dürfen nicht null sein,,,niemad will eine Null sien, oder?",
  7011: "PIM-Produkt kann nur in eingebaute Kanäle veröffentlicht werden",
  7012: "Artikelnummer ist außerhalb des Bereichs",
  7013: "PIM-Produkt-Familie als Elternteil nicht gefunden",
  7014: "PIM-Produkt-Attribut ist erforderlich, nicht gültig",
  7015: "PIM-Produkt-Attribut ist pro Sprache nicht gültig",
  7016: "PIM-Produkt-Attribut ist pro Kanal nicht gültig",
  7018: "PIM-Produkt-Attribut ist nicht im PIM basierend auf PIM-Produkt-Familie nicht gültig",
  7019: "PIM-Produkt veröffentlicht erforderliches Attribut fehlt Wert",
  7020: "PIM-Produkt-Gruppencode ist bereits vergeben",
  7021: "PIM-Produktgruppe nicht gefunden",
  7022: "PIM-Produktkategorie kann im PIM-Produkt nicht dupliziert werden",
  7023: "PIM-Produktattribute sollten einzigartig sein",
  7024: "PIM-Produkthersteller ist nicht eingestellt",
  7025: "PIM-Produktherstellerprogramm ist nicht eingestellt",
  7026: "PIM-Produktgruppe ist nicht eingestellt",
  7027: "PIM-Produkt-Bundle-Element nicht gefunden",
  7028: "PIM-Produkttyp muss Bundle sein",
  7029: "PIM-Bundle-Produktlieferant ist nicht definiert",
  7030: "PIM-Bundle-Programm ist nicht definiert",
  7031: "PIM-Bundle enthält keine Produkte",
  7032: "Pim Product Availability Status Not Found DE",
  7033: "Pim Product Availability Status Is Default DE",
  7034: "Pim Product Availability Status Date Range Should Not Conflict DE",
  7035: "Pim Product Type Must Be Standard DE",

  8000: "Position nicht gefunden",
  8001: "Duplizieren dieser Angebotsversion ist nicht erlaubt",
  8002: "Angebotskundenadresse sollte in einem anderen Typ sein",
  8003: "Hinzufügen von Unterpositionen ist nicht möglich, wenn Position nicht gefunden",
  8004: "Nur ein aktives Angebot kann geklont werden",
  8005: "Nur die letzte Version des Angebots kann geklont werden",
  8006: "Nur ein akzeptiertes Angebot kann reaktiviert werden",
  8007: "Nur ein abgelehntes Angebot kann reaktiviert werden",
  8008: "Ablehnen des Angebots ist nur für ein aktives Angebot erlaubt",
  8009: "Entfernen des Dokuments ist nicht möglich, Dokument nicht gefunden",
  8010: "Des geht fei ned: Eine nicht existierende Position kann nicht entfernt werden",
  8011: "Entfernen von Unterpositionen ist nicht möglich, wenn die Position nicht gefunden wurde",
  8012: "Nicht existierende Unterposition kann nicht entfernt werden",
  8013: "Nicht existierendes Dokument kann nicht entfernt oder aktualisiert werden",
  8014: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8015: "Aktualisieren der Position ist nicht möglich, wenn die Position nicht gefunden wurde",
  8016: "Nur ein aktives Angebot kann aktualisiert werden",
  8017: "Die letzte Version des Angebots kann aktualisiert werden",
  8018: "Aktualisieren von Unterpositionen ist nicht möglich - Position nicht gefunden",
  8019: "Aktualisieren von Unterpositionen ist nicht möglich - Unterposition nicht gefunden",
  8020: "Nur eine aktive Bestellung kann storniert werden",
  8021: "Die Bestellung kann nur in integrierte Kanäle veröffentlicht werden",
  8022: "Veröffentlichen in ERP ist möglich, wenn die Bestelldokumente mit erforderlicher Signatur unterzeichnet sind",
  8023: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8024: "Aktive Bestellung kann aktualisiert werden",
  8025: "Angebot nicht gefunden",
  8026: "Dokument nicht gefunden",
  8027: "Bestellung nicht gefunden",
  8028: "Bestellung ist nicht möglich - Position Lieferanten-ID ist null",
  8029: "Bestellung ist nicht möglich - Unterposition Lieferanten-ID null ist",
  8030: "Angebotsposition nicht gefunden",
  8031: "Angebotsdokument nicht gefunden",
  8032: "Sieht ganz schön alt aus: Angebot ist abgelaufen",
  8033: "Kunde nicht gefunden",
  8034: "Ansprechpartner der Kundenfirma nicht gefunden",
  8035: "Kunde hat keine CIM-Nummer",
  8036: "Kunde nicht genehmigt",
  8037: "Bestellposition nicht gefunden",
  8038: "Angebotsposition ist im Inventar reserviert",
  8039: "Angebotsposition ist nicht im Inventar reserviert",

  9000: "Vorbereitungsphase nicht gefunden",
  9001: "Abverkaufsprodukt nicht gefunden",
  9002: "Verkaufsphasenvorlage nicht gefunden",
  9003: "Vorbereitungsphase bereits erstellt",
  9004: "Zeitplan des Abverkaufsprodukts nicht gefunden",
  9005: "Zeitplan des Abverkaufsprodukts existiert bereits",
  9006: "Checklistenpunkte nicht gefunden",
  9007: "Reihenfolge der Abverkaufsprodukt-Ressourcen sollte eindeutig sein",
  9008: "Phasenwechsel ist erst nach Ablauf der aktuellen Phase möglich",
  9009: "Phasenwechsel ist nur für aktive Abverkaufsprodukte möglich",
  9010: "Phasenwechsel ist nur möglich, wenn alle Checklistenpunkte der Verkaufsphase abgehakt sind",
  9011: "Phasenschluss ist nur nach Ablauf der letzten Phase möglich",
  9012: "Phasenschluss ist nur für aktive Abverkaufsprodukte möglich",
  9013: "Letzte Phase kann nicht geändert werden",
  9014: "Medien-ID muss existieren, um eine Ressource aus dem Abverkaufsprodukt zu entfernen",
  9015: "Die nächste Verkaufsphase sollte Checklistenpunkte enthalten",
  9016: "Nur die letzte Phase kann geschlossen werden",
  9017: "Die Vorbereitungsphase sollte Checklistenpunkte enthalten",
  9018: "Der tatsächliche Verkaufspreis sollte größer als null sein... aber echt!",
  9019: "Bild entfernen ist nicht möglich für geschlossenes Abverkaufsprodukt",
  9020: "Bild entfernen ist nicht möglich für verkauftes Abverkaufsprodukt",
  9021: "Ressourcen neu ordnen ist nicht möglich für geschlossenes Abverkaufsprodukt",
  9022: "Ressourcen neu ordnen ist nicht möglich für verkauftes Abverkaufsprodukt",
  9023: "Phasenstart ist nicht möglich ohne Produktfamilie",
  9024: "Phasenstart ist nicht möglich ohne Verkaufsphasenvorlage",
  9025: "Verkauft ist erst nach Startdatum und -zeit des Abverkaufsprodukts verfügbar",
  9026: "Verkauft ist nur für aktive Abverkaufsprodukte möglich",
  9027: "Phasenstart ist nur für Vorbereitungsabverkaufsprodukte möglich",
  9028: "Phasenstart ist nur möglich, wenn alle Checklistenpunkte der Vorbereitungsphase abgehakt sind",
  9029: "Aktualisierung ist nicht möglich für geschlossenes Abverkaufsprodukt",
  9030: "Aktualisierung ist nicht möglich für verkauftes Abverkaufsprodukt",
  9031: "Aktualisierung der Checklistenpunkte der Vorbereitungsphase ist nur für Vorbereitungsabverkaufsprodukte möglich",
  9032: "Aktualisierung der Checklistenpunkte der Verkaufsphase ist erst nach Ablauf der aktuellen Phase möglich",
  9033: "Aktualisierung der Checklistenpunkte der Verkaufsphase ist nur für aktive Abverkaufsprodukte möglich",
  9034: "Aktualisierung der Verkaufsphasenvorlage ist nicht möglich für aktive Abverkaufsprodukte",
  9035: "Rückzug eines verkauften Artikels ist nur für verkaufte Abverkaufsprodukte möglich",
  9036: "Code der Verkaufsphasenvorlage muss eindeutig sein",

  11000: "Provider-Produktimportverfolgung nicht gefunden",
  11001: "Provider-Produktimport nicht gefunden",
  11002: "Provider-Konfiguration nicht installiert",
  11003: "B2B-Provider-Konfiguration existiert bereits",
  11004: "Provider-Konfiguration nicht gefunden",

  12000: "Duplizierte Iwofurn-Konfiguration",
  12001: "Iwofurn-Konfiguration nicht gefunden",
  12002: "Store-ILN existiert nicht",
  12003: "Iwofurn-Konfigurations-URL ist nicht gesetzt",
  12004: "Iwofurn-Konfigurations-Benutzername ist nicht gesetzt",
  12005: "Iwofurn-Konfigurations-Passwort ist nicht gesetzt",
  12006: "IwofurnInternerTag nicht gefunden",
  12007: "Iwofurn-Bestellung hat keine Position",
  12008: "Iwofurn-API antwortet nicht",

  13000: "Produktkategorie kann keine doppelten Kanalinformationen haben",
  13001: "Produktkategorie kann keine doppelten Kanalausnahmen haben",
  13002: "Produktkategorie-Kanalcode muss in der Kanalausnahme existieren",
  13003: "Produktkategorie-Kanalinformationen müssen existieren",
  13004:
    "Produktkategorie-Sortierreihenfolge sollte nicht kleiner als Null sein",

  14000: "Versandmethode mit diesem Code existiert bereits",
  14001: "Versandmethode nicht gefunden",
  14002: "Versandmethode kann im Produkt nicht wiederholt werden",
  14003: "Versandmethode existiert nicht im Produkt",

  14100: "Dokumentvorlage nicht gefunden",
  14101: "PDF des Verkaufsangebotsdokuments nicht erstellt",
  14102: "Verkaufsangebotsdokument nicht gefunden",
  14103: "Verkaufsangebot wurde akzeptiert...schön, oder?",
  14104: "Verkaufsangebot ist nicht aktiv",

  14200: "Allgemeiner Rabatt nicht gefunden",
  14201: "Preisvorlage nicht gefunden",
  14202: "Preisvorlagen-Element nicht gefunden",
  14203: "Preisvorlagen-Rabatt nicht gefunden",
  14204: "Code des allgemeinen Rabatts existiert bereits",
  14205: "Code der Preisvorlage existiert bereits",
  14206: "Ungültige Preisvorlagenrabatte angefordert",
  14207: "Ungültiger allgemeiner Rabatt angefordert",

  14300: "ERP-Inventarkonfiguration nicht gefunden",
  14301: "Login beim ERP-Inventarservice Microsoft fehlgeschlagen",
  14302: "Login beim ERP-Inventarservice Dynamics fehlgeschlagen",
  14303:
    "Antwort des ERP-Inventarservice auf Produktinventarabfrage enthält fehlerhafte Anforderung",
  14304:
    "Produkt in der Antwort des ERP-Inventarservice auf Produktinventarabfrage nicht verfügbar",
  14305:
    "Nur Store mit Rechtsentity-Code kann im ERP-Inventarservice verwendet werden",
  14306:
    "Antwort des ERP-Inventarservice auf Produktreservierung im Inventar enthält fehlerhafte Anforderung",
  14307:
    "Keine Antwort des ERP-Inventarservice auf Produktreservierung im Inventar",
  14308:
    "Antwort des ERP-Inventarservice auf Entreservierung des Produkts aus dem Inventar enthält fehlerhafte Anforderung",
  14309:
    "Keine Antwort des ERP-Inventarservice auf Entreservierung des Produkts aus dem Inventar",
  14310: "Produktmenge im ERP-Inventarservice ist geringer als angefordert",
  14311:
    "Nur Produkte mit Artikelnummer können im ERP-Inventarservice verwendet werden",

  14400: "Vertriebsmitarbeiterinformationen nicht gefunden",

  15000: "Datei nicht gefunden",
  15001: "Excel-Datei ist beschädigt",
  15002: "Excel-Datei-Blatt nicht gefunden",
  15003: "Artikelnummer im falschen Format",
  15004: "PIM-Produktfamiliencode im falschen Format",
  15005: "PIM-Produktgruppencode im falschen Format",
  15006: "Lieferantenartikelnummer im falschen Format",
  15007: "Originalname im falschen Format",
  15008: "EAN-Nummer im falschen Format",
  15009: "Kurzbeschreibung im falschen Format",
  15010: "Lieferantencode im falschen Format",
  15011: "Lieferanten-ILN im falschen Format",
  15012: "Lieferantenprogramcode im falschen Format",
  15013: "Material im falschen Format",
  15014: "Farbe im falschen Format",
  15015: "Einkaufspreis im falschen Format",

  17000: "PublishedContentNotFound",
  17001: "SupplierIsNotPublished",
  17002: "SupplierProgramIsNotPublished",
  17003: "SupplierIsNotSet",
  17004: "SupplierProgramIsNotSet",
  17101: "Product label template uploaded file must not be null DE",

  A1000: "Firma nicht gefunden",
  A1001: "Kunde nicht gefunden... wo isser denn",
  A1002: "Benutzer nicht gefunden",
  A1003: "Mitarbeiter nicht gefunden",
  A1004: "Benutzergruppe nicht gefunden",
  A1005: "Rechtsträger nicht gefunden",
  A1006: "Geschäft nicht gefunden",
  A1007: "Mobilnummer ist bereits registriert",
  A1008: "Kundenkonto nicht gefunden",
  A1009: "Mobilnummer-Bestätigungscode ist nicht gültig",
  A1010: "Mobilnummer ist noch nicht bestätigt",
  A1011: "E-Mail-Adresse ist nicht bestätigt",
  A1012: "Passcode sollte gesetzt werden",
  A1013: "E-Mail-Adresse ist bereits registriert",
  A1014: "Passcode sollte gültig sein",
  A1015: "E-Mail-Adresse stimmt nicht überein",
  A1016: "Rechtsträger mit demselben Code existiert bereits",
  A1017: "Neuer Passcode stimmt nicht überein",
  A1018: "Ungültiger Passcode",
  A1019: "Nutzername stimmt nicht mit E-Mail-Adresse überein",
  A1020: "Benutzer ist bereits angemeldet",
  A1021: "Benutzer existiert bereits",
  A1022: "Gerätetoken ist nicht gültig",
  A1023: "E-Mail-Bestätigungscode ist abgelaufen",
  A1024: "E-Mail-Bestätigungscode ist nicht gültig",
  A1025: "Mobilnummer ist nicht bestätigt",
  A1026: "Kundenentfernung ist nicht möglich...das kann bitter sein ",
  A1027: "Kunde muss DSGVO akzeptieren",
  A1028: "Kunde muss eine Person sein",
  A1029: "Mobilnummer muss registriert sein",
  A1030: "Steuer-ID der Firma muss ausgefüllt sein",
  A1031: "Firmenregistrierungsnummer muss eindeutig sein",
  A1032: "Kunde muss eine Firma sein",
  A1033: "Firmenansprechpartner nicht gefunden",
  A1034: "Land nicht gefunden",
  A1043: "SsoConfigurationNotFound",
  A1044: "SsoConfigurationAlreadyExists",

  C9001: "ERP-Konfiguration nicht gefunden",
  C9002: "Nachricht an ERP senden fehlgeschlagen",
  C9003: "Kanalnachricht nicht gefunden",
  C9004: "Favorit nicht gefunden",
  C9005: "Favoriteneintrag sollte einzigartig sein",
  C9006: "Favoriteneintrag nicht gefunden",
  C9007: "Vorfall kann nicht erstellt werden",
  C9008: "Vorfall kann nicht aktualisiert werden",
  C9009: "Bestellung kann im CRM nicht eingefügt oder aktualisiert werden",
  C9101: "CRM-Store nicht gefunden",
  C9102: "CRM-Bestellung nicht gefunden",
  C9103: "CRM-Bestelldetail nicht gefunden",
  C9104: "CRM-Kunde nicht gefunden",
  C9105: "CRM-Mitarbeiter nicht gefunden",
  C9106: "Mitarbeiter kann im CRM nicht eingefügt oder aktualisiert werden",
  C9107: "SaleTools-PIM-Produkt nicht gefunden",
  C9108: "PIM-Produktattribute sollten einzigartig sein",
  C9109: "Provisionierungsgruppe der Bestellung kann nicht entfernt werden",
  C9110: "Provisionierungsgruppe der Bestellung kann nicht geändert werden",
  C9111: "Kunde kann im CRM nicht eingefügt oder aktualisiert werden",
  C9112: "Kontaktperson kann nicht deaktiviert werden",
  C9113: "CRM-Kundentyp nicht definiert",
  C9114: "Kontaktperson kann im CRM nicht eingefügt oder aktualisiert werden",
  C9115: "Es gibt keine Preisliste im CRM",
  C9116: "Es gibt keine Transaktionswährung im CRM",
  C9117: "Ausgewählte Währung im CRM nicht gefunden",
  C9118: "Preisliste kann im CRM nicht abgerufen werden",
  C9119: "Transaktionswährungsliste kann im CRM nicht abgerufen werden",
  C9120: "CRM-Kunden-CIM-Nummer ist leer",
  C9121: "CRM-Rechtsträgercode ist leer",
  C9122: "CRM-Mitarbeiternummer ist leer",
  C9123: "Kunde mit eingegebener CIM-Nummer im CRM nicht gefunden",
  C9124: "Store mit eingegebenem Rechtsträgercode im CRM nicht gefunden",
  C9125: "Kunde mit CIM-Nummer kann im CRM nicht abgerufen werden",
  C9126: "Store mit Rechtsträgercode kann im CRM nicht abgerufen werden",
  C9127: "Mitarbeiter mit Mitarbeitennummer kann im CRM nicht abgerufen werden",
  C9128: "CRM-Kundenmodell ist leer... oh nein",
  C9129: "CRM-Vertriebspersonenmodell ist leer",
  C9130: "Es gibt keine Währung im CRM...dann gibt es auch kein Geld!",
  C9131: "Währungsliste kann im CRM nicht abgerufen werden",
  C9132: "Synchronisation des Bestelldetails mit CRM fehlgeschlagen",
  C9133: "Synchronisation des Bestelldokuments mit CRM fehlgeschlagen",
  C9134: "Lieferant mit eingegebener Lieferantennummer im CRM nicht gefunden",
  C9135: "Lieferant mit Lieferantennummer kann im CRM nicht abgerufen werden",
  C9136: "Abrufen der Bestelldetails der Bestellung aus dem CRM fehlgeschlagen",
  C9137:
    "Abrufen der Bestelldokumente der Bestellung aus dem CRM fehlgeschlagen",
  C9138: "Status des Bestelldetails im CRM ändern fehlgeschlagen",
  C9139: "Status der Bestellung im CRM ändern fehlgeschlagen",
  C9140: "Lieferant mit CRM synchronisieren fehlgeschlagen",
  C9141: "Recht mit CRM synchronisieren fehlgeschlagen",
  C9142: "Store mit CRM synchronisieren fehlgeschlagen",
  C9143: "Provisionierung der Bestellung im CRM ändern fehlgeschlagen",
  C91044: "CRM-Lieferant nicht gefunden",
  C9145: "Kontaktperson mit CRM synchronisieren fehlgeschlagen",
  C9146: "Kundenkonto mit CRM synchronisieren fehlgeschlagen",
  C9147: "Favorit mit CRM synchronisieren fehlgeschlagen",
  C9148: "Lieferantenurlaub mit CRM synchronisieren fehlgeschlagen",
  C9149: "Lieferantenprogramm mit CRM synchronisieren fehlgeschlagen",
  C9150: "Favorit aus CRM entfernen fehlgeschlagen",
  C9151:
    "Provisionierungsgruppe der Bestellung aus CRM entfernen fehlgeschlagen",
  C9152: "Aufgabe mit CRM synchronisieren fehlgeschlagen",
  C9153: "Aufgabe im CRM zuweisen fehlgeschlagen",
  C9154: "Aufgabe im CRM ändern fehlgeschlagen",
  C9155: "Lead aus dem CRM abrufen fehlgeschlagen",
  C9156: "Aufgabe aus dem CRM abrufen fehlgeschlagen",
  C9157: "Aufgabenliste aus dem CRM abrufen fehlgeschlagen",
  C9158: "Aktivität aus dem CRM abrufen fehlgeschlagen",
  C9159: "Status des Vorfalls im CRM ändern fehlgeschlagen",
  C9160: "Vorfall im CRM zuweisen fehlgeschlagen",
  C9161: "Vorfallsliste aus dem CRM abrufen fehlgeschlagen",
  C9162: "Vorfall aus dem CRM abrufen fehlgeschlagen",
  C9900: "Unbekannter CRM-Fehler...Watson, übernehmen Sie!",
  C9901: "CRM-Dataverse-Verbindung fehlgeschlagen",
  C9163: "CRM-Abteilung nicht gefunden",
  C9164: "Abteilung mit CRM synchronisieren fehlgeschlagen",
  C9165: "Abteilung im CRM deaktivieren fehlgeschlagen",
  C9166: "Abteilung im CRM erstellen fehlgeschlagen",
  C9167: "Abteilung im CRM löschen fehlgeschlagen",
  C9168: "Themenvorfall aus dem CRM abrufen fehlgeschlagen",
  C9169: "CRM-Thema nicht gefunden",
  C9170: "CRM-Lieferantencode ist leer",
  C9171: "Mitarbeiter mit eingegebener Mitarbeitennummer im CRM nicht gefunden",

  "000": "ObjectNotFound",
  PE0000: "Berechnungseinheit nicht gefunden",
  PE0100: "Berechnungseinheit-Anpassung nicht gefunden",
  PE0101: "Benutzerdefinierte Berechnungseinheit nicht gefunden",
  PE0200: "Preisschablonencode existiert bereits",
  PE0201: "Einkaufspreisschablone nicht gefunden",
  PE0202: "Einkaufspreisschablone ist nicht aktiv",
  PE0203: "Einkaufspreisschablonenperiode noch nicht gestartet",
  PE0204: "Einkaufspreisschablonenperiode beendet",
  PE0205: "Keine Einkaufspreisschablone verfügbar",
  PE0206: "Einkaufspreisschablone hat kein Element",
  PE0300: "Verkaufspreisschablone nicht gefunden",
  PE0301: "Verkaufspreisschablone ist nicht aktiv",
  PE0302: "Verkaufspreisschablonenperiode noch nicht gestartet",
  PE0303: "Verkaufspreisschablonenperiode beendet",
  PE0304: "Keine Verkaufspreisschablone verfügbar",
  PE0305: "Verkaufspreisschablone hat kein Element",
  PE0306: "Optionale Rabatte nicht in Verkaufspreisschablone gefunden",
  PE0400: "Einkaufspreisschablonen-Element nicht gefunden",
  PE0500: "Verkaufspreisschablonen-Element nicht gefunden",

  AS1001: "Elo-Konfiguration nicht gefunden",
  AS1002: "Elo-Konfiguration existiert bereits",
  AS1003: "Elo-Dienst nicht verfügbar",

  AS2001: "Persönliche Azure-Blob-Konfiguration nicht gefunden",
  AS2002: "Mandanten-Speicherkonfiguration nicht gefunden",
  AS2003: "Speichertyp für Puls-Dateityp nicht gesetzt",

  AS3001: "Mandanten-Speicherkonfiguration nicht gefunden",
  AS3002: "Speichertyp für Puls-Dateityp nicht gesetzt",
  AS3010: "Quelldatei konnte nicht per URL heruntergeladen werden",

  SS0002: "Verkaufskanal nicht gefunden",
  SS0003: "Code des Verkaufskanal muss unique sein",

  SS14300: "ErpInventoryConfigNotFound",
  SS14301: "ErpInventoryServiceLoginMicrosoftFailed",
  SS14302: "ErpInventoryServiceLoginDynamicsFailed",
  SS14303: "ErpInventoryServiceIsNotAvailable",
  SS14304: "OnlyStoreHaveLegalEntityCodeCanUsedInErpInventoryService",
  SS14305: "ErpInventoryServiceReserveProductIsNotAvailable",
  SS14306: "ErpInventoryServiceReserveProductInInventoryHasFailed",
  SS14307: "ErpInventoryServiceUnreserveProductIsNotAvailable",
  SS14308: "ErpInventoryServiceUnreserveProductFromInventoryHasFailed",
  SS14309: "ErpInventoryServiceProductQuantityIsNotEnough",
  SS14310: "OnlyProductHaveArticleNumberCanUsedInErpInventoryService",
  SS14311: "ErpInventoryServiceOnHandResultHasNoResponse",
  SS14312: "ErpInventoryServiceOnHandResultLocationNotExist",
  SS14313: "ErpInventoryServiceLoginMicrosoftHasBadRequestResult",
  SS14314: "ErpInventoryServiceLoginMicrosoftHasUnauthorizedResult",
  SS14315: "ErpInventoryServiceLoginDynamicsHasBadRequestResult",
  SS14316: "ErpInventoryServiceLoginDynamicsHasUnauthorizedResult",

  SS17100: "Product LabelT emplate Not Found DE",
  SS17101: "Product Label Template Uploaded File Must Not Be Null DE",
  SS17200: "Print Request Not Found DE",
  SS17201: "Print Request Has OnProcess Status DE",

  SS18000: "SellPhaseTemplateCodeMustBeUnique",
  SS18001: "DeleteChecklistItemIsPossibleWhenNotUsedInPreparationPhase",
  SS18002: "DeleteChecklistItemIsPossibleWhenNotUsedInSellPhase",
  SS18003: "CheckListItemCouldNotFound",
  SS18004: "PreparationPhaseAlreadyCreated",
  SS18005: "CheckListItemsNotFound",
  SS18006: "PreparationPhaseNotFound",
  SS18007: "SellPhaseTemplateNotFound",
  SS18008: "SellOffProductNotFound",
  SS18009: "OrderOfSellOffProductAssetsShouldBeUnique",
  SS18010: "ChangePhaseIsAvailableAfterCurrentPhaseDuration",
  SS18011: "ChangePhaseIsOnlyPossibleForActiveSellOffProduct",
  SS18012:
    "ChangePhaseIsOnlyPossibleWhenAllSellPhaseCheckListItemsHaveBeenChecked",
  SS18013: "UpdateIsNotPossibleForClosedSellOffProduct",
  SS18014: "UpdateSellPhaseTemplateIsNotPossibleForActiveSellOffProduct",
  SS18015: "LastPhaseCanNotBeChanged",
  SS18016: "SoldIsOnlyPossibleForActiveSellOffProduct",
  SS18017: "WithdrawSoldItemIsOnlyPossibleForSoldSellOffProduct",
  SS18018: "ClosePhaseIsOnlyPossibleForActiveSellOffProduct",
  SS18019: "OnlyLastPhaseCanBeClosed",
  SS18020: "ClosePhaseIsAvailableOnlyAfterLastPhaseDuration",
  SS18021: "PreparationPhaseShouldContainsCheckListItem",
  SS18022:
    "UpdatePreparationPhaseCheckListItemIsOnlyPossibleForPreparationSellOffProduct",
  SS18023: "UpdateSellPhaseCheckListItemIsOnlyPossibleForActiveSellOffProduct",
  SS18024:
    "UpdateSellPhaseCheckListItemIsAvailableOnlyAfterCurrentPhaseDuration",
  SS18025: "NextSellPhaseShouldContainsCheckListItem",
  SS18026: "StartPhaseIsNotPossibleWithoutProductFamily",
  SS18027:
    "StartPhaseIsOnlyPossibleWhenAllPreparationPhaseCheckListItemsHaveBeenChecked",
  SS18028: "MediaIdMustExistForRemoveAssetFromSellOffProduct",
  SS18029: "RealSoldPriceShouldGreaterThanZero",
  SS18030: "SoldIsAvailableOnlyAfterSellOffProductStartDateTime",
  SS18031: "StartPhaseIsNotPossibleWithoutSellPhaseTemplate",
  SS18032: "StartPhaseIsOnlyPossibleForPreparationSellOffProduct",
  SS18033: "CheckListItemLabelAlreadyExists",
  SS18034: "StartPhaseIsOnlyPossibleWhenSellPhaseTemplateIsNotEmpty",
  SS18035: "SellPhaseTemplateIsNotSet",

  SS18100: "SellOffProductTimelineNotFound",
  SS18101: "SellOffProductTimelineAlreadyExist",
  SS7035: "Pim Product Type Must Be Bundle DE",
  SS7036: "Pim Product Type Must BeStandard DE",
};
